import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Document, Page, pdfjs } from "react-pdf";
import {
    Box,
    VStack,
    SimpleGrid,
    Text,
    Image,
    Button,
    Icon,
    HStack,
    Avatar,
    Tab,
    TabList,
    Tabs,
    TabPanel,
    TabPanels,
    Flex,
    SlideFade,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Grid,
    useToast,
    Center,
    Spacer,
    Spinner,
    Divider,
    useBreakpointValue
} from "@chakra-ui/react";
import { BsArrowLeftCircle, BsExclamationCircleFill, BsImages } from "react-icons/bs";

import { getMessageAttachment, getSentMessage } from "../../features/messages/message.actions";
import { MessageTracking } from "./MessageTracking";
import { localDateTime } from "../../helpers/DayJsHelper";

export const SentMessage: React.FC<any> = () => {
    const navigateTo = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const { id } = useParams();
    const { state }: any = useLocation();
    const toast = useToast();

    const [tabIndex, setTabIndex] = useState(0);

    const [firstTimeLoading, setFirstTimeLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<any>({});

    const [recipients, setRecipients] = useState<any[]>([]);
    const [teams, setTeams] = useState<any[]>([]);

    const [attachments, setAttachments] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState("");

    // PDF pages
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const resetDocViewer = () => {
        setNumPages(0);
        setPageNumber(1);
    };

    const [currentPdf, setCurrentPdf] = useState({
        id: 0,
        uri: "",
        fileName: ""
    });
    const [pdfIsOpen, setPdfIsOpen] = useState(false);

    const fetchData = useCallback(() => {
        setLoading(true);
        getSentMessage(Number(id))
            .then(async (message: any) => {
                setMessage(message);
                setRecipients(message.users);
                setTeams(message.teams);

                const attachmentThumbnails: any[] = [];

                for (let attachment of message.attachments) {
                    attachmentThumbnails.push({
                        id: attachment.matmessage,
                        thumbnail: `${process.env.REACT_APP_OBBI_API_URL}/messages/attachment?id=${id}&attachment=${attachment.matpath}&thumbnail=true&datauri=false`,
                        fileName: attachment.matfilename,
                        path: attachment.matpath,
                        isPdf: attachment.ispdf || attachment.isdoc
                    });
                }
                setAttachments(attachmentThumbnails);
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setLoading(false);
                setFirstTimeLoading(false);
            });
    }, [id]);

    useEffect(() => { fetchData(); },
        [fetchData, id]);

    const renderImage = () => {
        return <Modal
            size="2xl"
            isOpen={isOpen}
            onClose={() => { setIsOpen(false); }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />
                <ModalBody>
                    <Image
                        src={currentImage}
                        m="5px auto 15px auto"
                        p={0}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    };

    const renderPdf = () => {
        return <Modal
            size="4xl"
            isOpen={pdfIsOpen}
            onClose={() => {
                setPdfIsOpen(false);
                resetDocViewer();
            }}
        >
            <ModalOverlay />

            <ModalContent mt={0}>
                <ModalHeader
                    style={{
                        height: "50px",
                        borderRadius: "5px 5px 0 0",
                        background: "#38a169",
                        color: "white",
                    }}
                >
                    <HStack gap={1} paddingRight={10}>
                        <Text wordBreak="break-word" fontSize="lg" maxW="50%">{currentPdf.fileName}</Text>

                        <Spacer />

                        <Button
                            bg="white"
                            color="brand.500"
                            float="left"
                            size="sm"
                            disabled={pageNumber === 1}
                            onClick={() => setPageNumber(pageNumber - 1)}
                        >
                            Back
                        </Button>

                        <Text fontSize="md">
                            {pageNumber} of {numPages}
                        </Text>

                        <Button
                            bg="white"
                            color="brand.500"
                            float="right"
                            size="sm"
                            disabled={pageNumber === numPages}
                            onClick={() => setPageNumber(pageNumber + 1)}
                        >
                            Next
                        </Button>

                        <ModalCloseButton
                            color="white"
                            _hover={{ bg: "" }}
                            _active={{ bg: "" }}
                        />
                    </HStack>
                </ModalHeader>

                <ModalBody>
                    <Document
                        file={currentPdf.uri}
                        loading={
                            <Center my={48}>
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    color="brand.500"
                                    size="xl"
                                />
                            </Center>
                        }
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page
                            scale={2}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            pageNumber={pageNumber}
                        />
                    </Document>
                </ModalBody>
            </ModalContent>
        </Modal>
    };

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc =
            `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    return (
        <Box m={{ base: 0, md: 5 }} minH={{ base: "100vh", md: "unset" }}>
            <Button
                display={{ base: "none", md: "unset" }}
                w="fit-content"
                border="none"
                boxShadow="sm"
                mb={2}
                size="sm"
                color="gray.600"
                variant="outline"
                fontWeight={600}
                onClick={() => {
                    navigateTo("/messages",
                        {
                            state: {
                                tabIndex: state?.tabIndex,
                                pageIndex: state?.pageIndex,
                                pageCount: state?.pageCount,
                                filter: state?.filter
                            }
                        });
                }}
            >
                <HStack alignItems="center">
                    <Icon as={BsArrowLeftCircle} />
                    <Text>Back to Sent Messages</Text>
                </HStack>
            </Button>

            <Box
                height={{ base: "max-content", md: "unset" }}
                boxShadow={{ base: "none", md: "lg" }}
                rounded={{ base: "none", md: "lg" }}
                bg="white"
                px={{ base: 0, md: 5 }}
                py={5}
            >
                <Button
                    display={{ base: "unset", md: "none" }}
                    m={5}
                    mb={6}
                    size="sm"
                    color="gray.600"
                    variant="link"
                    fontWeight={600}
                    onClick={() => {
                        navigateTo("/messages",
                            {
                                state: {
                                    tabIndex: state?.tabIndex,
                                    pageIndex: state?.pageIndex,
                                    pageCount: state?.pageCount,
                                    filter: state?.filter
                                }
                            });
                    }}
                >
                    <HStack>
                        <Icon as={BsArrowLeftCircle} />
                        <Text>Back to Sent Messages</Text>
                    </HStack>
                </Button>

                <SlideFade in={!loading}>
                    <SimpleGrid columns={{ base: 1, xl: 2 }} mx={5}>
                        <VStack alignItems="start">
                            <HStack pb={3}>
                                {
                                    message.msgkind === "1" && <Icon
                                        color="red.500"
                                        size="xl"
                                        as={BsExclamationCircleFill}
                                    />
                                }

                                <Text
                                    fontWeight={700}
                                    fontSize='2xl'
                                    color="gray.700"
                                    wordBreak="break-word"
                                >
                                    {message.msgsubject}
                                </Text>
                            </HStack>

                            {
                                isMobile ?
                                    <Text
                                        mt={3}
                                        fontSize="sm"
                                        color="gray.500"
                                    >
                                        Sent {localDateTime(message.mudatesent)}
                                    </Text> :

                                    <Box>
                                        <Text
                                            fontSize="sm"
                                            color="gray.500"
                                            float="right"
                                        >
                                            Sent {localDateTime(message.mudatesent)}
                                        </Text>
                                    </Box>
                            }

                            {
                                teams.length > 0 && <HStack>
                                    <Text fontSize={{ base: "xs", md: "sm" }} color="gray.500">
                                        Sent to Teams
                                    </Text>

                                    {
                                        teams.map((t) => {
                                            return <HStack>
                                                <Avatar
                                                    size="xs"
                                                    color="white"
                                                    name={t.teamname}
                                                />

                                                <Text fontSize="xs">
                                                    {t.teamname}
                                                </Text>
                                            </HStack>
                                        })
                                    }
                                </HStack>
                            }

                            {
                                recipients.length > 0 && <HStack>
                                    <Text fontSize={{ base: "xs", md: "sm" }} color="gray.500">
                                        Sent to Team Members
                                    </Text>

                                    {
                                        recipients.map((r) => {
                                            return <HStack>
                                                <Avatar
                                                    size="xs"
                                                    color="white"
                                                    name={`${r.udforename} ${r.udsurname}`}
                                                />

                                                <Text fontSize="xs">
                                                    {`${r.udforename} ${r.udsurname}`}
                                                </Text>
                                            </HStack>
                                        })
                                    }
                                </HStack>
                            }
                        </VStack>
                    </SimpleGrid>
                </SlideFade>

                <Box pt={{ base: 3, md: 5 }} pb={3}>
                    <Divider />
                </Box>

                <Tabs colorScheme="green" onChange={(index) => setTabIndex(index)}>
                    <Flex
                        justify={{ base: "center", md: "space-between" }}
                    >
                        <TabList
                            mb={3}
                            mx={{ base: 0, md: 5 }}
                            w={{ base: "full", md: "unset" }}
                            justifyContent="center"
                        >
                            <Tab>
                                <Text
                                    color={tabIndex === 0 ? "green.500" : "gray.500"}
                                    fontWeight={500}
                                >
                                    Message Details
                                </Text>
                            </Tab>
                            <Tab>
                                <Text
                                    color={tabIndex === 1 ? "green.500" : "gray.500"}
                                    fontWeight={500}
                                >
                                    Tracking
                                </Text>
                            </Tab>
                        </TabList>
                    </Flex>

                    <TabPanels>
                        <TabPanel>
                            {
                                <SlideFade in={!loading} offsetY="20px">
                                    <VStack
                                        display='flex'
                                        alignItems='baseline'
                                    >
                                        <VStack
                                            textAlign="left"
                                            display='flex'
                                            alignItems='baseline'
                                            spacing={8}
                                        >
                                            <Text
                                                fontWeight={500}
                                                fontSize="md"
                                                color="gray.600"
                                                wordBreak="break-word"
                                                whiteSpace="pre-line"
                                            >
                                                <ReactMarkdown children={message.msgbody} rehypePlugins={[rehypeRaw] as any} />
                                            </Text>

                                            {
                                                attachments && attachments.length > 0 && <VStack
                                                    textAlign="left"
                                                    display="flex"
                                                    alignItems="baseline"
                                                    spacing={3}
                                                >
                                                    <HStack>
                                                        <Icon
                                                            as={BsImages}
                                                            fontSize="xs"
                                                            color="gray.500"
                                                        />
                                                        <Text
                                                            fontWeight={500}
                                                            fontSize="sm"
                                                            color="gray.500"
                                                        >
                                                            Attachments
                                                        </Text>
                                                    </HStack>

                                                    <Grid
                                                        templateColumns={{ base: 'repeat(5, 1fr)', md: 'repeat(5, 5fr)' }}
                                                        alignItems="start"
                                                        gap={2}
                                                        mt={3}
                                                    >
                                                        {
                                                            attachments.map((a: any) => {
                                                                return (
                                                                    <Box minW="170px">
                                                                        <Image
                                                                            className="hover-pop"
                                                                            cursor="pointer"
                                                                            src={a.thumbnail}
                                                                            fallbackSrc='https://via.placeholder.com/160'
                                                                            alt={a.fileName}
                                                                            boxSize='160px'
                                                                            boxShadow="xs"
                                                                            objectFit="cover"
                                                                            border="1px solid"
                                                                            borderColor="gray.200"
                                                                            rounded="lg"
                                                                            onClick={async () => {
                                                                                await getMessageAttachment(a.id, a.path, 0, 1, a.isPdf)
                                                                                    .then((res: any) => {
                                                                                        if (a.isPdf) {
                                                                                            setCurrentPdf({
                                                                                                id: Number(id),
                                                                                                fileName: a.fileName,
                                                                                                uri: res?.datauri
                                                                                            });
                                                                                            setPdfIsOpen(true);
                                                                                        } else {
                                                                                            setCurrentImage(res.datauri);
                                                                                            setIsOpen(true);
                                                                                        }
                                                                                    })
                                                                                    .catch((error: any) => {
                                                                                        console.log(error);

                                                                                        toast({
                                                                                            title: "Error",
                                                                                            description: "Cannot download this attachment",
                                                                                            status: 'error',
                                                                                            duration: 6000,
                                                                                            isClosable: true,
                                                                                        });
                                                                                    });
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </VStack>
                                            }
                                        </VStack>
                                    </VStack>
                                </SlideFade>
                            }
                        </TabPanel>

                        <TabPanel>
                            <MessageTracking message={message} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>

            {renderImage()}
            {renderPdf()}
        </Box>
    )
}
import { get, getWithParams, post } from "../../api/axiosClient";

import { loginFailure, loginSuccess, logout, verifyEmail, verifyEmailExists } from "./auth.slice";

import store from "../../redux/store";

export async function login(data: any) {
    return await post("auth/login", data)
        .then((res: any) => {
            store.dispatch(loginSuccess(res.data));
        }).catch((error: any) => {
            store.dispatch(loginFailure(error));
            throw error;
        });
};

export async function has2FA(username: any): Promise<boolean | null> {
    return await post('auth/has2fa', { username: username })
        .then((res: any) => {
            return res?.data?.success;
        }).catch((error: any) => {
            throw error;
        })
};

export async function validate() {
    const v: any = await post("auth/validateAccess")
        .catch((error: any) => {
            if (error.response) {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }

            return {
                status: -1,
                data: {
                    error: -1,
                    success: false,
                    message: "Unable to validate session"
                }
            };
        });

    return {
        status: v.status,
        data: v.data
    };
};

export async function destroySession() {
    return (
        await post("auth/logout")
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                store.dispatch(logout());
            })
    );
};

export async function getToken() {
    let token = "";

    try {
        const result: any = await getWithParams("auth/oneShotToken", { minutes: 1 });
        if (result.data.success) {
            token = result.data.oneShotToken;
        }
    } catch (error: any) {
        console.log(error);
    }

    return token;
};

export async function validateToken(token: string) {
    let isValid = false;

    try {
        const result: any = await post("auth/oneShotToken", { token });

        if (result.data.success) {
            console.log(result.data);

            store.dispatch(loginSuccess(result.data));

            isValid = true;
        } else
            isValid = false;
    } catch (error: any) {
        console.log(error);
    }

    return isValid;
};

export async function getClickthroughURL(action: string, id: number = 0, str: string = "") {
    const t: any = await getWithParams("auth/oneShotToken", { minutes: 1 });

    if (t.data.success) {
        let token: string = t.data.oneShotToken;
        return `${process.env.REACT_APP_ENV_URL}/go?action=${action}&token=${token}${id !== 0 ? `&id=${id}` : ''}`;
    }

    throw new Error("Unable to get one shot token");
};

export async function selectClassicObbi() {
    return (
        await post("auth/selectClassicInterface")
            .catch((error) => {
                console.log(error);
            })
    );
};

export async function selectNewObbi() {
    return (
        await post("auth/selectNewInterface")
            .catch((error) => {
                console.log(error);
            })
    );
};

export async function getCrsfToken() {
    return (
        await get("auth/seasurf")
            .catch((error) => {
                console.log(error);
            })
    );
};

export async function resetPassword(passwordData: any) {
    let result = {
        success: false,
        message: ""
    };

    await post("admin/changePassword", {
        currentpassword: passwordData?.currentPassword,
        newpassword: passwordData?.newPassword,
        confirmpassword: passwordData?.confirmPassword,
        twofacode: passwordData?.twoFa
    })
        .then((res: any) => {
            result.success = res.data.success;

            if (res.data.error === 34) {
                result.message = "This doesn't match your current password";
            }
        })
        .catch((error) => {
            console.log(error);

            throw error;
        });

    return result;
};

export async function changeEmail(emailData: any) {
    let result = {
        success: false,
        message: ""
    };

    await post("admin/changeEmailAddress", {
        emailaddress: emailData?.emailAddress,
        confirmemailaddress: emailData?.confirmEmailAddress
    })
        .then((res: any) => {
            result.success = res.data.success;

            if (res.data.error === 34) {
                result.message = "This doesn't match your current email";
            }

            store.dispatch(verifyEmailExists(emailData?.emailAddress));
            store.dispatch(verifyEmail());
        })
        .catch((error) => {
            console.log(error);

            throw error;
        });

    return result;
};

export async function getPermalink(id: string) {
    let permalink = "";

    await getWithParams("auth/permalink", { id })
        .then((res: any) => {
            if (res.data.success) {
                permalink = res.data.permalink;
            }
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });

    return permalink;
};

export async function createPermalink(payload: any) {
    let permalink = "";

    await post("auth/permalink", payload)
        .then((res: any) => {
            if (res.data.success) {
                permalink = res.data;
            }
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });

    return permalink;
};
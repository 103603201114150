import { useEffect, useRef, useState } from "react";
import { debounce, isEmpty, isNull, isUndefined } from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
import Lightbox from "react-image-lightbox";
import {
	Divider,
	HStack,
	Icon,
	Text,
	Input,
	Image,
	Button,
	FormControl,
	VStack,
	Box,
	InputGroup,
	InputRightAddon,
	Select,
	FormLabel,
	Textarea,
	Spacer,
	SlideFade,
	SimpleGrid,
	useToast,
	Spinner,
	ButtonGroup,
	ModalContent,
	Modal,
	Center,
	ModalBody,
	ModalCloseButton,
	ModalHeader,
	ModalOverlay
} from "@chakra-ui/react";
import {
	BsChatDots,
	BsCheckCircleFill,
	BsExclamationCircleFill,
	BsPaperclip,
	BsTrash,
	BsWrench,
	BsXCircleFill,
} from "react-icons/bs";

import "react-datepicker/dist/react-datepicker.css";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import { fileTypeFromBlob } from "file-type";

import {
	deleteAttemptAttachment,
	getAttemptAttachment,
	getAttemptThumbnail,
	getItemThumbnail,
	getTaskItem,
	patchTaskItem,
	postAttemptAttachment,
} from "../../features/tasks/task.actions";

import { RadioCard, RadioCardGroup } from "../common/radioCardGroup/RadioCardGroup";
import { DropZone } from "../common/DropZone";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import dayjs from "dayjs";

export const Item: React.FC<any> = ({
	attemptId,
	itemId,
	itemIndex,
	currentPage,
	setProgressState,
}) => {
	const [itemData, setItemData] = useState({
		item: {
			datavaluecount: 0,
			attachmentcount: 0,
			datavalues: [],
			datainputtype: "",
			datainputunit: "",
			tidatainputprompt: "",
			tidatainputlowerbound: null,
			tidatainputupperbound: null,
			dataoption: 0,
			canenterdata: false,
			datainputmandatory: false,
			tidatainputmandatory: false,
			docusignmandatory: false,
			photomandatory: false,
			commentmandatory: false,
			cansetstatus: false,
			statusmandatory: false,
			candocusign: false,
			cancomment: false,
			canattach: false,
			hasrange: false,
			tidescription: "",
			statusoption: 0,
			datainputselect: false,
			datainputdatetime: false,
			datainputdate: false,
			datainputtime: false,
			datainputtext: false,
			datainputstring: false,
			datainputnumber: false,
			hasbound: false,
			tidatainputexpectedquantity: "",
			createcorrectiveaction: false
		},
		value: {
			statusuncertain: false,
			statusfailed: false,
			statuspassed: false,
			statusnull: false,
			hasphotos: false,
			ttidatainputstring: "",
			ttifeedback: "",
			attachments: [],
			ttistatus: null,
			ackneeded: false,
			coursestate: null,
			commentneeded: false
		},
	});

	const toast = useToast();

	const [loading, setLoading] = useState<boolean>(false);
	const [updating, setUpdating] = useState<boolean>(false);
	const [fileUploadLoading, setFileUploadLoading] = useState(false);
	const [commentActive, setCommentActive] = useState<boolean>(false);
	const [photoActive, setPhotoActive] = useState<boolean>(false);
	const [comment, setComment] = useState<any>(false);
	const [isCommentModified, setIsCommentModified] = useState(false);
	const debounceRef = useRef<ReturnType<typeof debounce> | null>(null);

	const [definitionAttachments, setDefinitionAttachments] = useState<any>([]);
	const [attachments, setItemAttachments] = useState<any>([]);
	const [activeImage, setActiveImage] = useState("");
	const [isOpen, setIsOpen] = useState(false);

	// PDF 
	const [pdfAttachment, setPdfAttachment] = useState({
		id: 0,
		uri: "",
		fileName: ""
	});
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [pdfIsOpen, setPdfIsOpen] = useState(false);

	const resetDocViewer = () => {
		setNumPages(0);
		setPageNumber(1);
	};

	const [status, setStatus] = useState("");

	const canvas = useRef<SignatureCanvas>(null);
	const getSigniture = (url: string) => canvas.current?.fromDataURL(url);
	const formatSigniture = (): string | undefined => canvas.current?.toDataURL("image/png");

	const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setComment(e.target.value);
	};

	const handleCommentBlur = debounce(() => {
		if (comment !== itemData.value.ttifeedback) {
			setIsCommentModified(true);
		} else {
			setIsCommentModified(false);
		}
	}, 200);

	debounceRef.current = handleCommentBlur;

	const handleSaveComment = () => {
		if (debounceRef.current) {
			debounceRef.current.flush();
		}

		patchItem({
			name: "itemfeedback",
			value: comment,
			attempt: attemptId,
			item: itemId,
		}).then(() => {
			setIsCommentModified(false);
			setCommentActive(false);
			setPhotoActive(false);
		}).catch((error) => {
			console.error("Failed to save comment:", error);
		});
	};

	const clearSigniture = () => {
		canvas.current?.clear();

		patchItem({
			name: "savedocusign",
			value: "clear",
			attempt: attemptId,
			item: itemId,
		});
	};

	const getStatus = (
		uncertain: boolean,
		failed: boolean,
		passed: boolean,
		unset: boolean
	) => {
		if (unset) return "";
		else if (uncertain) return "0";
		else if (passed) return "1";
		else if (failed) return "2";
		else return "";
	};

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => {
				setPdfIsOpen(false);
				resetDocViewer();
			}}
		>
			<ModalOverlay />

			<ModalContent mt={0}>
				<ModalHeader
					style={{
						height: "50px",
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white"
					}}
				>
					<HStack gap={1} paddingRight={10}>
						<Text wordBreak="break-word" fontSize="lg" maxW="50%">{pdfAttachment.fileName}</Text>
						<Spacer />
						<Button
							bg="white"
							color="brand.500"
							float="left"
							size="sm"
							disabled={pageNumber === 1}
							onClick={() => setPageNumber(pageNumber - 1)}
						>
							Back
						</Button>
						<Text fontSize="md">
							{pageNumber} of {numPages}
						</Text>
						<Button
							bg="white"
							color="brand.500"
							float="right"
							size="sm"
							disabled={pageNumber === numPages}
							onClick={() => setPageNumber(pageNumber + 1)}
						>
							Next
						</Button>
						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>
					<Document
						file={pdfAttachment.uri}

						loading={
							<Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							scale={2}
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={pageNumber}
						/>
					</Document>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const renderTrainingItem = (coursestate: any) => {
		if (!isNull(coursestate) && !isUndefined(coursestate)) {
			if (coursestate.expired)
				return <HStack pt={2}>
					<Icon as={BsExclamationCircleFill} color="danger.500" />
					<Text color="gray.600"
						fontWeight="500"
						display="flex"
						alignItems="baseline"
					>{`Your "${coursestate.cname}" training has expired`}</Text>
				</HStack>
			else if (coursestate.failed)
				return <HStack pt={2}>
					<Icon as={BsExclamationCircleFill} color="danger.500" />
					<Text color="gray.500"
						fontWeight="600"
						display="flex"
						alignItems="baseline"
					>{`You have failed "${coursestate.cname}" training`}</Text>
				</HStack>
			else if (coursestate.valid)
				return <HStack pt={2}>
					<Icon as={BsCheckCircleFill} color="brand.500" />
					<Text color="gray.600"
						fontWeight="500"
						display="flex"
						alignItems="baseline"
					>{`"${coursestate.cname}" training up to date`}</Text>
				</HStack>
		} else {
			return <></>
		}
	};

	const deleteAttachment = (attachmentGuid: string) => {
		deleteAttemptAttachment(attemptId, itemId, attachmentGuid)
			.then(() => {
				let updatedAttachments = attachments;
				updatedAttachments = updatedAttachments.filter((a: any) => {
					return a.attachment.ttiapath !== attachmentGuid;
				});

				setItemAttachments(updatedAttachments);

				toast({
					title: "Photo deleted",
					description: "",
					status: "success",
					duration: 5000,
					isClosable: true
				});
			})
			.catch((error) => {
				toast({
					title: error.message,
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			});
	};

	const onDropAction = async (file: any) => {
		if (attachments.length > 3) {
			toast({
				title: "You can only upload up to 4 attachments",
				description: "",
				status: "error",
				duration: 6000,
				isClosable: true
			});

			return;
		}
		const fileExtension = await fileTypeFromBlob(file);
		const supportedFileTypes = ["webp", "gif", "png", "jpg", "jpeg", "pdf"];

		if (!supportedFileTypes.includes(fileExtension?.ext ?? "")) {
			toast({
				title: "Unsupported attachment type",
				description: "",
				status: "error",
				duration: 6000,
				isClosable: true
			});

			return;
		}

		setFileUploadLoading(true);

		postAttemptAttachment(attemptId, itemId, file)
			.then((res) => {
				setItemAttachments([...attachments, res]);
			})
			.catch((error) => {
				toast({
					title: error?.response?.data?.message,
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			})
			.finally(() => {
				setPhotoActive(false);
				setFileUploadLoading(false);
			});
	};

	const debouncedPatchItem = debounce((params) => {
		patchItem(params);
	}, 800);

	const patchItem = async (arg: any): Promise<any> => {
		setUpdating(true);

		patchTaskItem(arg)
			.then((res: any) => {
				setItemData(res);
				setStatus(
					getStatus(
						res.value.statusuncertain,
						res.value.statusfailed,
						res.value.statuspassed,
						res.value.statusnull
					)
				);
				setProgressState(res.state);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setUpdating(false);
			});
	};

	const getItemAttachment = async (path: string) => {
		await getAttemptAttachment(
			attemptId,
			itemId,
			path
		)
			.then((res: any) => {
				if (res.attachment.attachmentispdf) {
					setPdfAttachment({
						id: res.attachment.attachmentid,
						uri: res.datauri,
						fileName: res.attachment.ttiafilename
					});
					setPdfIsOpen(true);
				} else {
					setActiveImage(res.datauri);
					setIsOpen(true);
				}
			})
			.catch((error: any) => {
				console.log(error);
				toast({
					title: "Cannot fetch this attachment.",
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			});
	};

	const getDefinitionAttachment = async (path: string) => {
		await getItemThumbnail(
			attemptId,
			itemId,
			path,
			false
		)
			.then((res: any) => {
				setActiveImage(res.datauri);
				setIsOpen(true);
			})
			.catch((error: any) => {
				console.log(error);
				toast({
					title: "Cannot fetch this attachment.",
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			});
	};

	const [date, setDate] = useState<any>("");
	const onSetDate = (date: any) => {
		if (date === "") setDate("");
		else {
			setDate(date);
		}
	};

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc =
			`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	});

	useEffect(() => {
		setLoading(true);

		// Reset attachment states
		setItemAttachments([]);
		setCommentActive(false);
		setPhotoActive(false);

		getTaskItem(attemptId, itemId)
			.then(async (res: any) => {
				setItemData(res);
				setComment(res.value.ttifeedback);

				if ((res.item.datainputdatetime || res.item.datainputdate) &&
					res.value.ttidatainputstring !== "" &&
					res.value.ttidatainputstring !== "NULL" &&
					res.value.ttidatainputstring !== null
				) {
					if (res.item.datainputdatetime) {
						setDate(`${res.value.datecomponent}T${res.value.timecomponent}`);
					} else {
						setDate(res.value.ttidatainputstring);
					}
				}

				setStatus(
					getStatus(
						res.value.statusuncertain,
						res.value.statusfailed,
						res.value.statuspassed,
						res.value.statusnull
					)
				);

				if (!isEmpty(res.item.attachments)) {
					const photos: any[] = [];

					for (let attachment of res.item.attachments) {
						const photo = await getItemThumbnail(
							attemptId,
							itemId,
							attachment.tiapath
						);

						photos.push(photo);
					}

					setDefinitionAttachments(photos);
				}

				if (res.value.hasphotos) {
					const itemAttachments: any[] = [];

					for (let attachment of res.value.photos) {
						const media = await getAttemptThumbnail(attemptId, itemId, attachment.ttiapath);
						itemAttachments.push(media);
					}

					setItemAttachments(itemAttachments);
				}

				if (res.item.candocusign && res.value.docusign) {
					getAttemptAttachment(attemptId, itemId, res.value.docusign.ttiapath || "")
						.then((res: any) => {
							getSigniture(res.datauri);
						});
				}
			})
			.catch((error) => {
				console.log(error);
				toast({
					title: "Something went wrong!",
					description: "Please try again. If this issue persists please contact your system administrator.",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, [attemptId, currentPage, itemId, toast]);

	return (
		<>
			{
				isOpen && <Lightbox
					mainSrc={activeImage}
					onCloseRequest={() => setIsOpen(false)}
				/>
			}
			<Box
				w={{ base: "100%" }}
				id={`Item-${itemId}`}
				bg="green.500"
				color="white"
				roundedTop="lg"
				boxShadow="lg"
			>
				<HStack mx={5} py={1}>
					<Text
						alignSelf="center"
						fontSize="sm"
						textAlign="left"
						fontWeight={500}
					>
						{`Item ${itemIndex + 1} of ${currentPage.items.length}`}
					</Text>
					<Spacer />
					<Text
						alignSelf="center"
						fontSize="sm"
						textAlign="left"
						fontWeight={500}
					>
						{updating && <Spinner size="sm" verticalAlign="text-bottom" />}
					</Text>
				</HStack>
			</Box>
			<Box
				bg="white"
				mb={5}
				roundedBottom="lg"
				boxShadow={{ base: "md", md: "lg" }}
			>
				<VStack alignItems="left" p={4}>
					{
						!loading && itemData.item.tidescription && <HStack>
							{
								(
									itemData.item.statusmandatory ||
									itemData.item.datainputmandatory ||
									itemData.item.docusignmandatory ||
									itemData.item.commentmandatory ||
									itemData.item.photomandatory
								) &&
								<Text color="danger.500" alignSelf="baseline">*</Text>
							}
							<Text
								fontSize="md"
								alignSelf="start"
								textAlign="left"
								color="gray.600"
								fontWeight={500}
							>
								{itemData.item.tidescription}
							</Text>
						</HStack>
					}
					{
						!loading && itemData.item.cansetstatus && <FormControl pt={2}>
							<RadioCardGroup
								key={1}
								value={status}
								onChange={(e: any) => {
									patchItem({
										name: "itemstate",
										value: Number(e),
										attempt: attemptId,
										item: itemId
									});
								}}
								flexDirection="row"
								alignItems="center"
								gap={{ base: 2, md: 5 }}
							>
								<RadioCard
									value="1"
									className="hover-pop"
									showCheck={false}
									fillColor="green.50"
									border="1px solid"
									borderColor="gray.200"
									boxShadow="xs"
								>
									<Text
										fontSize="xl"
										color="gray.600"
										fontWeight="500"
										alignItems="baseline"
										display="flex"
									>
										<Icon
											as={BsCheckCircleFill}
											mr={1}
											color="brand.500"
											alignSelf="center"
										/>
									</Text>
								</RadioCard>
								<RadioCard
									showCheck={false}
									value="2"
									className="hover-pop"
									selectedColor="#e53e3e"
									fillColor="red.50"
									border="1px solid"
									borderColor="gray.200"
									boxShadow="xs"
								>
									<Text
										fontSize="xl"
										color="gray.600"
										fontWeight="500"
										display="flex"
										alignItems="baseline"
									>
										<Icon
											as={BsXCircleFill}
											mr={1}
											color="danger.500"
											alignSelf="center"
										/>
									</Text>
								</RadioCard>
								<RadioCard
									showCheck={false}
									value="0"
									className="hover-pop"
									border="1px solid"
									borderColor="gray.200"
									boxShadow="xs"
								>
									<Text
										fontSize="sm"
										color="gray.600"
										fontWeight="500"
										display="flex"
										alignItems="baseline"
									>
										N/A
									</Text>
								</RadioCard>
							</RadioCardGroup>
						</FormControl>
					}
					{
						!loading && (itemData.item.datainputstring || itemData.item.datainputnumber) && <FormControl
							pt={2}
							onChange={(e: any) => {
								patchItem({
									name: "itemdata",
									value: e.target.value,
									attempt: attemptId,
									item: itemId,
								});
							}}
						>
							{
								itemData.item.canenterdata && <FormLabel
									mb={1}
									color="gray.500"
									fontWeight={500}
									fontSize="sm"
									fontStyle="italic"
									textAlign="left"
								>
									{itemData.item.tidatainputprompt}
								</FormLabel>
							}

							<Text
								mb={2}
								color={itemData.value.statusfailed ? "danger.500" : "gray.500"}
								fontWeight={500}
								fontSize="sm"
								textAlign="left"
							>
								{
									!isEmpty(itemData.item.tidatainputexpectedquantity)
										? `Expecting ${itemData.item.tidatainputexpectedquantity}${!isNull(itemData.item.datainputunit) ? itemData.item.datainputunit : ""}` : ""
								}
							</Text>
							<InputGroup size="md">
								<Input
									type={itemData.item.datainputnumber ? "number" : "text"}
									borderColor={itemData.value.statusfailed ? "danger.500" : "gray.200"}
									onWheel={(e: any) => e.target.blur()}
									_hover={{
										borderColor: itemData.value.statusfailed ? "danger.500" : ""
									}}
									_focus={{
										borderColor: itemData.value.statusfailed ? "danger.500" : "brand.500",
										borderWidth: "2px"
									}}
									defaultValue={
										itemData.value.ttidatainputstring || ""
									}
								/>
								{
									itemData.item.datainputunit && <InputRightAddon
										bg={itemData.value.statusfailed ? "danger.500" : "gray.50"}
										color={itemData.value.statusfailed ? "white" : "gray.600"}
										borderColor={itemData.value.statusfailed ? "danger.500" : "gray.200"}
										fontWeight={itemData.value.statusfailed ? 600 : 500}
										children={itemData.item.datainputunit}
									/>
								}
							</InputGroup>
							<FormLabel
								mt={1}
								color={itemData.value.statusfailed ? "danger.500" : "gray.500"}
								fontWeight={500}
								fontSize="sm"
								textAlign="left"
							>
								{
									itemData.item.hasrange && `Expecting between ${itemData.item.tidatainputlowerbound}${!isNull(itemData.item.datainputunit) ? itemData.item.datainputunit : ""} and ${itemData.item.tidatainputupperbound}${!isNull(itemData.item.datainputunit) ? itemData.item.datainputunit : ""}`
								}
							</FormLabel>
						</FormControl>
					}
					{
						!loading && itemData.item.datainputselect && <FormControl pt={2}>
							<Select
								color="gray.600"
								_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
								defaultValue={itemData.value.ttidatainputstring || ""}
								placeholder="Please select"
								onChange={(e: any) => {
									patchItem({
										name: "itemdata",
										value: e.target.value,
										attempt: attemptId,
										item: itemId,
									});
								}}
							>
								{
									itemData.item.datavalues.map((option: any, i: number) => (
										<option key={option.tdvvalue} value={option.tdvvalue}>{option.tdvvalue}</option>
									))
								}
							</Select>
						</FormControl>
					}
					{
						!loading && itemData.item.datainputdatetime && <FormControl pt={2}>
							<HStack>
								<Input
									value={date}
									placeholder="Select Date and Time"
									size="md"
									type="datetime-local"
									color="gray.600"
									onKeyDown={(e: any) => { e.preventDefault() }}
									onWheel={(e: any) => e.target.blur()}
									onChange={(e: any) => {
										onSetDate(e.target.value);
										patchItem({
											name: "itemdata",
											value: e.target.value,
											attempt: attemptId,
											item: itemId,
										});
									}}
								/>
								<Button
									mb={2}
									onClick={() => {
										let x = dayjs(new Date());
										const now = x.format("YYYY-MM-DD HH:mm").replace(/\s/g, 'T');

										setDate(now);
										patchItem({
											name: "itemdata",
											value: x,
											attempt: attemptId,
											item: itemId,
										});
									}}
								>
									Now
								</Button>
							</HStack>
						</FormControl>
					}
					{
						!loading && itemData.item.datainputdate && <FormControl pt={2}>
							<FormLabel color="gray.500" fontStyle="italic">Select date</FormLabel>
							<Input
								value={date}
								placeholder="Select Date"
								size="md"
								type="date"
								onWheel={(e: any) => e.target.blur()}
								onKeyDown={(e: any) => { e.preventDefault() }}
								onChange={(e: any) => {
									onSetDate(e.target.value);
									patchItem({
										name: "itemdata",
										value: e.target.value,
										attempt: attemptId,
										item: itemId,
									});
								}}
							/>
						</FormControl>
					}
					{
						!loading && itemData.item.datainputtime && <FormControl pt={2}>
							<FormLabel color="gray.500" fontStyle="italic">Select time</FormLabel>
							<Input
								defaultValue={itemData.value.ttidatainputstring}
								placeholder="Select Time"
								size="md"
								type="time"
								onWheel={(e: any) => e.target.blur()}
								onKeyDown={(e: any) => { e.preventDefault() }}
								onChange={(e: any) => {
									patchItem({
										name: "itemdata",
										value: e.target.value,
										attempt: attemptId,
										item: itemId,
									});
								}}
							/>
						</FormControl>
					}
					{
						!loading && itemData.item.datainputtext && <FormControl
							pt={2}
							isRequired={itemData.value.commentneeded}
							onChange={(e: any) => {
								patchItem({
									name: "itemdata",
									value: e.target.value,
									attempt: attemptId,
									item: itemId,
								});
							}}
						>
							<FormLabel color="gray.600">
								{itemData.item.tidatainputprompt || "Add a comment"}
							</FormLabel>
							<Box
								className="hover-pop"
								border="1px solid"
								borderColor="gray.200"
								px={{ base: "4", md: "3" }}
								py={2}
								w="100%"
								borderRadius="md"
								boxShadow="xs"
								cursor="pointer"
								onClick={() => {
									photoActive && setPhotoActive(!photoActive);
									setCommentActive(!commentActive);
								}}
							>
								<HStack mb={2}>
									<Icon
										as={itemData.item.createcorrectiveaction ? BsWrench : BsChatDots}
										alignSelf="start"
										color="gray.500"
										verticalAlign="text-top"
										mt={0.5}
									/>
									<Text
										fontSize="sm"
										textAlign="left"
										fontWeight={500}
										color="gray.500"
									>
										{itemData.item.createcorrectiveaction ? "This comment will be used to create a corrective action" : itemData.item.tidatainputprompt || "Add a comment"}
									</Text>
								</HStack>
								<Text
									fontSize="sm"
									textAlign="left"
									fontWeight={600}
									color="gray.600"
								>
									{
										itemData.value.ttifeedback ? <ReactMarkdown
											children={itemData.value.ttifeedback}
											rehypePlugins={[rehypeRaw] as any}
										/>
											: "Click here to add a comment"
									}
								</Text>
							</Box>
						</FormControl>
					}
					{
						!loading && itemData.item.candocusign && <>
							{
								getSigniture(itemData.value.ttidatainputstring)
							}
							<FormControl pt={2}>
								<FormLabel color="gray.500" fontStyle="italic">
									Please sign in the box below
								</FormLabel>
								<SignaturePad
									ref={canvas}
									clearOnResize={false}
									onEnd={() => {
										debouncedPatchItem({
											name: "savedocusign",
											value: formatSigniture(),
											attempt: attemptId,
											item: itemId,
										});
									}}
									canvasProps={{
										style: {
											width: "100%",
											height: "140px",
											border: "1px solid #e2e8f0",
											borderRadius: "7px",
											backgroundColor: "white"
										},
									}}
								/>
								<Button
									w="full"
									mt={2}
									size="sm"
									variant="outline"
									color="gray.600"
									leftIcon={<Icon color="gray.600" as={BsTrash} />}
									onClick={() => {
										clearSigniture();
									}}
								>
									Clear
								</Button>
							</FormControl>
						</>
					}
					{
						!isNull(itemData.value.coursestate) && renderTrainingItem(itemData.value.coursestate)
					}
					{
						!loading &&
						((itemData.item.commentmandatory && isEmpty(itemData.value.ttifeedback))
							|| itemData.item.photomandatory) &&
						<VStack>
							<Box
								hidden={!itemData.item.commentmandatory}
								borderLeft="3px solid"
								borderColor="red.500"
								bg="red.50"
								mt={2}
								mx={5}
								px={{ base: "4", md: "3" }}
								py={2}
								w="full"
								borderRadius="md"
							>
								<HStack>
									<Icon as={BsExclamationCircleFill} color="danger.500" />
									<Text
										fontSize="sm"
										textAlign="left"
										fontWeight={500}
										color="gray.600"
									>
										You must add a comment
									</Text>
								</HStack>
							</Box>
							<Box
								hidden={
									!itemData.item.photomandatory &&
									itemData.item.attachmentcount < 1
								}
								borderLeft="3px solid"
								borderColor="red.500"
								bg="red.50"
								mt={2}
								mx={5}
								px={{ base: "4", md: "3" }}
								py={2}
								w="full"
								borderRadius="md"
							>
								<HStack>
									<Icon as={BsExclamationCircleFill} color="danger.500" />
									<Text
										fontSize="sm"
										textAlign="left"
										fontWeight={500}
										color="gray.600"
									>
										You must add a photo
									</Text>
								</HStack>
							</Box>
						</VStack>
					}
					{
						!loading && !(isEmpty(itemData.value.ttifeedback) || itemData.item.datainputtext) &&
						<SlideFade in={!loading}>
							<Box pt={2}>
								<Box
									border="1px solid"
									borderColor="gray.200"
									borderRadius="md"
									px={{ base: "4", md: "3" }}
									py={2}
								>
									<HStack mb={2}>
										<Icon
											as={itemData.item.createcorrectiveaction ? BsWrench : BsChatDots}
											alignSelf="start"
											color="gray.500"
											verticalAlign="text-top"
											mt={0.5}
										/>
										<Text
											fontSize="sm"
											textAlign="left"
											fontWeight={500}
											color="gray.500"
										>
											{itemData.item.createcorrectiveaction ? "This comment will be used to create a corrective action" : "Comment"}
										</Text>
									</HStack>
									<Text
										fontSize="sm"
										textAlign="left"
										fontWeight={600}
										color="gray.600"
									>
										<ReactMarkdown
											children={itemData.value.ttifeedback}
											rehypePlugins={[rehypeRaw] as any}
										/>
									</Text>
								</Box>
							</Box>
						</SlideFade>
					}
					{
						!loading && !isEmpty(definitionAttachments) && <SimpleGrid
							columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
							spacing={2}
						>
							{
								definitionAttachments.map((a: any, i: number) => {
									return (
										<Box minW="160px" key={`definition_attachment_${i}`}>
											<Image
												className="hover-pop"
												cursor="pointer"
												src={a.datauri}
												fallbackSrc='https://via.placeholder.com/160'
												alt={a.fileName}
												boxSize='160px'
												boxShadow="xs"
												objectFit="cover"
												border="1px solid"
												borderColor="gray.200"
												rounded="lg"
												onClick={() => {
													getDefinitionAttachment(a.attachment.tiapath);
												}}
											/>
										</Box>
									);
								})
							}
						</SimpleGrid>
					}
					{
						!loading && !isEmpty(attachments) && <HStack pt={2} gap={2}>
							{
								attachments.map((a: any, i: number) => {
									return (
										<Box key={`Attachment_${i}`}>
											<Image
												className="hover-pop"
												cursor="pointer"
												src={a.datauri}
												fallbackSrc='https://via.placeholder.com/160'
												alt={a.fileName}
												boxSize='140px'
												boxShadow="xs"
												objectFit="cover"
												border="1px solid"
												borderColor="gray.200"
												rounded="lg"
												onClick={() => {
													getItemAttachment(a.attachment.ttiapath);
												}}
											/>
											<Button
												className="hover-pop"
												variant="link"
												size="xs"
												color="gray.500"
												_hover={{ color: "" }}
												onClick={() => {
													deleteAttachment(a.attachment.ttiapath);
												}}
											>
												<Text textDecoration="underline">Delete</Text>
											</Button>
										</Box>
									);
								})
							}
						</HStack>
					}
					{
						(itemData.item.cancomment || itemData.item.canattach) &&
						<>
							<Divider pt={3} mx={{ base: 0, md: -5 }} />
							<HStack pt={2} gap={5}>
								{
									itemData.item.cancomment && <Button
										variant="link"
										color={commentActive ? "green.500" : "gray.600"}
										_hover={commentActive ? { color: "" } : {}}
										fontSize="sm"
										fontWeight={commentActive ? 600 : 500}
										leftIcon={<Icon as={BsChatDots} />}
										onClick={() => {
											photoActive && setPhotoActive(!photoActive);
											setCommentActive(!commentActive);
										}}
									>
										Comment
									</Button>
								}
								{
									itemData.item.canattach && <Button
										variant="link"
										color={photoActive ? "green.500" : "gray.600"}
										_hover={photoActive ? { color: "" } : {}}
										fontSize="sm"
										fontWeight={photoActive ? 600 : 500}
										leftIcon={<Icon as={BsPaperclip} />}
										onClick={() => {
											commentActive && setCommentActive(!commentActive);
											setPhotoActive(!photoActive);
										}}
									>
										<Text fontSize="sm">
											Add Media
										</Text>
									</Button>
								}
							</HStack>
							{
								isCommentModified && (
									<Text fontSize="sm" color="red.500" fontWeight={500}>
										Remember to save your comment
									</Text>
								)
							}
						</>
					}
					{
						itemData.item.cancomment && commentActive && <Box>
							<Textarea
								id={`CommentBox_${itemId}`}
								mt={2}
								mb={5}
								autoFocus={commentActive}
								defaultValue={itemData.value.ttifeedback}
								value={comment}
								onChange={handleCommentChange}
								onBlur={handleCommentBlur}
							/>

							<ButtonGroup>
								<Button
									colorScheme="gray"
									size="sm"
									onClick={() => {
										if ((isEmpty(comment) || comment === "\n") && !isEmpty(itemData.value.ttifeedback)) {
											setComment(itemData.value.ttifeedback);
										}

										setCommentActive(false);
										setPhotoActive(false);
									}}
								>
									Cancel
								</Button>

								<Button
									colorScheme="brand"
									size="sm"
									_hover={{ bg: "#248451" }}
									onClick={handleSaveComment}
								>
									Save
								</Button>
							</ButtonGroup>
						</Box>
					}
					{
						itemData.item.canattach && photoActive && <Box pt={2}>
							<DropZone
								w="full"
								onDropAction={onDropAction}
								fileUploadLoading={fileUploadLoading}
								additionalAcceptedFileTypes={["PDF", "WebP"]}
							/>

							<Button
								mt={5}
								colorScheme="gray"
								onClick={() => {
									setCommentActive(false);
									setPhotoActive(false);
								}}
							>
								Cancel
							</Button>
						</Box>
					}
				</VStack>
			</Box>

			{renderPdf()}
		</>
	);
};
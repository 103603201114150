import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Box,
	Divider,
	HStack,
	Icon,
	Text,
	Button,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Flex,
	Spacer,
	useBreakpointValue,
	useToast,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Input,
	Image,
	VStack,
	InputGroup,
	InputRightElement,
	Tooltip,
} from "@chakra-ui/react";
import { BsArrowLeftCircle, BsEye, BsGraphUp, BsShare, BsXCircleFill } from "react-icons/bs";
import { FaClipboardCheck } from "react-icons/fa";

import { CheckProductTierFlag } from "../../helpers/Utilities";
import { createPermalink, getClickthroughURL } from "../../features/auth/auth.actions";

import { TeamTasks } from "./teamTasks/TeamTasks";
import { GuidedTasks } from "./guidedTasks/GuidedTasks";
import TaskReporting from "./reporting/TaskReporting";

import { RootState } from "../../redux/rootReducer";
import { BiCopy } from "react-icons/bi";

import organise from "../../assets/images/viewing-files.svg";

export const Tasks = () => {
	const navigateTo = useNavigate();

	const { state }: any = useLocation();
	const isMobile = useBreakpointValue({ base: true, md: false });
	const toast = useToast();

	const currentFiltersRef = useRef<any>(null);

	const auth = useSelector((state: RootState) => state.authReducer);
	const user = useSelector((state: RootState) => state.authReducer.user);

	const [loading, setLoading] = useState(true);
	const [clearAllTeamTaskFilters, setClearAllTeamTaskFilters] = useState(false);
	const [clearAllGuidedTaskFilters, setClearAllGuidedTaskFilters] = useState(false);
	const [teamTasksfilterSelected, setTeamTasksFilterSelected] = useState(false);
	const [guidedTasksfilterSelected, setGuidedTasksFilterSelected] = useState(false);

	const [tabIndex, setTabIndex] = useState(state?.navData?.tabIndex || 0);
	const [pageIndex, setPageIndex] = useState(state?.navData?.pageIndex || 0);
	const [pageCount, setPageCount] = useState(state?.navData?.pageCount || 0);

	const [teamId, setTeamId] = useState(state?.navData?.teamId || 0);
	const [filter, setFilter] = useState(state?.navData?.filter || "all");
	const [category, setCategory] = useState(state?.navData?.category || "");
	const [search, setSearch] = useState(state?.navData?.search || "");

	const [permalinkData, setPermalinkData] = useState(state?.navData?.search || "");
	const [permalinkModalIsOpen, setPermalinkModalIsOpen] = useState<boolean>(false);

	const renderPermalinkModal = () => {
		return <Modal
			size="xl"
			isOpen={permalinkModalIsOpen}
			onClose={() => { setPermalinkModalIsOpen(false); }}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<HStack>
						<Text fontWeight={700}>Share This Report</Text>
					</HStack>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody>
					<VStack alignItems={'baseline'} gap={2}>
						<Box w='100%'>
							<Text
								fontWeight={500}
								color="gray.600"
								fontSize="sm"
								mb={1}
							>
								Report Link
							</Text>
							<InputGroup size='md'>
								<Input value={permalinkData.url} />
								<InputRightElement>
									<Tooltip
										hasArrow
										label="Copy Report Link"
										aria-label="Copy Report Link"
									>
										<Button
											mr={1}
											size='sm'
											onClick={() => {
												navigator.clipboard.writeText(permalinkData.url);
												toast({
													title: "Report Link Copied",
													description: "The report link has been copied to your clipboard.",
													status: "info",
													duration: 3000,
													isClosable: true
												});
											}}
										>
											<Icon as={BiCopy} />
										</Button>
									</Tooltip>
								</InputRightElement>
							</InputGroup>
						</Box>
						<Box
							className="hover-pop"
							cursor={'pointer'}
							rounded={'md'}
							boxShadow={'sm'}
							w={'100%'}
							p={2}
							onClick={() => getShareLink()}
						>
							<HStack>
								<Image src={organise} />
								<Text fontWeight={500}>
									Alternatively you can email this report link <u>here</u>.
								</Text>
							</HStack>
						</Box>
					</VStack>
				</ModalBody>
				<ModalFooter p={5}>
					<HStack>
						<Button
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								setPermalinkModalIsOpen(false);
							}}
						>
							Cancel
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const setTab = (index: number) => {
		const newUrl = index === 2
			? `${window.location.pathname}?filter=all&kind=all&page=0`
			: `${window.location.pathname}`;
		window.history.pushState({}, '', newUrl);

		// Only reset filters if we're not restoring from navigation
		if (index !== tabIndex && !state?.navData?.filters) {
			setTabIndex(index);
			setPageIndex(0);
			setTeamId(0);
			setFilter("");
			setCategory("");
			setSearch("");
		} else {
			setTabIndex(index);
		}
	};

	const getReportType = (acronym: string) => {
		switch (acronym) {
			case "TTH": return "team";
			case "GTH": return "guided";
			case "PTH": return "mandatory";
		}
	};

	const handleNavigateToTaskReport = (row: any) => {
		navigateTo(`/tasks/taskreport/${row.reportid}`, {
			state: {
				type: getReportType(row.reportacronym),
				querystring: window.location.search,
				from: "report",
				navData: {
					tabIndex: row.navData.tabIndex,
					filters: row.navData.filters,
					dateRange: row.navData.dateRange
				}
			}
		});
	};

	const clearAllFiltersPressed = () => {
		if (tabIndex === 0) {
			// Team tasks tab is open
			setClearAllTeamTaskFilters(true);
		} else if (tabIndex === 1) {
			// Guided tasks tab is open
			setClearAllGuidedTaskFilters(true);
		}
	};

	const handleClickThrough = async (path: string, id?: number) => {
		let url: string = await getClickthroughURL(path, id);
		window.open(url, "_self");
	};

	useEffect(() => {
		if (window.location.search) setTabIndex(2);
		setLoading(false);
	}, []);

	const getShareLink = () => {
		createPermalink({
			permalink: {
				task: currentFiltersRef.current?.search || "",
				team: currentFiltersRef.current?.team || 0,
				user: currentFiltersRef.current?.user || 0,
				category: currentFiltersRef.current?.category || "",
				filter: currentFiltersRef.current?.filter || "all",
				kind: currentFiltersRef.current?.kind || "all",
				startdate: currentFiltersRef.current?.startdate || "",
				enddate: currentFiltersRef.current?.enddate || "",
				action: "TaskReportsShareLink"
			}
		})
			.then((permalink: any) => {
				const mailtoLink = `mailto:?subject=Obbi%20Task%20Report%20Link&body=${encodeURIComponent(permalink.url)}`;
				window.location.href = mailtoLink;
			})
			.catch((error) => {
				console.error(error);
				toast({
					title: "Cannot Share Report",
					description: "The report email could not be generated.",
					status: "error",
					duration: 5000,
					isClosable: true
				});
			});
	};

	const createShareLink = () => {
		setPermalinkModalIsOpen(true);
		createPermalink({
			permalink: {
				task: currentFiltersRef.current?.search || "",
				team: currentFiltersRef.current?.team || 0,
				user: currentFiltersRef.current?.user || 0,
				category: currentFiltersRef.current?.category || "",
				filter: currentFiltersRef.current?.filter || "all",
				kind: currentFiltersRef.current?.kind || "all",
				startdate: currentFiltersRef.current?.startdate || "",
				enddate: currentFiltersRef.current?.enddate || "",
				action: "TaskReportsShareLink"
			}
		})
			.then((permalink: any) => {
				setPermalinkData(permalink);
				setPermalinkModalIsOpen(true);
			})
			.catch((error) => {
				console.error(error);
				toast({
					title: "Cannot Share Report",
					description: "The report link could not be generated.",
					status: "error",
					duration: 5000,
					isClosable: true
				});
			});
	};

	const updateFilters = useCallback((filters: any) => {
		currentFiltersRef.current = filters;
	}, []);

	return (
		<Box m={{ base: 0, md: 5 }} height={{ base: "100%", md: "unset" }}>
			<Button
				display={{ base: "none", md: "unset" }}
				w="fit-content"
				hidden={state?.from !== "home"}
				size="sm"
				mb={2}
				variant="outline"
				color="gray.600"
				fontWeight={600}
				onClick={() => {
					navigateTo(-1);
				}}
			>
				<HStack>
					<Icon as={BsArrowLeftCircle} />
					<Text>
						{
							state?.from === "home" ? "Back to Home" :
								state?.from === "notification" && "Back to Notification"
						}
					</Text>
				</HStack>
			</Button>

			{
				!loading ?
					<Box
						height={{ base: "max-content", md: "unset" }}
						px={{ base: 0, md: 5 }}
						pt={5}
						pb={3}
						bg={'white'}
						boxShadow={{ base: "none", md: "lg" }}
						rounded={{ base: "none", md: "lg" }}
					>
						<Button
							display={{ base: "unset", md: "none" }}
							hidden={state?.from !== "home"}
							size="sm"
							m={5}
							mb={8}
							textAlign="start"
							color="gray.600"
							variant="link"
							fontWeight={600}
							onClick={() => {
								navigateTo(-1);
							}}
						>
							<HStack>
								<Icon as={BsArrowLeftCircle} />
								<Text>
									{
										state?.from === "home" ? "Back to Home" :
											state?.from === "notification" && "Back to Notification"
									}
								</Text>
							</HStack>
						</Button>

						<HStack
							mx={{ base: 3, md: 5 }}
							mb={5}
							gap={2}
						>
							<Icon
								as={FaClipboardCheck}
								verticalAlign="middle"
								fontSize="4xl"
								color="green.500"
							/>
							<Text
								fontWeight={{ base: 600, lg: 700 }}
								fontSize={{ base: "lg", lg: "2xl" }}
								color="gray.700"
							>
								Tasks
							</Text>
							<Spacer />
							{
								(
									(tabIndex === 0 && teamTasksfilterSelected) ||
									(tabIndex === 1 && guidedTasksfilterSelected)
								) &&
								<Button
									variant="ghost"
									size="xs"
									onClick={clearAllFiltersPressed}
								>
									<HStack>
										<Icon as={BsXCircleFill} color="gray.600" fontSize="sm" />
										<Text>Clear All Filters</Text>
									</HStack>
								</Button>
							}
							{
								tabIndex === 2 && <Button
									color={'gray.600'}
									size={isMobile ? 'xs' : 'sm'}
									float="right"
									leftIcon={<Icon as={BsShare} />}
									onClick={createShareLink}
								>
									Share Report
								</Button>
							}
							{
								user?.permissions?.Tasks?.read && <Button
									display={{ base: "none", md: "flex" }}
									bg="green.500"
									_hover={{ bg: "#248451" }}
									size="sm"
									color="white"
									float="right"
									leftIcon={<Icon as={FaClipboardCheck} />}
									onClick={() => {
										handleClickThrough("ManageTasks");
									}}
								>
									Manage Tasks
								</Button>
							}
							{
								user?.permissions?.Tasks?.read && <HStack
									display={{ base: "block", md: "none" }}
									justifyContent="end"
								>
									<Button
										bg="green.500"
										size="xs"
										color="white"
										_hover={{ bg: "#248451" }}
										leftIcon={<Icon as={FaClipboardCheck} />}
										onClick={() => {
											handleClickThrough("ManageTasks");
										}}
									>
										Manage Tasks
									</Button>
								</HStack>
							}
						</HStack>

						<Divider w="unset" mx={{ base: 0, md: -5 }} />

						<Tabs
							colorScheme="green"
							defaultIndex={window.location.search !== '' ? 2 : state?.navData?.tabIndex || 0}
							onChange={(index: any) => setTab(index)}
						>
							{
								CheckProductTierFlag(user?.producttier?.ptobservedtasksflag) && <Flex
									justify={{ base: "center", md: "space-between" }}
									pt={2}
								>
									<TabList
										mx={{ base: 0, md: 5 }}
										w={{ base: "full", md: "unset" }}
										justifyContent="center"
									>
										<Tab>
											<Text
												color={tabIndex === 0 ? "green.500" : "gray.500"}
												fontWeight={tabIndex === 0 ? 700 : 500}
											>
												Team Tasks
											</Text>
										</Tab>
										{
											<Tab display={user.isTeamManager ? "flex" : "none"}>
												{
													!isMobile && <Icon as={BsEye} mr={2} />
												}
												<Text
													color={tabIndex === 1 ? "green.500" : "gray.500"}
													fontWeight={tabIndex === 1 ? 700 : 500}
												>
													Task Observations
												</Text>
											</Tab>
										}
										{
											auth.orgtaskreportstreamenabled && <Tab>
												{
													!isMobile && <Icon as={BsGraphUp} mr={2} />
												}
												<Text
													color={tabIndex === 2 ? "green.500" : "gray.500"}
													fontWeight={tabIndex === 2 ? 700 : 500}
												>
													Reports
												</Text>
											</Tab>
										}
									</TabList>
								</Flex>
							}

							<TabPanels>
								<TabPanel>
									{
										tabIndex === 0 && <TeamTasks
											tabIndex={tabIndex}
											pageCount={pageCount}
											setPageCount={setPageCount}
											pageIndex={pageIndex}
											setPageIndex={setPageIndex}
											teamId={teamId}
											setTeamId={setTeamId}
											filter={filter}
											setFilter={setFilter}
											category={category}
											setCategory={setCategory}
											search={search}
											setSearch={setSearch}
											clearAllFilters={clearAllTeamTaskFilters}
											setClearAllFilters={setClearAllTeamTaskFilters}
											setFilterSelected={setTeamTasksFilterSelected}
										/>
									}
								</TabPanel>
								<TabPanel>
									{
										tabIndex === 1 && <GuidedTasks
											tabIndex={tabIndex}
											pageCount={pageCount}
											setPageCount={setPageCount}
											pageIndex={pageIndex}
											setPageIndex={setPageIndex}
											filter={filter}
											setFilter={setFilter}
											teamId={teamId}
											setTeamId={setTeamId}
											category={category}
											setCategory={setCategory}
											search={search}
											setSearch={setSearch}
											clearAllFilters={clearAllGuidedTaskFilters}
											setClearAllFilters={setClearAllGuidedTaskFilters}
											setFilterSelected={setGuidedTasksFilterSelected}
										/>
									}
								</TabPanel>
								<TabPanel>
									{
										tabIndex === 2 && <TaskReporting
											onNavigateToTaskReport={handleNavigateToTaskReport}
											updateFilters={updateFilters}
										/>
									}
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Box> : <></>
			}

			{renderPermalinkModal()}
		</Box>
	);
};
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Image, Flex, Stack, Text, useBreakpointValue, Button, Icon, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import {
	BsHouse,
	BsEnvelope,
	BsAward,
	BsFileText,
	BsConeStriped,
	BsGraphUp,
	BsGear,
	BsFileBarGraph,
	BsWrench,
	BsArrowRepeat,
	BsExclamationCircle,
	BsBell,
	BsShieldFillExclamation
} from 'react-icons/bs';
import { VscMortarBoard } from 'react-icons/vsc';
import { FaClipboardCheck, FaEnvelope, FaHardHat, FaGraduationCap } from 'react-icons/fa';

import { RootState } from '../../redux/rootReducer';
import { getClickthroughURL, selectClassicObbi } from '../../features/auth/auth.actions';
import { CheckProductTierFlag } from '../../helpers/Utilities';
import { NavButton } from './NavButton';

import Logo from '../../assets/logos/obbi_logo_1051x640.png';

export const Sidebar: React.FC<any> = ({ toggleMenu, messageCount, isAdmin }) => {
	const org = useSelector((state: RootState) => state.authReducer);
	const user = useSelector((state: RootState) => state.authReducer.user);
	const location = useLocation();
	const navigateTo = useNavigate();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const getInitialExpandedIndex = () => {
		const path = location.pathname;
		if (path.includes('/messages') || path.includes('/notifications')) return 0;
		if (path.includes('/certificates')) return 1;
		if (path.includes('/tasks') || path.includes('/actions')) return 2;
		if (path.includes('/incidents') || path.includes('/riskassessments')) return 3;
		if (path.includes('/training') || path.includes('/documents')) return 4;
		return undefined;
	};

	const [expandedIndex, setExpandedIndex] = React.useState<number | undefined>(getInitialExpandedIndex());

	// Update expanded index when route changes
	useEffect(() => {
		const newIndex = getInitialExpandedIndex();
		setExpandedIndex(newIndex);
	}, [location.pathname]);

	const handleClickThrough = async (path: string) => {
		let url: string = await getClickthroughURL(path);
		window.open(url, '_self');
	};

	const isPathActive = (path: string) => window.location.pathname.includes(path);

	const isAnyChildActive = (paths: string[]): boolean => {
		return paths.some(path => window.location.pathname.includes(path));
	};

	const renderNavButton = (
		label: string,
		icon: any,
		path: string,
		onClick: () => void,
		toggleAlert?: boolean,
		alertCount?: number
	) => (
		<NavButton
			w='100'
			label={label}
			icon={icon}
			bgColor={isPathActive(path) ? 'green.500' : ''}
			color={isPathActive(path) ? 'white' : ''}
			onClick={onClick}
			toggleAlert={toggleAlert}
			alertCount={alertCount}
		/>
	);

	const renderAccordionItem = (
		title: string,
		icon: any,
		iconColor: string,
		items: {
			label: string,
			icon: any,
			path: string,
			onClick?: () => void,
			toggleAlert?: boolean,
			alertCount?: number
		}[]
	) => {
		const isActive = isAnyChildActive(items.map(item => item?.path).filter(Boolean) as string[]);

		return (
			<AccordionItem>
				<AccordionButton _hover={{ bg: '' }} px={3}>
					<Icon
						as={icon}
						mr={3}
						color={iconColor}
					/>
					<Flex flex='1' textAlign='left'>
						<Text
							color='gray.700'
							fontSize='xs'
							fontWeight={isActive ? 700 : 500}
						>
							{title}
						</Text>
					</Flex>
					<AccordionIcon color='gray.600' />
				</AccordionButton>
				<AccordionPanel px={0} pb={2}>
					<Stack>
						{items.map(item => item && renderNavButton(
							item.label,
							item.icon,
							item.path,
							item.onClick || (() => {
								navigateTo(item.path);
								if (isMobile) toggleMenu();
							}),
							item.toggleAlert,
							item.alertCount
						))}
					</Stack>
				</AccordionPanel>
			</AccordionItem>
		);
	};

	const newSidebar = (
		<Flex
			as='section'
			position='fixed'
			minHeight='full'
			height='100%'
			width='235px'
			overflowY='auto'
			zIndex={100}
			style={{ scrollbarWidth: 'thin', scrollbarColor: 'transparent' }}
		>
			<Flex
				flex='1'
				bg='bg-surface'
				maxW={{ base: 'full', sm: 'xs' }}
				py={{ base: 6, sm: 5 }}
				px={{ base: 4, sm: 6 }}
				overflowY='auto'
			>
				<Stack shouldWrapChildren width='100%'>
					{!isMobile && (
						<Image
							src={Logo}
							alt='Obbi logo'
							className='hover-pop'
							w='100px'
							cursor='pointer'
							onClick={() => {
								navigateTo('/home');
								toggleMenu();
							}}
						/>
					)}
					{isMobile && org.orgnewobbi === 1 && (
						<Button
							mb={3}
							size='sm'
							variant='outline'
							onClick={() => {
								selectClassicObbi().then(() => {
									handleClickThrough('Home');
								});
							}}
						>
							<Icon as={BsArrowRepeat} mr={2} /> Switch to Classic Obbi
						</Button>
					)}
					<Stack pt={{ base: 0, lg: 4 }}>
						{renderNavButton('Home', BsHouse, 'home', () => {
							navigateTo('home');
							if (isMobile) toggleMenu();
						})}

						{user.isTeamManager && (
							<>
								{renderNavButton('Team Overview', BsGraphUp, 'teamreports', () => {
									handleClickThrough('TeamUpdateReport');
									if (isMobile) toggleMenu();
								})}
								{renderNavButton('Reports', BsFileBarGraph, 'admin', () => {
									handleClickThrough('TeamReports');
									if (isMobile) toggleMenu();
								})}
							</>
						)}
					</Stack>
					<Accordion
						allowToggle
						index={expandedIndex}
						onChange={(index: any) => setExpandedIndex(index === expandedIndex ? undefined : index)}
					>
						{renderAccordionItem('Inbox', FaEnvelope, 'blue.500', [
							{ label: 'Messages', icon: BsEnvelope, path: '/messages', toggleAlert: messageCount > 0, alertCount: messageCount },
							{ label: 'Notifications', icon: BsBell, path: '/notifications' },
						])}
						{renderAccordionItem('Compliance', FaClipboardCheck, 'green.500', [
							{ label: 'Certificates', icon: BsAward, path: '/certificates' },
						])}
						{renderAccordionItem('Operations', FaHardHat, 'yellow.500', [
							{ label: 'Tasks', icon: FaClipboardCheck, path: '/tasks' },
							{ label: 'Actions', icon: BsWrench, path: '/actions' },
						])}
						{renderAccordionItem('Safety', BsShieldFillExclamation, 'red.500', [
							{ label: 'Incidents', icon: BsConeStriped, path: '/incidents' },
							org?.orgriskassessmentsenabled && CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag) && {
								label: 'Risk Assessments',
								icon: BsExclamationCircle,
								path: '/riskassessments'
							},
						].filter(Boolean))}
						{renderAccordionItem('Training', FaGraduationCap, 'purple.500', [
							{ label: 'Training Courses', icon: VscMortarBoard, path: '/training' },
							{ label: 'Documents', icon: BsFileText, path: '/documents' },
						])}
					</Accordion>

					{isAdmin && renderNavButton('Admin', BsGear, 'admin', () => {
						handleClickThrough('Admin');
						if (isMobile) toggleMenu();
					})}
				</Stack>
			</Flex>
		</Flex>
	);

	const classicSidebar = (
		<Flex
			as='section'
			position='fixed'
			minHeight='full'
			height='100%'
			width='235px'
			overflowY='auto'
			zIndex={100}
			style={{ scrollbarWidth: 'thin', scrollbarColor: 'transparent transparent' }}
		>
			<Flex
				flex='1'
				bg='bg-surface'
				maxW={{ base: 'full', sm: 'xs' }}
				py={{ base: 6, sm: 5 }}
				px={{ base: 4, sm: 6 }}
				overflowY='auto'
			>
				<Stack shouldWrapChildren width='100%'>
					{!isMobile && (
						<Image
							src={Logo}
							alt='Obbi logo'
							className='hover-pop'
							w='100px'
							cursor='pointer'
							onClick={() => {
								navigateTo('/home');
								toggleMenu();
							}}
						/>
					)}
					{isMobile && org.orgnewobbi === 1 && (
						<Button
							mb={3}
							size='sm'
							variant='outline'
							onClick={() => {
								selectClassicObbi().then(() => {
									handleClickThrough('Home');
								});
							}}
						>
							<Icon as={BsArrowRepeat} mr={2} /> Switch to Classic Obbi
						</Button>
					)}
					<Stack pt={{ base: 0, lg: 4 }}>
						<NavButton
							label='Home'
							icon={BsHouse}
							bgColor={window.location.pathname.includes('home') ? 'green.500' : ''}
							color={window.location.pathname.includes('home') ? 'white' : ''}
							onClick={() => {
								navigateTo('/home');
								if (isMobile) toggleMenu();
							}}
						/>
						{user.isTeamManager && (
							<>
								<NavButton
									label='Team Overview'
									icon={BsGraphUp}
									bgColor={window.location.pathname.includes('teamreports') ? 'green.500' : ''}
									color={window.location.pathname.includes('teamreports') ? 'white' : ''}
									onClick={() => {
										handleClickThrough('TeamUpdateReport');
										if (isMobile) toggleMenu();
									}}
								/>
								<NavButton
									label='Reports'
									icon={BsFileBarGraph}
									bgColor={window.location.pathname.includes('admin') ? 'green.500' : ''}
									color={window.location.pathname.includes('admin') ? 'white' : ''}
									onClick={() => {
										handleClickThrough('TeamReports');
										if (isMobile) toggleMenu();
									}}
								/>
							</>
						)}
					</Stack>
					<Text pt={1} color='gray.500' fontSize='xs' fontWeight={600}>
						Compliance
					</Text>
					<Stack>
						<NavButton
							label='Tasks'
							icon={FaClipboardCheck}
							bgColor={window.location.pathname.includes('tasks') ? 'green.500' : ''}
							color={window.location.pathname.includes('tasks') ? 'white' : ''}
							onClick={() => {
								navigateTo('/tasks');
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label='Actions'
							icon={BsWrench}
							bgColor={window.location.pathname.includes('actions') ? 'green.500' : ''}
							color={window.location.pathname.includes('actions') ? 'white' : ''}
							onClick={() => {
								navigateTo('/actions');
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label='Certificates'
							icon={BsAward}
							bgColor={window.location.pathname.includes('certificates') ? 'green.500' : ''}
							color={window.location.pathname.includes('certificates') ? 'white' : ''}
							onClick={() => {
								navigateTo('/certificates');
								if (isMobile) toggleMenu();
							}}
						/>
					</Stack>
					<Text pt={1} color='gray.500' fontSize='xs' fontWeight={600}>
						Awareness
					</Text>
					<Stack>
						<NavButton
							label='Training'
							icon={VscMortarBoard}
							bgColor={window.location.pathname.includes('training') ? 'green.500' : ''}
							color={window.location.pathname.includes('training') ? 'white' : ''}
							onClick={() => {
								navigateTo('/training');
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label='Documents'
							icon={BsFileText}
							bgColor={window.location.pathname.includes('documents') ? 'green.500' : ''}
							color={window.location.pathname.includes('documents') ? 'white' : ''}
							onClick={() => {
								navigateTo('/documents');
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label='Messages'
							icon={FaEnvelope}
							bgColor={window.location.pathname.includes('messages') ? 'green.500' : ''}
							color={window.location.pathname.includes('messages') ? 'white' : ''}
							onClick={() => {
								navigateTo('/messages');
								if (isMobile) toggleMenu();
							}}
							toggleAlert={messageCount > 0}
							alertCount={messageCount}
						/>
					</Stack>
					<Text pt={1} color='gray.500' fontSize='xs' fontWeight={600}>
						Safety & Standards
					</Text>
					<Stack>
						{org?.orgriskassessmentsenabled && CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag) && (
							<NavButton
								label='Risk Assessments'
								icon={BsExclamationCircle}
								bgColor={window.location.pathname.includes('riskassessments') ? 'green.500' : ''}
								color={window.location.pathname.includes('riskassessments') ? 'white' : ''}
								onClick={() => {
									navigateTo('/riskassessments');
									if (isMobile) toggleMenu();
								}}
							/>
						)}
						<NavButton
							label='Incidents'
							icon={BsConeStriped}
							bgColor={window.location.pathname.includes('incidents') ? 'green.500' : ''}
							color={window.location.pathname.includes('incidents') ? 'white' : ''}
							onClick={() => {
								navigateTo('/incidents');
								if (isMobile) toggleMenu();
							}}
						/>
					</Stack>
					{isAdmin && (
						<>
							<Text pt={1} color='gray.500' fontSize='xs' fontWeight={600}>
								Management
							</Text>
							<Stack mt={2}>
								<NavButton
									label='Admin'
									icon={BsGear}
									bgColor={window.location.pathname.includes('admin') ? 'green.500' : ''}
									color={window.location.pathname.includes('admin') ? 'white' : ''}
									onClick={() => {
										handleClickThrough('Admin');
										if (isMobile) toggleMenu();
									}}
								/>
							</Stack>
						</>
					)}
				</Stack>
			</Flex>
		</Flex>
	);

	return org?.orgnewsidebarenabled ? newSidebar : classicSidebar;
};
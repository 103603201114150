import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import TeamCombobox from './TeamCombobox';
import UserCombobox from './UserCombobox';
import { Team, TeamUserSelectionProps, User } from './types/TaskReportingTypes';

const DEFAULT_TEAM: Team = { teamid: 0, teamname: 'All Teams' };
const DEFAULT_USER: User = { uduid: 0, udforename: 'All Users' };

const TeamUserSelection: React.FC<TeamUserSelectionProps> = ({
    teams,
    allUsers,
    teamsWithUsers,
    onFiltersChange,
    isReset,
    onResetComplete,
    selectedTeamId,
    selectedUserId,
    disabled = false
}) => {
    const [{ selectedTeam, selectedUser }, setSelections] = useState(() => {
        const team = teams.find(t => t.teamid === selectedTeamId) || DEFAULT_TEAM;
        const user = allUsers.find(u => u.uduid === selectedUserId) || DEFAULT_USER;
        return { selectedTeam: team, selectedUser: user };
    });

    const handleTeamUpdate = (team: Team) => {
        setSelections(prev => ({
            selectedTeam: team,
            selectedUser: DEFAULT_USER
        }));
        onFiltersChange(team.teamid, 0);
    };

    const handleUserUpdate = (user: User) => {
        setSelections(prev => ({
            ...prev,
            selectedUser: user
        }));
        onFiltersChange(selectedTeam.teamid, user.uduid);
    };

    useEffect(() => {
        if (isReset) {
            setSelections({
                selectedTeam: DEFAULT_TEAM,
                selectedUser: DEFAULT_USER
            });
            onResetComplete?.();
            return;
        }

        const team = teams.find(t => Number(t.teamid) === selectedTeamId);
        const user = allUsers.find(u => Number(u.uduid) === selectedUserId);

        if (team || user) {
            setSelections(prev => ({
                selectedTeam: team || prev.selectedTeam,
                selectedUser: user || prev.selectedUser
            }));
        }
    }, [isReset, selectedTeamId, selectedUserId, teams, allUsers, onResetComplete]);

    return (
        <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={2}
            w='100%'
        >
            <Box flex='1' position='relative'>
                <TeamCombobox
                    teams={teams}
                    selectedTeam={selectedTeam}
                    update={handleTeamUpdate}
                    disabled={disabled}
                />
            </Box>
            <Box flex='1' position='relative'>
                <UserCombobox
                    users={allUsers}
                    selectedUser={selectedUser}
                    update={handleUserUpdate}
                    disabled={disabled}
                    filterByTeam={selectedTeam.teamid !== 0 ? selectedTeam.teamid : undefined}
                    teamsWithUsers={teamsWithUsers}
                />
            </Box>
        </Stack>
    );
};

export default TeamUserSelection;
import { Fragment, useState } from "react";
import { isUndefined } from "lodash";
import { Box, Avatar, Icon, HStack, Text } from "@chakra-ui/react";
import { Combobox, Transition } from '@headlessui/react';
import { BsArrowReturnRight, BsChevronDown, BsPeople, BsSearch } from "react-icons/bs";
import _ from "lodash";

export const TeamMessageCombobox: React.FC<any> = ({
    teams,
    update,
    disabled = false,
    placeholder = ''
}) => {
    const [selected, setSelected] = useState<any>();
    const [query, setQuery] = useState("");
    const [canScroll, setCanScroll] = useState(false);

    const checkScrollable = (element: HTMLElement) => {
        if (element) {
            setCanScroll(element.scrollHeight > element.clientHeight);
        }
    };

    const filteredTeams =
        query === ""
            ? teams
            : teams.filter((team: any) => {
                return team.teamname?.toLowerCase().includes(query.toLowerCase())
            });

    return (
        <Combobox
            value={selected}
            disabled={disabled}
            onChange={(e: any) => { update(e); }}
        >
            <Box
                border='1px solid'
                borderColor={!disabled ? '#e2e8f0' : ''}
                rounded='lg'
                style={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
                alignItems='center'
                justifyContent={'center'}
            >
                <Box mx={2} w='100%'>
                    <Combobox.Button
                        hidden={disabled}
                        style={{ width: "100%", textAlign: "start" }}
                    >
                        <HStack>
                            <Avatar
                                size="xs"
                                name={!isUndefined(selected?.teamname) ? selected?.teamname : ""}
                                color="white"
                            />
                            <Combobox.Input
                                className="combobox"
                                autoComplete="off"
                                defaultValue={''}
                                onChange={(event: any) => {
                                    setQuery(event.target.value);
                                }}
                                style={{
                                    width: 'calc(100% - 30px)',
                                    color: '#4a5568',
                                    padding: '4px',
                                    backgroundColor: 'transparent',
                                    marginLeft: '5px'
                                }}
                                placeholder={placeholder}
                            />
                            <Icon
                                as={BsChevronDown}
                                color="gray.500"
                                float="right"
                                mt={2}
                            />
                        </HStack>
                    </Combobox.Button>
                </Box>
            </Box>

            <Transition
                as={Fragment}
                leave="transition ease-in duration-600"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
            >
                <Combobox.Options
                    as={Box}
                    className={'combobox-options'}
                    disabled={disabled}
                    rounded="lg"
                    ref={(element: HTMLElement | null) => {
                        if (element) checkScrollable(element);
                    }}
                    style={{
                        border: '1px solid',
                        borderColor: 'rgba(48, 49, 51, 0.05)',
                        boxShadow: '0px 0px 10px rgba(48, 49, 51, 0.05)',
                        listStyle: 'none',
                        zIndex: 9999,
                        position: 'absolute',
                        backgroundColor: 'white',
                        left: 0,
                        maxHeight: '200px',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        '-webkit-overflow-scrolling': 'touch'
                    }}
                >
                    {
                        filteredTeams.length === 0 && query !== "" ? (
                            <HStack
                                p={2}
                                bg="white"
                                rounded="md"
                                boxShadow="md"
                            >
                                <Icon as={BsSearch} color="gray.500" />
                                <Text
                                    fontSize="sm"
                                    cursor="pointer"
                                >
                                    Nothing found
                                </Text>
                            </HStack>
                        ) : (
                            <>
                                {filteredTeams.map((team: any, i: number) => (
                                    <Combobox.Option
                                        key={`${team?.teamid}`}
                                        value={team}
                                    >
                                        {({ active }) => {
                                            return <HStack
                                                w="100%"
                                                p={2}
                                                bgColor={active ? "gray.50" : ""}
                                                cursor="pointer"
                                            >
                                                <Avatar
                                                    size="xs"
                                                    name={
                                                        !isUndefined(team?.teamname) ? team?.teamname : ""
                                                    }
                                                    color="white"
                                                    verticalAlign="middle"
                                                />

                                                <Text
                                                    fontWeight={500}
                                                    fontSize="sm"
                                                    color="gray.600"
                                                    wordBreak="break-word"
                                                    textAlign="left"
                                                >
                                                    {team?.teamname}
                                                </Text>
                                            </HStack>
                                        }}
                                    </Combobox.Option>
                                ))}
                                {canScroll && (
                                    <Box
                                        position='sticky'
                                        bottom={0}
                                        left={0}
                                        right={0}
                                        height='-webkit-fit-content'
                                        pointerEvents='none'
                                    />
                                )}
                            </>
                        )}
                </Combobox.Options>
            </Transition>
        </Combobox>
    )
};
import { useCallback, useEffect, useState } from "react";
import {
	Box,
	Center,
	Divider,
	FormControl,
	Grid,
	Input,
	Image,
	Select,
	Text,
	VStack,
	SlideFade,
	Stack,
	useToast,
	HStack,
	Spacer,
	Accordion,
	AccordionItem,
	AccordionIcon,
	AccordionButton,
	AccordionPanel,
	useBreakpointValue,
	Button,
	Icon,
} from "@chakra-ui/react";
import { FaClipboardCheck } from "react-icons/fa";

import { getTasks } from "../../../features/tasks/task.actions";

import { useDebounce } from "../../common/useDebounce";
import { InsightActionCard } from "../../common/InsightActionCard";
import { SmallFilterCard } from "../../common/SmallFilterCard";
import { TeamCombobox } from "../../common/TeamCombobox";
import { TeamTaskTable } from "../TeamTaskTable";
import { MandatoryTaskTable } from "../MandatoryTaskTable";

import overdue from "../../../assets/images/incident.svg";
import dueToday from "../../../assets/images/waiting.svg";
import dueThisWeek from "../../../assets/images/nothingToSee.svg";
import noTasks from "../../../assets/images/no-tasks.svg";
import mandatory from "../../../assets/images/mandatoryTask.svg";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { localTime } from "../../../helpers/DayJsHelper";
import { CheckProductTierFlag } from "../../../helpers/Utilities";
import { BsPlusCircle } from "react-icons/bs";
import { getClickthroughURL } from "../../../features/auth/auth.actions";

export const TeamTasks: React.FC<any> = ({
	tabIndex,
	pageCount,
	setPageCount,
	pageIndex,
	setPageIndex,
	teamId,
	setTeamId,
	filter,
	setFilter,
	category,
	setCategory,
	search,
	setSearch,
	clearAllFilters,
	setClearAllFilters,
	setFilterSelected
}) => {
	const org = useSelector((state: RootState) => state.authReducer);
	const user = useSelector((state: RootState) => state.authReducer.user);
	const isMobile = useBreakpointValue({ base: true, xl: false });

	const [overdueTaskCount, setOverdueTaskCount] = useState(0);
	const [tasksDueTodayCount, setTasksDueTodayCount] = useState(0);
	const [tasksDueThisWeekCount, setTasksDueThisWeekCount] = useState(0);

	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [loading, setLoading] = useState<boolean>(false);

	const [categories, setCategories] = useState([]);
	const [teams, setTeams] = useState([]);

	const [currentItems, setCurrentItems] = useState([]);
	const [mandatoryTasks, setMandatoryTasks] = useState([]);

	const [sort, setSort] = useState<string>("duedate");
	const [kind] = useState<string>("all");
	const [error, setError] = useState<string>('');

	const toast = useToast();

	const handleClickThrough = async (path: string, id?: number) => {
		let url: string = await getClickthroughURL(path, id);
		window.open(url, "_self");
	};

	const fetchData = useCallback(
		async ({
			filter,
			category,
			teamId,
			search,
			pageIndex,
			sort
		}) => {
			await getTasks(
				sort,
				filter,
				kind,
				teamId,
				category,
				search,
				(pageIndex + 1).toString(),
				user.isTeamManager
			)
				.then((res: any) => {
					const formattedCurrentItems = res.tasks.map((task: any) => {
						const lastReportDate = task?.tth[0]?.tthdatesignedoff;
						const lastReportSignedOffBy = task?.tth[0]?.signedoffbyname;

						let lastReportStatus = "";

						if (task.tth.length > 0 && task.tth[0]?.isfailed) {
							lastReportStatus = "failed";
						} else if (task.tth.length > 0 && task.tth[0]?.ispassed) {
							lastReportStatus = "passed";
						}

						return {
							id: task.ttid,
							taskId: task.taskid,
							teamId: task.teamid,
							title: {
								mandatory: task.canstartperiodictask && !task.canstartteamtask && !task.canstartguidedtask,
								overdue: task.overdue,
								name: task.taskname
							},
							status: task.overdue ? "overdue" : task.whendue === "today" ? "today" : "",
							due: task.ttdatedue,
							inProgress: task.hasattempts,
							lastReport: {
								date: lastReportDate,
								status: lastReportStatus,
								signedOffBy: lastReportSignedOffBy
							},
							assigned: task.teamname,
							whenDue: task.whendue,
							action: lastReportStatus,
						};
					});

					setCurrentItems(formattedCurrentItems);

					if (CheckProductTierFlag(user?.producttier?.ptmandatorytasksflag) && org.orgmandatorytasksenabled) {
						const formattedMandatoryItems = res.mandatoryTasks.map((task: any) => {
							return {
								id: task.ttid,
								taskId: task.taskid,
								taskName: task.taskname,
								teamId: task.teamid,
								assigned: task.teamname,
								due: `Today, ${localTime(task.periodictaskstarttime)} - ${localTime(task.periodictaskendtime)}`,
								title: {
									mandatory: true,
									overdue: false,
									name: task.taskname
								}
							};
						});
						setMandatoryTasks(formattedMandatoryItems);
					}

					setPageCount(res.pageCount);
					setCategories(res.categories);
					setTeams(res.teams);

					setOverdueTaskCount(res.overdueTaskCount);
					setTasksDueTodayCount(res.tasksDueTodayCount);
					setTasksDueThisWeekCount(res.tasksDueThisWeekCount);
				})
				.catch((error: any) => {
					console.log(error);
				});
		}, []
	);

	useEffect(() => {
		setLoading(true);

		fetchData({
			filter,
			category,
			teamId,
			search,
			pageIndex,
			sort
		}).then(() => {
			setLoading(false);
			setFirstTimeLoading(false);
		});
	}, [fetchData, filter, category, teamId, search, pageIndex, sort]);

	useEffect(() => {
		const filterInEffect =
			search !== "" ||
			(filter !== "" && filter !== "all") ||
			(teamId !== 0 && teamId !== undefined) ||
			(category !== "" && category !== undefined);

		setFilterSelected(filterInEffect);
	}, [filter, category, teamId, search]);

	useEffect(() => {
		if (clearAllFilters) {
			setPageIndex(0);
			setTeamId(0);
			setFilter("all");
			setCategory("");
			setSearch("");

			setSort("duedate");
			setError('');
			setClearAllFilters(false);
			setFilterSelected(false);
		}
	}, [clearAllFilters]);

	const debouncedRequest = useDebounce(() => {
		setPageIndex(0);

		fetchData({
			filter,
			category,
			teamId,
			search,
			pageIndex: 0,
			sort
		});
	}, 300);

	const updateSearch = (e: any) => {
		setSearch(e.target.value);
		debouncedRequest();
	};

	const updateTeam = (value: string) => {
		setPageIndex(0);
		setTeamId(value);
	};

	const updateCategory = (category: string) => {
		setPageIndex(0);
		setCategory(category);
	};

	return (
		<Box>
			<Grid
				templateColumns={{
					base: "repeat(3, 1fr)",
					md: "repeat(4, 1fr)"
				}}
				gap={5}
				mb={5}
				mt={1}
			>
				<InsightActionCard
					title="Overdue Tasks"
					count={overdueTaskCount}
					image={overdue}
					color="red.500"
					active={filter === "overdue"}
					loading={firstTimeLoading}
					onClickAction={() => {
						if (filter === "overdue") {
							setFilter("all");
							setPageIndex(0);
						} else {
							setFilter("overdue");
							setPageIndex(0);
						}
					}}
				/>

				<InsightActionCard
					title="Tasks Due Today"
					count={tasksDueTodayCount}
					image={dueToday}
					color="warning.500"
					active={filter === "today"}
					loading={firstTimeLoading}
					onClickAction={() => {
						if (filter === "today") {
							setFilter("all");
							setPageIndex(0);
						} else {
							setFilter("today");
							setPageIndex(0);
						}
					}}
				/>

				<InsightActionCard
					title="Tasks Due This Week"
					count={tasksDueThisWeekCount}
					image={dueThisWeek}
					color="warning.500"
					active={filter === "thisweek"}
					loading={firstTimeLoading}
					onClickAction={() => {
						if (filter === "thisweek") {
							setFilter("all");
							setPageIndex(0);
						} else {
							setFilter("thisweek");
							setPageIndex(0);
						}
					}}
				/>
			</Grid>

			{
				!isMobile && <Divider w="unset" mx={{ base: 0, md: -10 }} />
			}

			{
				mandatoryTasks.length > 0 && !firstTimeLoading && <Accordion
					allowToggle
					defaultIndex={[0]}
					p={3}
					mt={5}
					w="100%"
					rounded="lg"
					border="1px"
					borderColor={firstTimeLoading ? "transparent" : "gray.200"}
				>
					<AccordionItem border="none">
						<AccordionButton
							p={3}
							pl={2}
							_hover={{ bg: "gray.50" }}
						>
							<HStack w="full">
								<Image src={mandatory} h="55px" />

								<VStack alignItems="start">
									<Text
										fontSize={{ base: "12pt", sm: "lg" }}
										fontWeight={600}
										color="purple.500"
										textAlign="left"
									>
										You have {mandatoryTasks.length.toString()} Mandatory Task{mandatoryTasks.length > 1 && "s"} to complete today
									</Text>
								</VStack>

								<Spacer />

								<AccordionIcon color="gray.500" />
							</HStack>
						</AccordionButton>

						<AccordionPanel p={0} mt={{ base: 3, md: "unset" }}>
							<MandatoryTaskTable
								loading={firstTimeLoading}
								currentItems={mandatoryTasks}
								navData={{
									tabIndex,
									pageCount,
									pageIndex,
									filter,
									category,
									teamId,
									search,
									isGuided: false
								}}
							/>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			}

			<SlideFade in={!firstTimeLoading}>
				{
					categories?.length > 0 && categories?.length <= 7 && <Box
						p={1.5}
						mt={5}
						bg="gray.50"
						textAlign="left"
						rounded="lg"
						border="1px solid"
						borderColor="gray.100"
					>
						<Text
							fontWeight={500}
							color="gray.500"
							fontSize="sm"
							ml={1}
							mb={2}
						>
							Task Categories
						</Text>

						<SlideFade in={!firstTimeLoading}>
							<HStack>
								{
									categories?.map((c: any, i: Number) => {
										return (
											<SmallFilterCard
												key={i}
												title={c.taskcategory}
												count={c.taskcount}
												onClickAction={() => {
													c.taskcategory !== category
														? updateCategory(c.taskcategory)
														: updateCategory("")
												}}
												selectedCard={category}
												icon={FaClipboardCheck}
												iconColor="brand.500"
											/>
										);
									})
								}
							</HStack>
						</SlideFade>
					</Box>
				}
			</SlideFade>

			<SlideFade in={!firstTimeLoading} offsetY="20px">
				<Stack
					direction={{ base: "column", lg: "row" }}
					mt={
						categories.length > 0 && categories.length <= 7 ? 2 :
							categories.length > 7 ? 5 : 5
					}
				>
					{
						categories?.length > 7 && <>
							<FormControl w={{ base: "full", lg: "50%" }}>
								<Input
									placeholder="Search Tasks"
									fontSize="sm"
									value={search}
									onChange={updateSearch}
								/>
							</FormControl>

							{
								error && <Text
									mt={2}
									ml={2}
									color={'red.500'}
									fontSize="sm"
								>
									{error}
								</Text>
							}
						</>
					}

					{
						categories?.length > 7 ? <FormControl
							colorScheme="green"
							w={{ base: "full", lg: "50%" }}
						>
							<Select
								_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
								color="gray.600"
								fontSize="sm"
								onChange={(e) => {
									updateCategory(e.target.value);
								}}
								placeholder="Search by Category"
							>
								{
									categories?.map((c: any, i) => {
										return <option key={`category_${i}`} value={c.taskcategory}>
											{c.taskcategory}
										</option>;
									})
								}
							</Select>
						</FormControl> :

							<FormControl w={{ base: "full", lg: "50%" }}>
								<Input
									placeholder="Search Tasks"
									fontSize="sm"
									value={search}
									onChange={updateSearch}
								/>
							</FormControl>
					}

					<FormControl
						colorScheme="green"
						w={{ base: "full", lg: "50%" }}
					>
						<TeamCombobox
							teams={teams || []}
							update={updateTeam}
							selectedTeam={teamId}
						/>
					</FormControl>
				</Stack>
			</SlideFade>

			<Box
				p={3}
				mt={5}
				rounded="lg"
				border="1px"
				borderColor={firstTimeLoading ? "transparent" : "gray.200"}
			>
				{
					currentItems?.length > 0 && !firstTimeLoading ? <TeamTaskTable
						loading={loading}
						currentItems={currentItems}
						sort={sort}
						setSort={setSort}
						setPageIndex={setPageIndex}
						navData={{
							tabIndex,
							pageCount,
							pageIndex,
							filter,
							category,
							teamId,
							search,
							isGuided: false
						}}
					/> :

						<SlideFade in={!loading} offsetY="20px">
							<Center m={5} hidden={firstTimeLoading || loading}>
								<VStack>
									<Image mb={8} src={noTasks} />

									<Text fontSize="xl" fontWeight={600}>
										No Tasks Found
									</Text>

									<Button
										bg="green.500"
										size="sm"
										w={{ base: "100%", md: "unset" }}
										color="white"
										float="right"
										_hover={{ bg: "#248451" }}
										leftIcon={<Icon as={BsPlusCircle} />}
										onClick={() => {
											handleClickThrough("CreateTask");
										}}
									>
										Create a Task
									</Button>
								</VStack>
							</Center>
						</SlideFade>
				}
			</Box>
		</Box>
	);
};
import parse, { HTMLReactParserOptions, Element as ParserElement, domToReact, DOMNode } from 'html-react-parser';
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, UnorderedList, OrderedList, ListItem, Image } from '@chakra-ui/react';
import React from 'react';
import DOMPurify from 'dompurify';

interface SecureHtmlRendererProps {
    content: string;
}

const sanitizeImageURL = (p: any) => {
    const baseUrl = process.env.REACT_APP_OBBI_API_URL;

    // only accept absolute URLs in whitelisted domains

    if (p.startsWith(baseUrl)) return p;

    return "";
};

const options: HTMLReactParserOptions = {
    replace: (domNode) => {
        if (domNode && (domNode as any).type === 'tag') {
            const element = domNode as ParserElement;

            // Handle images
            if (element.name === 'img') {

                const src = element.attribs.src;
                const alt = element.attribs.alt || '';
                const width = element.attribs.width ?
                    isNaN(Number(element.attribs.width)) ? element.attribs.width : `${element.attribs.width}px`
                    : undefined;
                const height = element.attribs.height ?
                    isNaN(Number(element.attribs.height)) ? element.attribs.height : `${element.attribs.height}px`
                    : undefined;

                return <Image
                    width={width}
                    height={height}
                    src={sanitizeImageURL(src)}
                    alt={alt}
                    style={{
                        maxWidth: '100%',
                        display: 'block'
                    }}
                />
            }

            // Handle lists
            if (element.name === 'ul') {
                return (
                    <UnorderedList paddingLeft="40px" marginY={4}>
                        {domToReact(element.children as DOMNode[], options)}
                    </UnorderedList>
                );
            }

            if (element.name === 'ol') {
                return (
                    <OrderedList paddingLeft="40px" marginY={4}>
                        {domToReact(element.children as DOMNode[], options)}
                    </OrderedList>
                );
            }

            if (element.name === 'li') {
                return (
                    <ListItem marginY={2}>
                        {domToReact(element.children as DOMNode[], options)}
                    </ListItem>
                );
            }

            //handle pre
            if (element.name === 'pre') {
                return (
                    <Box
                        as="pre"
                        whiteSpace="pre-wrap"
                        padding={4}
                        marginY={4}
                        borderRadius="md"
                        bg="gray.100"
                    >
                        {domToReact(element.children as DOMNode[], options)}
                    </Box>
                );
            }

            // // Handle tables
            // if (element.name === 'table') {
            //     const children = element.children ? domToReact(element.children as DOMNode[]) : null;
            //     return (
            //         <TableContainer>
            //             <Table>
            //                 {children}
            //             </Table>
            //         </TableContainer>
            //     );
            // }

            // // Map HTML table elements to Chakra components
            // const tableComponents: { [key: string]: any } = {
            //     thead: Thead,
            //     tbody: Tbody,
            //     tr: Tr,
            //     th: Th,
            //     td: Td
            // };

            // if (tableComponents[element.name]) {
            //     const Component = tableComponents[element.name];
            //     const children = element.children ? domToReact(element.children as DOMNode[], options) : null;
            //     return <Component>{children}</Component>;
            // }

        }
    }
};

export const SecureHtmlRenderer: React.FC<SecureHtmlRendererProps> = ({
    content
}) => {
    // Clean content
    const clean = DOMPurify.sanitize(content);

    return (
        <Box>
            {parse(clean, options)}
        </Box>
    );
};
import { useSelector } from "react-redux";
import { RootState } from "./redux/rootReducer";
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate
} from "react-router-dom";
import { Box, Icon } from "@chakra-ui/react";
import { BsBellFill } from "react-icons/bs";
import { AiOutlineAudit, AiOutlineDatabase, AiOutlineWarning } from "react-icons/ai";

import ProtectedRoutes from './ProtectedRoutes';
import { TokenValidatedRoutes } from "./components/ExternalRouting";
import { CheckProductTierFlag } from "./helpers/Utilities";

import { Login } from './components/login/Login';
import { Shell } from './components/shell/Shell';
import { HomePage } from './components/homepage/Homepage';

import { Profile } from "./components/profile/Profile";

import { Messages } from './components/messages/Messages';
import { CreateMessage } from './components/messages/CreateMessage';
import { SentMessage } from './components/messages/SentMessage';
import { Message } from './components/messages/Message';

import { Notifications } from './components/notifications/Notifications';
import { Notification } from './components/notifications/Notification';

import { Tasks } from './components/tasks/Tasks';
import { Task } from './components/tasks/Task';
import { TaskSummary } from './components/tasks/TaskSummary';
import { TaskComplete } from './components/tasks/TaskComplete';
import { TaskReport } from './components/tasks/TaskReport';

import { Documents } from './components/documents/Documents';
import { Certificates } from './components/certificates/Certificates';

import { Actions } from './components/actions/Actions';
import { Action } from './components/actions/Action';

import { Incidents } from './components/incidents/Incidents';
import { Incident } from './components/incidents/Incident';
import { CreateIncident } from './components/incidents/CreateIncident';
import { CreateAccident } from './components/incidents/CreateAccident';
import { CreateNearMiss } from './components/incidents/CreateNearMiss';

import { Training } from './components/training/Training';
import { Course } from './components/training/Course';
import { Module } from './components/training/Module';
import { ModuleComplete } from './components/training/ModuleComplete';

import { ContactSales } from './components/common/ContactSales';

import { RiskAssessments } from "./components/riskAssessments/RiskAssessments";
import { RiskAssessmentEditor } from "./components/riskAssessments/RiskAssessmentEditor";
import { PreCreateRiskAssessment } from "./components/riskAssessments/PreCreateRiskAssessment";
import { RiskAssessment } from "./components/riskAssessments/RiskAssessment";

import actions from "./assets/images/incident.svg";
import certificates from "./assets/images/certificateExpiring.svg";
import TaskReporting from "./components/tasks/reporting/TaskReporting";
import Report from "./components/tasks/reporting/Report";
import CreateRAFromTemplateSelect from "./components/riskAssessments/CreateRAFromTemplateSelect";
import RiskAssessmentClone from "./components/riskAssessments/RiskAssessmentClone";

const Router = () => {
	const user = useSelector((state: RootState) => state.authReducer.user);

	const canAccessTasks = CheckProductTierFlag(user?.producttier?.pttasksflag);
	const canAccessActions = CheckProductTierFlag(user?.producttier?.ptactionsflag);
	const canAccessRiskAssessments = CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag);
	const canAccessCertificates = CheckProductTierFlag(user?.producttier?.ptcertificatesflag);

	const taskSalesComponent = <Box
		p={5}
		m={5}
		bg="white"
		rounded="lg"
	>
		<ContactSales
			title="Take control of your organisation's operations with Tasks"
			lineOne="Unlock the full potential of Obbi by enabling Tasks!"
			lineTwo=""
			image={null}
			buttonOneText="Contact Sales"
			buttonOneLink="https://calendly.com/gareth-macklin/45-minute-meeting-uk"
			buttonTwoText="Learn More"
			buttonTwoLink="https://obbi.golf/features/"
		/>
	</Box>;

	const actionSalesComponent = <Box
		p={5}
		m={5}
		bg="white"
		rounded="lg"
	>
		<ContactSales
			title="Manage your facilities and response maintenance with Actions in Obbi!"
			lineOne="The Actions feature in Obbi provides clubs with automated issue reporting from your checklists, as well as tracking and managing follow-up actions with your team."
			image={actions}
			buttonOneText="Contact Sales"
			buttonOneLink="https://calendly.com/gareth-macklin/45-minute-meeting-uk"
			buttonTwoText="Learn More"
			buttonTwoLink="https://obbi.club/feature/facility-maintenance/"
			attributeIcon1={<Icon as={AiOutlineAudit} color="green.400" />}
			attributeTitle1="Full Traceability"
			attributeText1="With a complete audit history for all changes, blockers, updates and completions, you’ll never be left guessing what work was done."
			attributeIcon2={<Icon as={BsBellFill} color="purple.400" />}
			attributeTitle2="Notify the right people"
			attributeText2="Set up alerts to either internal staff or external parties, depending on your own club's setup."
			attributeIcon3={<Icon as={AiOutlineWarning} color="blue.500" />}
			attributeTitle3="Important issues get addressed"
			attributeText3="Whether it’s a safety issue discovered during a risk assessment, or a machine fault discovered during pre-start checks, with Actions you can make sure the most important things are visible and getting done."
		/>
	</Box>;

	const riskAssessmentSalesComponent = <Box
		p={5}
		m={5}
		bg="white"
		rounded="lg"
	>
		<ContactSales
			title="Take control of hazard management at your club with Obbi!"
			lineOne="The Risk Assessments feature in Obbi provides both managers and staff with a simple way to effectively manage and reduce safety risks around your club."
			lineTwo=""
			image={null}
			buttonOneText="Contact Sales"
			buttonOneLink="https://calendly.com/gareth-macklin/45-minute-meeting-uk"
			buttonTwoText="Learn More"
			buttonTwoLink="https://obbi.golf/features/"
			attributeIcon1={<Icon as={AiOutlineAudit} color="green.400" />}
			attributeTitle1="Unlimited oversight"
			attributeText1="With unlimited risk assessment creation, you can ensure you’ve got coverage across all significant areas of risk."
			attributeIcon2={<Icon as={AiOutlineDatabase} color="purple.400" />}
			attributeTitle2="Safety action plans"
			attributeText2="Create and track follow-up actions, including alerts to assigned teams and full visibility during inspections and audits."
		/>
	</Box>;

	const certificateSalesComponent = <Box
		p={5}
		m={5}
		bg="white"
		rounded="lg"
	>
		<ContactSales
			title="Track staff and club certificates in one place, with Certificates in Obbi!"
			lineOne="The Certificates feature in Obbi allows you to manage the full lifecycle of certificates, whether for individual members of staff or for your club’s buildings and facilities."
			lineTwo=""
			image={certificates}
			buttonOneText="Contact Sales"
			buttonOneLink="https://calendly.com/gareth-macklin/45-minute-meeting-uk"
			buttonTwoText="Learn More"
			buttonTwoLink="https://obbi.golf/features/"
			attributeIcon1={<Icon as={AiOutlineAudit} color="green.400" />}
			attributeTitle1="Seamless Upload"
			attributeText1="Effortlessly upload and store certificates in a secure and accessible platform while easily assigning them to team members."
			attributeIcon2={<Icon as={BsBellFill} color="purple.400" />}
			attributeTitle2="Automatic reminders"
			attributeText2="Never miss a certificate expiry again, with automatic email alerts when certificates are approaching their expiry date."
			attributeIcon3={<Icon as={AiOutlineDatabase} color="blue.500" />}
			attributeTitle3="Audit-ready"
			attributeText3="Access a complete history of all certificate activities, including assignments and expirations, for enhanced oversight and reporting."
		/>
	</Box>;

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/login" element={<Login />} />

				<Route path="/go/*" element={<TokenValidatedRoutes />} />

				<Route path="/" element={<ProtectedRoutes />}>
					<Route element={<Shell />}>

						{/* Homepage */}
						<Route path="/" element={<Navigate to="/home" replace />} />
						<Route path="/home" element={<HomePage />} />

						{/* Profile */}
						<Route path="/profile" element={<Profile />} />

						{/* Messages */}
						<Route path="/messages" element={<Messages />} />
						<Route path="/messages/message/:id" element={<Message />} />
						<Route path="/messages/sentmessage/:id" element={<SentMessage />} />
						<Route path="/messages/create" element={<CreateMessage />} />
						<Route path="/messages/create/:id" element={<CreateMessage />} />

						{/* Notifications */}
						<Route path="/notifications" element={<Notifications />} />
						<Route path="/notifications/notification/:id" element={<Notification />} />

						{/* Tasks */}
						<Route
							path="/tasks"
							element={canAccessTasks ? <Tasks /> : taskSalesComponent}
						/>
						<Route
							path="/tasks/tasksummary/:id"
							element={canAccessTasks ? <TaskSummary /> : taskSalesComponent}
						/>
						<Route
							path="/tasks/taskreport/:id"
							element={canAccessTasks ? <TaskReport /> : taskSalesComponent}
						/>
						<Route
							path="/tasks/task/:id"
							element={canAccessTasks ? <Task /> : taskSalesComponent}
						/>
						<Route
							path="/tasks/task/:id/complete"
							element={canAccessTasks ? <TaskComplete /> : taskSalesComponent}
						/>
						<Route
							path="/tasks/task/:id/failed"
							element={canAccessTasks ? <TaskComplete /> : taskSalesComponent}
						/>

						{/* Task Reporting */}
						<Route
							path="/tasks/reports"
							element={canAccessTasks ? <TaskReporting /> : taskSalesComponent}
						/>
						<Route
							path="/tasks/reporting/:id"
							element={canAccessTasks ? <Report /> : taskSalesComponent}
						/>

						{/* Actions */}
						<Route
							path="/actions"
							element={canAccessActions ? <Actions /> : actionSalesComponent}
						/>
						<Route
							path="/actions/create"
							element={canAccessActions ? <Action /> : actionSalesComponent}
						/>
						<Route
							path="/actions/action/:id"
							element={canAccessActions ? <Action /> : actionSalesComponent}
						/>

						{/* Risk Assessments */}
						<Route
							path="/riskassessments"
							element={canAccessRiskAssessments ? <RiskAssessments /> : riskAssessmentSalesComponent}
						/>
						<Route
							path="/riskassessments/version/:id"
							element={canAccessRiskAssessments ? <RiskAssessment /> : riskAssessmentSalesComponent}
						/>
						<Route
							path="/riskassessments/select"
							element={canAccessRiskAssessments ? <PreCreateRiskAssessment /> : riskAssessmentSalesComponent}
						/>
						<Route
							path="/riskassessments/templateselect"
							element={canAccessRiskAssessments ? <CreateRAFromTemplateSelect /> : riskAssessmentSalesComponent}
						/>
						<Route
							path="/riskassessments/create"
							element={canAccessRiskAssessments ? <RiskAssessmentEditor /> : riskAssessmentSalesComponent}
						/>
						<Route
							path="/riskassessments/edit/:id"
							element={canAccessRiskAssessments ? <RiskAssessmentEditor /> : riskAssessmentSalesComponent}
						/>
						<Route
							path="/riskassessments/clone/:id"
							element={canAccessRiskAssessments ? <RiskAssessmentClone /> : riskAssessmentSalesComponent}
						/>

						{/* Certificates */}
						<Route path="/certificates" element={canAccessCertificates ? <Certificates /> : certificateSalesComponent} />

						{/* Training */}
						<Route path="/training" element={<Training />} />
						<Route path="/training/reports" element={<Training />} />
						<Route path="/training/course/:id" element={<Course />} />
						<Route path="/training/module/:id" element={<Module />} />
						<Route path="/training/module/:id/complete" element={<ModuleComplete />} />

						{/* Documents */}
						<Route path="/documents" element={<Documents />} />

						{/* Incidents */}
						<Route path="/incidents" element={<Incidents />} />
						<Route path="/incidents/incident/:id" element={<Incident />} />
						<Route path="/incidents/create" element={<CreateIncident />} />
						<Route path="/incidents/accident" element={<CreateAccident />} />
						<Route path="/incidents/nearmiss" element={<CreateNearMiss />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
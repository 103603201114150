import { Fragment, useCallback, useEffect, useState } from "react";
import {
	AspectRatio,
	Avatar,
	Badge,
	Box,
	Button,
	Center,
	Divider,
	FormControl,
	Grid,
	GridItem,
	HStack,
	Icon,
	Image,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Skeleton,
	SlideFade,
	Spacer,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { BsAward, BsDashCircle, BsExclamationDiamond, BsFileText, BsPeople, BsXCircle } from "react-icons/bs";
import { SmallFilterCard } from "../common/SmallFilterCard";
import { InsightActionCard } from "../common/InsightActionCard";
import incident from "../../assets/images/incident.svg";
import certificateExpiring from "../../assets/images/certificateExpiring.svg";
import { downloadCertificate, getCertificates, getTeamCertificates } from "../../features/certificates/certificates.actions";
import { Pager } from "../common/Pager";
import { isEmpty, isNull } from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { getMyTeams, getTeamMembers } from "../../features/admin/admin.actions";
import { Role } from "./Certificates";
import { useDebounce } from "../common/useDebounce";
import { UserCombobox } from "../common/UserCombobox";
import { TeamCombobox } from "../common/TeamCombobox";
import { useLocation } from "react-router-dom";
import { localDate } from "../../helpers/DayJsHelper";
import { getClickthroughURL } from "../../features/auth/auth.actions";
import { CheckIsWithinProductTierLimit } from "../../helpers/Utilities";

export const CertificateTable = ({
	role,
	clearAllFilters,
	setClearAllFilters,
	setFilterSelected,
	setIsWithinProductTierLimit
}: any) => {
	const { state }: any = useLocation();
	const user = useSelector((state: RootState) => state.authReducer.user);
	const isMobile = useBreakpointValue({ base: true, xl: false });

	const [isOpen, setIsOpen] = useState(false);
	const [pdfIsOpen, setPdfIsOpen] = useState(false);

	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [loading, setLoading] = useState(false);

	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);

	const [currentCert, setCurrentCert] = useState({
		id: 0,
		isPdf: false,
		uri: "",
		fileName: ""
	});

	const [categories, setCategories] = useState([]);

	const [filter, setFilter] = useState<string>(
		state !== null && state.filter ? state.filter : ""
	);

	const [category, setCategory] = useState("");
	const [search, setSearch] = useState("");

	const [expiredCertCount, setExpiredCert] = useState(0);
	const [expiringCertCount, setExpiringCert] = useState(0);

	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const [teamsManaged, setTeamsManaged] = useState<any>([]);
	const [selectedTeam, setSelectedTeam] = useState<any>();
	const [selectedUser, setSelectedUser] = useState<any>();
	const [teamsWithUsers, setTeamsWithUsers] = useState<{ teamid: number, users: any }[]>([]);
	const [allUsers, setAllUsers] = useState<any>([]);
	const [availableUsers, setAvailableUsers] = useState<any>([]);
	const [error, setError] = useState<string>('');
	const [loadingTeamsAndUsers, setLoadingTeamsAndUsers] = useState<boolean>(false);

	const toast = useToast();

	const resetCertViewer = () => {
		setNumPages(0);
		setPageNumber(1);
	};

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => {
				setPdfIsOpen(false);
				resetCertViewer();
			}}
		>
			<ModalOverlay />

			<ModalContent minH="100vh" my={0} bg="white">
				<ModalHeader
					style={{
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={1} paddingRight={10}>
						<Text wordBreak="break-word" fontSize="sm" maxW="50%">{currentCert.fileName}</Text>

						<Spacer />

						<Button
							bg="white"
							color="brand.500"
							float="left"
							size="sm"
							disabled={pageNumber === 1}
							onClick={() => setPageNumber(pageNumber - 1)}
						>
							Back
						</Button>
						<Text fontSize="md">
							{pageNumber} of {numPages}
						</Text>
						<Button
							bg="white"
							color="brand.500"
							float="right"
							size="sm"
							disabled={pageNumber === numPages}
							onClick={() => setPageNumber(pageNumber + 1)}
						>
							Next
						</Button>

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>
					<Document
						file={currentCert.uri}
						loading={
							<Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							scale={2}
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={pageNumber}
						/>
					</Document>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const renderImage = () => {
		return <Modal
			size="4xl"
			isOpen={isOpen}
			onClose={() => { setIsOpen(false) }}
		>
			<ModalOverlay />

			<ModalContent my={0} bg="white">
				<ModalHeader
					style={{
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={3} paddingRight={10}>
						<Text wordBreak="break-word" fontSize="sm" maxW="50%">
							{currentCert.fileName || "Untitled"}
						</Text>
						<Spacer />

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody m={5}>
					<Image src={currentCert.uri} m="0 auto" p={0} />
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const fetchCertificate = async (id: number, isPdf: boolean) => {
		await downloadCertificate(id, isPdf)
			.then((res: any) => {
				setCurrentCert({
					id: id,
					isPdf: res.cert.ispdf || res.cert.isdoc,
					uri: res.datauri,
					fileName: res.cert.certfilename
				});
			})
			.catch((error: any) => {
				console.log(error);
			})
	};

	const fetchData = useCallback(async ({ filter, category, search, selectedTeam, selectedUser, pageIndex }) => {
		setLoading(true);
		pageIndex = (pageIndex + 1).toString();

		if (Role.USER === role) {
			await getCertificates("", role, filter, category, search, selectedTeam, selectedUser, pageIndex)
				.then(async (res: any) => {
					let formattedCurrentItems: any = [];

					for (let i = 0; i < res.certificates.length; i++) {
						let thumbnail = null;

						if (res.certificates[i].hasthumbnail) {
							thumbnail =
								`${process.env.REACT_APP_OBBI_API_URL}/certificates/thumbnail?id=${res.certificates[i].certid}&datauri=false&thumbnail=true`;
						}

						formattedCurrentItems.push({
							certid: res.certificates[i].certid,
							certdescription: res.certificates[i].certdescription,
							certfilename: res.certificates[i].certfilename,
							certcategory: res.certificates[i].certcategory,
							certuser: res.certificates[i].userforename + ' ' + res.certificates[i].usersurname,
							certexpirydate: res.certificates[i].certexpirydate,
							expiring: res.certificates[i].expiring,
							expired: res.certificates[i].expired,
							isimage: res.certificates[i].isimage,
							ispdf: res.certificates[i].ispdf,
							isdoc: res.certificates[i].isdoc,
							thumbnail
						});
					}

					setExpiredCert(res.expiredCount);
					setExpiringCert(res.expiringSoonCount);

					setCurrentItems(formattedCurrentItems);
					setCategories(res.availableCategories);

					setPageCount(res.pageCount);
					setIsWithinProductTierLimit(CheckIsWithinProductTierLimit(res.orgCertCount, user?.producttier?.ptcertificatelimit));
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
					setFirstTimeLoading(false);
				});
		} else
			await getTeamCertificates(user.orgid, "", filter, category, search, selectedTeam, selectedUser, pageIndex)
				.then(async (res: any) => {
					let formattedCurrentItems: any = [];

					for (let i = 0; i < res.certificates.length; i++) {
						let thumbnail = null;

						if (res.certificates[i].hasthumbnail) {
							thumbnail =
								`${process.env.REACT_APP_OBBI_API_URL}/certificates/thumbnail?id=${res.certificates[i].certid}&datauri=false&thumbnail=true`;
						}

						formattedCurrentItems.push({
							certid: res.certificates[i].certid,
							certdescription: res.certificates[i].certdescription,
							certfilename: res.certificates[i].certfilename,
							certcategory: res.certificates[i].certcategory,
							certuser: res.certificates[i].userforename + ' ' + res.certificates[i].usersurname,
							certexpirydate: res.certificates[i].certexpirydate,
							expiring: res.certificates[i].expiring,
							expired: res.certificates[i].expired,
							isimage: res.certificates[i].isimage,
							ispdf: res.certificates[i].ispdf,
							isdoc: res.certificates[i].isdoc,
							thumbnail
						});
					}

					setExpiredCert(res.expiredCount);
					setExpiringCert(res.expiringSoonCount);

					setCurrentItems(formattedCurrentItems);
					setCategories(res.availableCategories);

					setPageCount(res.pageCount);
					setIsWithinProductTierLimit(CheckIsWithinProductTierLimit(res.orgCertCount, user?.producttier?.ptcertificatelimit));
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
					setFirstTimeLoading(false);
				});
	}, []);

	useEffect(() => {
		const getTeamsAndUsers = async () => {
			try {
				if (user.isTeamManager && role === Role.MANAGER) {
					const teams = await getMyTeams();

					const teamsWithUsers: { teamid: number, users: any }[] = [];
					const allUsers: any[] = [];

					for (const team of teams?.manages) {
						const members = await getTeamMembers(team.teamid);

						allUsers.push(...members?.members);

						teamsWithUsers.push({
							teamid: team.teamid,
							users: members?.members
						});
					}

					setTeamsWithUsers(teamsWithUsers);
					setAllUsers(allUsers);
					setAvailableUsers(allUsers)
					setTeamsManaged(teams?.manages)
					setLoadingTeamsAndUsers(false);
				}
			} catch (error) {
				console.error(error)
				setLoadingTeamsAndUsers(false);
			}
		}

		if (user.isTeamManager && role === Role.MANAGER) {
			setLoadingTeamsAndUsers(true);

			getTeamsAndUsers();
		}
	}, []);

	useEffect(() => {
		fetchData({
			filter,
			category,
			search,
			selectedTeam,
			selectedUser,
			pageIndex
		});
	}, [category, fetchData, filter, pageIndex, search, selectedTeam, selectedUser]);

	useEffect(() => {
		const filterInEffect =
			(filter !== "" && filter !== "all") ||
			(selectedTeam !== "" && selectedTeam !== undefined) ||
			(selectedUser !== "" && selectedUser !== undefined) ||
			(search !== "") ||
			(category !== "" && category !== undefined);

		setFilterSelected(filterInEffect);
	}, [category, filter, search, selectedTeam, selectedUser]);

	useEffect(() => {
		if (clearAllFilters) {
			setFilter("all");
			setCategory("");
			setSearch("");
			setSelectedTeam("");
			setSelectedUser("");
			setPageIndex(0);
			setClearAllFilters(false);
			setFilterSelected(false);
		}
	}, [clearAllFilters]);

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc =
			`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	});

	function teamSelected(value: any) {
		setPageIndex(0);

		if (value === '0') {
			setSelectedTeam('')
			setSelectedUser('')
			setAvailableUsers(allUsers)
			return;
		}

		setSelectedTeam(value)
		setSelectedUser('')
		setAvailableUsers(teamsWithUsers.find((t: any) => t.teamid === value)?.users);
	};

	const debouncedRequest = useDebounce(() => {
		setPageIndex(0);

		fetchData({
			filter,
			category,
			search,
			selectedTeam,
			selectedUser,
			'pageIndex': 0
		})
	}, 300);

	const onChange = (e: any) => {
		setSearch(e.target.value);

		console.log("Searching for '" + e.target.value + "'");

		if (/[^a-zA-Z0-9\s]+/.test(e.target.value)) {

			setError('Search text contains characters that are not allowed.');

			toast({
				title: "Search text contains characters that are not allowed.",
				description: "",
				status: "error",
				duration: 3000,
				isClosable: false
			});

			return;

		} else {
			setError('');
		}

		debouncedRequest();
	};

	const update = (value: string) => {
		setPageIndex(0)
		setSelectedUser(value === '0' ? '' : value)
	};

	const handleClickThrough = async (path: string, id?: number) => {
		let url: string = await getClickthroughURL(path, id);
		window.open(url, "_self");
	};

	return (
		<Box mx={5}>
			{
				!isEmpty(currentCert.uri) && pdfIsOpen && renderPdf()
			}

			{
				!isEmpty(currentCert.uri) && isOpen && renderImage()
			}

			<Box>
				<Grid
					templateColumns={{
						base: "repeat(2, 1fr)",
						md: "repeat(4, 1fr)",
					}}
					gap={5}
					mb={5}
				>
					<GridItem colSpan={{ base: 2, md: 4 }}>
						{
							user?.permissions?.Certificates?.read && <Button
								display={{ base: "flex", md: "none" }}
								bg="green.500"
								_hover={{
									bg: "#248451"
								}}
								w="full"
								size="sm"
								color="white"
								float="right"
								onClick={() => {
									handleClickThrough("ManageCertificates");
								}}
							>
								<Icon as={BsAward} mr={2} /> Manage Certificates
							</Button>
						}
					</GridItem>

					<InsightActionCard
						title="Expired Certificates"
						count={expiredCertCount}
						image={incident}
						color="danger.500"
						active={filter === "expired"}
						onClickAction={() => {
							if (filter === "expired") {
								setFilter("all");
								setPageIndex(0);
							} else {
								setFilter("expired");
								setPageIndex(0);
							}
						}}
						loading={firstTimeLoading}
					/>
					<InsightActionCard
						title="Expiring Certificates"
						count={expiringCertCount}
						image={certificateExpiring}
						color="warning.500"
						active={filter === "expiringsoon"}
						onClickAction={() => {
							if (filter === "expiringsoon") {
								setFilter("all");
								setPageIndex(0);
							} else {
								setFilter("expiringsoon");
								setPageIndex(0);
							}
						}}
						loading={firstTimeLoading}
					/>
				</Grid>

				{
					!isMobile && <Divider w="unset" mx={{ base: 0, md: -10 }} />
				}

				{/* categories */}
				<SlideFade in={!firstTimeLoading} offsetY="20px">
					{
						categories.length > 0 && categories.length < 7 &&
						<Box
							p={1.5}
							mt={5}
							bg="gray.50"
							textAlign="left"
							rounded="lg"
							border="1px solid"
							borderColor="gray.100"
						>
							<Text
								fontWeight={500}
								color="gray.500"
								fontSize="sm"
								ml={1}
								mb={2}
							>
								Certificates Categories
							</Text>

							<SlideFade in={!firstTimeLoading}>
								<HStack>
									{
										categories.map((c: any, i: Number) => {
											return (
												<SmallFilterCard
													key={i}
													title={c.certcategory}
													onClickAction={() => {
														c.certcategory !== category
															? setCategory(c.certcategory)
															: setCategory("");
													}}
													selectedCard={category}
													icon={BsAward}
													iconColor="brand.500"
												/>
											);
										})
									}
								</HStack>
							</SlideFade>
						</Box>
					}
				</SlideFade>

				{/* filter bars */}
				<SlideFade in={!firstTimeLoading} offsetY="20px">
					<Stack
						direction={{ base: "column", lg: "row" }}
						mt={
							categories.length > 0 && categories.length <= 7 ? 2 :
								categories.length > 7 ? 5 : 5
						}
					>
						{
							categories.length > 7 ? (
								<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
									<Select
										_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
										color="gray.600"
										fontSize="sm"
										defaultValue=""
										onChange={(e) => setCategory(e.target.value)}
										placeholder="Search by Category"
									>
										{
											categories.map((c: any, i) => {
												return <option key={i} value={c.certcategory}>{c.certcategory}</option>;
											})
										}
									</Select>
								</FormControl>
							) : (
								<FormControl w={{ base: "full", lg: "50%" }}>
									<Input placeholder="Search Certificates" fontSize="sm" value={search} onChange={onChange} />
								</FormControl>
							)
						}

						{
							categories.length > 7 &&
							<FormControl w={{ base: "full", lg: "50%" }}>
								<Input placeholder="Search Certificates" fontSize="sm" value={search} onChange={onChange} />
							</FormControl>
						}

						{
							(user.isTeamManager && teamsManaged && role === Role.MANAGER) &&
							<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
								<Skeleton isLoaded={!loadingTeamsAndUsers} rounded="lg">
									{
										teamsManaged?.length === 1 ?
											<HStack
												alignItems={'center'}
												border={'1px solid'}
												borderRadius={'lg'}
												borderColor={'gray.200'}
												height={'40px'}
											>
												<Box
													border={'1px solid'}
													borderColor={'gray.400'}
													borderRadius={'50%'}
													boxSize={'25px'}
													ml={1}
													mr={1}
													display={'flex'}
													alignItems={'center'}
													justifyContent={'center'}
													backgroundColor={'gray.400'}
												>
													<Icon as={BsPeople} color="gray.50" />
												</Box>
												<Box
													alignItems={'center'}
													display={'flex'}
												>
													<Text
														color="gray.600"
														fontSize="sm"
													>
														{teamsManaged[0]?.teamname}
													</Text>
												</Box>
											</HStack> :

											<SlideFade in={!loadingTeamsAndUsers} offsetY="20px">
												<TeamCombobox
													teams={teamsManaged || []}
													update={teamSelected}
													selectedTeam={selectedTeam}
												/>
											</SlideFade>
									}
								</Skeleton>
							</FormControl>
						}

						{
							(user.isTeamManager && availableUsers && role === Role.MANAGER) &&
							<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
								<Skeleton isLoaded={!loadingTeamsAndUsers} rounded="lg">

									<SlideFade in={!loadingTeamsAndUsers} offsetY="20px">
										<UserCombobox
											users={availableUsers || []}
											update={update}
											selectedUser={selectedUser}
										/>
									</SlideFade>
								</Skeleton>
							</FormControl>
						}
					</Stack>

					{
						error &&
						<Text
							mt={2}
							ml={2}
							color={'red.500'}
							fontSize="sm"
							textAlign={'left'}
						>
							{error}
						</Text>
					}
				</SlideFade>

				<Box
					mt={5}
					px={5}
					border="1px"
					borderRadius="12px"
					borderColor="gray.200"
					hidden={firstTimeLoading}
				>
					{
						currentItems.length < 1 && !firstTimeLoading ?
							<Fragment>
								<Center m={5}>
									<VStack>
										<Text fontSize="xl" fontWeight={600}>
											No Certificates Found
										</Text>
										<Text fontWeight={400} color="gray.500">
											You have no certificates available
										</Text>
									</VStack>
								</Center>
							</Fragment>
							:
							<Fragment>
								<Grid
									templateColumns={{
										base: "repeat(1, minmax(0, 1fr))",
										sm: "repeat(2, minmax(0, 1fr))",
										lg: "repeat(3, minmax(0, 1fr))",
										xl: "repeat(5, minmax(0, 1fr))",
									}}
									gridAutoRows="1fr"
									gap={5}
									my={5}
								>
									{
										currentItems.map((cert: any, i) => {
											return (
												<Skeleton
													isLoaded={!loading && !firstTimeLoading}
													display="flex"
													rounded="lg"
													key={i}
												>
													<Box
														display="flex"
														flexDirection="column"
														position="relative"
														w="100%"
														p={3}
														background="white"
														rounded="md"
														boxShadow="xs"
														border="1px solid"
														borderColor="gray.200"
														className="hover-pop"
														cursor="pointer"
														onClick={() => {
															fetchCertificate(Number(cert.certid), cert.ispdf || cert.isdoc)
																.then(() => {
																	if (cert.isimage) {
																		setIsOpen(true);
																	} else {
																		setPdfIsOpen(true);
																	}
																});;
														}}
													>
														<Box flex="1" mb={3}>
															{
																!isNull(cert.thumbnail) && !loading ?
																	<AspectRatio maxW="100%" ratio={4 / 3} mb={3}>
																		<Image
																			src={cert.thumbnail}
																			rounded="lg"
																			objectFit='contain'
																			boxSize='150px'
																			border="1px solid"
																			borderColor="gray.100"
																		/>
																	</AspectRatio>
																	:
																	<AspectRatio maxW="100%" ratio={4 / 3} mb={3}>
																		<Icon
																			as={BsFileText}
																			fontSize="5xl"
																			color="gray.600"
																		/>
																	</AspectRatio>
															}

															<Box alignItems="start" width="calc(100%)">
																<Text
																	fontWeight={600}
																	fontSize="sm"
																	color="gray.600"
																	textAlign="left"
																	wordBreak="break-all"
																	textOverflow="ellipsis"
																	overflow="hidden"
																	whiteSpace="nowrap"
																	height="3ch"
																>
																	{cert.certdescription || cert.certfilename || "Untitled"}
																</Text>
																{
																	cert.certuser && <HStack mb={2}>
																		<Avatar
																			name={cert.certuser}
																			size="xs"
																			color="white"
																		/>
																		<Text
																			textAlign="start"
																			fontWeight={500}
																			fontSize="sm"
																			color="gray.600"
																			wordBreak="break-all"
																			textOverflow="ellipsis"
																			overflow="hidden"
																			whiteSpace="nowrap"
																		>
																			{cert.certuser}
																		</Text>
																	</HStack>
																}
																{
																	cert.certcategory && <HStack height="3ch">
																		<Icon as={BsAward} color="gray.500" />

																		<Text
																			fontWeight={500}
																			fontSize="xs"
																			color="gray.500"
																			wordBreak="break-all"
																			textOverflow="ellipsis"
																			overflow="hidden"
																			whiteSpace="nowrap"
																		>
																			{cert.certcategory}
																		</Text>
																	</HStack>
																}
																{
																	cert.certexpirydate && <Text
																		fontWeight={500}
																		fontSize="xs"
																		color="gray.500"
																		textAlign="left"
																		wordBreak="break-all"
																	>
																		Expires {localDate(cert.certexpirydate)}
																	</Text>
																}
															</Box>
														</Box>

														<Box display="flex">
															<HStack>
																{
																	(cert.expired || cert.expiring) && <Badge
																		size="sm"
																		colorScheme={
																			cert.expired ? "red"
																				: cert.expiring ? "orange"
																					: "gray"
																		}
																	>
																		<HStack>
																			<Icon
																				as={cert.expired ? BsXCircle
																					: cert.expiring ? BsExclamationDiamond : BsDashCircle}
																			/>
																			<Text>
																				{cert.expired ? "Expired" : cert.expiring ? "Expiring" : ""}
																			</Text>
																		</HStack>
																	</Badge>
																}
															</HStack>
														</Box>
													</Box>
												</Skeleton>
											);
										})
									}
								</Grid>

								<Box
									my={3}
									textAlign="center"
									hidden={pageCount <= 1}
								>
									<Pager
										pageCount={pageCount}
										pageIndex={pageIndex}
										setPageIndex={setPageIndex}
									/>
								</Box>
							</Fragment>
					}
				</Box>
			</Box>
		</Box>
	);
};
import React, { Fragment, useEffect, useState } from "react";
import CSS from 'csstype';
import ReactMarkdown from 'react-markdown';

import {
	Box,
	Button,
	Center,
	HStack,
	Icon,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	SlideFade,
	Spacer,
	Spinner,
	Text,
	VStack
} from "@chakra-ui/react";

import { BsExclamationCircleFill, BsFilePdfFill } from 'react-icons/bs';
import { isNull } from "lodash";
import Lightbox from "react-image-lightbox";
import { Document, Page, pdfjs } from 'react-pdf';

export interface slideInterface {
	layout: string,
	title: string,
	image: string,
	video: string,
	pdf: string,
	audio: string,
	autostartVoiceover: boolean,
	content: string
};

export const SlidePlayer: React.FC<any> = ({ slide, audioData, loading }) => {
	const hasTitle: boolean = slide.title?.length > 0;
	const hasImage: boolean = slide.image?.length > 0;
	const hasAudio: boolean = slide.audio?.length > 0;
	const hasVideo: boolean = slide.video?.length > 0;

	const videoYoutube: boolean = slide.video?.length > 0 && slide.video?.startsWith("http") && slide.video?.indexOf("youtube") > 0;
	const videoVimeo: boolean = slide.video?.length > 0 && slide.video?.startsWith("http") && slide.video?.indexOf("vimeo") > 0;
	const videoBitMovin: boolean = slide.video?.length > 0 && slide.video?.startsWith("http") && slide.video?.indexOf("bitmovin") > 0;
	const videoEmbed: boolean = slide.video?.length > 0 && !videoYoutube && !videoVimeo && !videoBitMovin;

	const hasPDF: boolean = slide.pdf?.length > 0;

	const titleStyles: CSS.Properties = {
		backgroundColor: "#37a169",
		fontWeight: "bold",
		lineHeight: "80px",
		color: "white",
		borderRadius: "8px",
		marginBottom: "10px"
	};

	const pictureStyles: CSS.Properties = {
		width: "100%",
		objectFit: "contain"
	};

	const contentStyles: CSS.Properties = {
		textAlign: "left",
		color: "#404040",
		fontSize: "14pt",
	};

	const col6: CSS.Properties = {
		display: "flex",
		flexDirection: "column",
		flexBasis: "100%",
		flex: "1"
	};

	const [isOpen, setIsOpen] = useState(false);
	const [pdfIsLoading, setPdfIsLoading] = useState(false);
	const [pdfIsOpen, setPdfIsOpen] = useState(false);

	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const changePdfPageIndex = (index: number) => {
		setPdfIsLoading(true);
		setPageNumber(index);

		setTimeout(() => { setPdfIsLoading(false); }, 150);
	};

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => { setPdfIsOpen(false) }}
		>
			<ModalOverlay />

			<ModalContent minH="100vh" my={0} bg="white">
				<ModalHeader
					style={{
						height: "50px",
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={1} paddingRight={10}>
						<Text wordBreak="break-word" fontSize="lg" maxW="50%">{slide.title}</Text>
						<Spacer />

						<Button
							bg="white"
							color="brand.500"
							float="left"
							size="sm"
							disabled={pageNumber === 1}
							onClick={() => changePdfPageIndex(pageNumber - 1)}
						>
							Back
						</Button>
						<Text fontSize="md">
							{pageNumber} of {numPages}
						</Text>
						<Button
							bg="white"
							color="brand.500"
							float="right"
							size="sm"
							disabled={pageNumber === numPages}
							onClick={() => changePdfPageIndex(pageNumber + 1)}
						>
							Next
						</Button>

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>
					{
						!pdfIsLoading && <Document
							file={slide.pdf}
							onLoadSuccess={onDocumentLoadSuccess}
							loading={
								<Center my={48} scrollMarginY={"96"}>
									<Spinner
										thickness='4px'
										speed='0.65s'
										color="brand.500"
										size="xl"
									/>
								</Center>
							}
						>
							<Page
								scale={2}
								renderAnnotationLayer={false}
								renderTextLayer={false}
								pageNumber={pageNumber}
							/>
						</Document>
					}
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
		setPdfIsLoading(false);
	}

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc =
			`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	});

	switch (slide.layout) {
		case "media-content":
			return (
				<>
					{renderPdf()}
					<SlideFade in={!loading} offsetY="20px">
						{
							isOpen && <Lightbox mainSrc={slide.image} onCloseRequest={() => setIsOpen(false)} />
						}

						{
							hasTitle && <Text
								style={titleStyles}
								fontSize={{ base: "lg", md: "lg" }}
								textAlign="center"
								mt={3}
							>
								{slide.title}
							</Text>
						}

						{
							hasAudio && !isNull(audioData.error) && <Box
								borderLeft="3px solid"
								borderColor="red.500"
								bg="danger.100"
								mt={3}
								px={{ base: "4", md: "3" }}
								py={{ base: "4", md: "2.5" }}
								borderRadius="md"
							>
								<HStack>
									<Icon as={BsExclamationCircleFill} color="danger.500" />
									<Text textAlign="left" color="gray.600">
										<strong>Cannot play audio:</strong> {audioData.error}
									</Text>
								</HStack>
							</Box>
						}

						{
							hasAudio && <audio
								src={audioData.file}
								autoPlay={slide.autostartVoiceover}
								controls
								style={{ width: "100%", marginTop: "15px" }}
							/>
						}

						{
							hasVideo && (videoYoutube || videoVimeo || videoBitMovin) && renderIframe(slide.video)
						}

						{
							hasVideo && videoEmbed && <Box mt={5}>
								<video
									src={slide.video}
									autoPlay={!slide.autostartVoiceover}
									controls />
							</Box>
						}

						{
							hasImage && <Image
								className="hover-pop"
								src={slide.image}
								alt="Question image"
								height={{ base: "auto" }}
								mt={5}
								cursor="pointer"
								onClick={() => setIsOpen(true)}
							/>
						}

						{
							hasPDF && <Box py={5}>
								<Button
									size="md"
									bg="brand.500"
									color="white"
									float="left"
									_hover={{ bgColor: "" }}
									leftIcon={<Icon as={BsFilePdfFill} />}
									onClick={() => {
										setPdfIsOpen(true);
									}}
								>
									Open attached PDF
								</Button>
							</Box>
						}

						<Box style={contentStyles} mt={8}>
							<Text color="gray.600">
								<ReactMarkdown
									children={slide.content}
									className="slide-player"
								/>
							</Text>
						</Box>
					</SlideFade>
				</>
			);

		case "content-media":
			return (
				<>
					{renderPdf()}
					<SlideFade in={!loading} offsetY="20px">
						{
							isOpen &&
							<Lightbox mainSrc={slide.image} onCloseRequest={() => setIsOpen(false)} />

						}

						<Text
							style={titleStyles}
							fontSize={{ base: "lg", md: "lg" }}
							textAlign="center"
							mt={3}
						>
							{slide.title}
						</Text>

						{
							hasAudio && !isNull(audioData.error) &&
							<Box
								borderLeft="3px solid"
								borderColor="red.500"
								bg="danger.100"
								px={{ base: "4", md: "3" }}
								py={{ base: "4", md: "2.5" }}
								borderRadius="md"
							>
								<HStack>
									<Icon as={BsExclamationCircleFill} color="danger.500" />
									<Text textAlign="left" fontWeight={500} color="gray.600">
										<strong>Cannot play audio:</strong> {audioData.error}
									</Text>
								</HStack>
							</Box>
						}

						{
							hasAudio && <audio src={audioData.file} autoPlay={slide.autostartVoiceover} controls style={{ width: "100%", marginTop: "15px" }} />
						}

						<Box mt={5} style={contentStyles}>
							<Text color="gray.600">
								<ReactMarkdown children={slide.content} className="slide-player" />
							</Text>
						</Box>

						{
							hasPDF && <Button
								mt={5}
								size="md"
								bg="brand.500"
								color="white"
								float="left"
								_hover={{ bgColor: "" }}
								leftIcon={<Icon as={BsFilePdfFill} />}
								onClick={() => {
									setPdfIsOpen(true);
								}}
							>
								Open attached PDF
							</Button>
						}

						{hasVideo && (videoYoutube || videoVimeo || videoBitMovin) && renderIframe(slide.video)}
						{
							hasVideo && videoEmbed &&
							<Box mt={5}><video src={slide.video} autoPlay={!slide.autostartVoiceover} controls /></Box>
						}

						{
							hasImage && <Image
								mt={5}
								className="hover-pop"
								src={slide.image}
								alt={slide.image}
								height={{ base: "auto" }}
								objectFit="none"
								cursor="pointer"
								onClick={() => setIsOpen(true)}
							/>
						}
					</SlideFade>
				</>
			);

		case "two-column-right":
			return (
				<>
					{renderPdf()}

					{
						!loading && <SlideFade in={!loading}>
							{
								isOpen ? (
									<Lightbox mainSrc={slide.image} onCloseRequest={() => setIsOpen(false)} />
								) : (
									<Fragment />
								)
							}

							{
								hasTitle && <Text
									style={titleStyles}
									fontSize={{ base: "lg", md: "lg" }}
									textAlign="center"
									mt={3}
								>
									{slide.title}
								</Text>
							}
							<SimpleGrid gap={8} columns={2}>
								<Box>
									{
										hasAudio && !isNull(audioData.error) &&
										<Box
											borderLeft="3px solid"
											borderColor="red.500"
											bg="danger.100"
											mt={3}
											px={{ base: "4", md: "3" }}
											py={{ base: "4", md: "2.5" }}
											borderRadius="md"
										>
											<HStack>
												<Icon as={BsExclamationCircleFill} color="danger.500" />
												<Text textAlign="left" fontWeight={500} color="gray.600">
													<strong>Cannot play audio:</strong> {audioData.error}
												</Text>
											</HStack>
										</Box>
									}
									{
										hasAudio && <audio src={audioData.file} autoPlay={slide.autostartVoiceover} controls style={{ width: "100%", marginTop: "15px" }} />
									}

									<Box style={contentStyles} mt={5}>
										<Text color="gray.600">
											<ReactMarkdown children={slide.content} className="slide-player" />
										</Text>
									</Box>

									{
										hasPDF && (
											<Button
												mt={5}
												size="md"
												bg="brand.500"
												color="white"
												float="left"
												_hover={{ bgColor: "" }}
												leftIcon={<Icon as={BsFilePdfFill} />}
												onClick={() => {
													setPdfIsOpen(true);
												}}
											>
												Open attached PDF
											</Button>
										)
									}
								</Box>
								<Box>
									{hasVideo && (videoYoutube || videoVimeo || videoBitMovin) && renderIframe(slide.video)}
									{
										hasVideo && videoEmbed &&
										<Box
											w="full"
											bg="#0f0f0f"
											mt={5}
											rounded="lg"
											alignContent="center"
										>
											<video src={slide.video} controls autoPlay={!slide.autostartVoiceover} style={{ margin: "0 auto 15px" }} />
										</Box>
									}

									{hasImage && (
										<Image className="hover-pop" src={slide.image} alt={slide.image} style={pictureStyles} cursor="pointer" mt={5} onClick={() => setIsOpen(true)} />
									)}
								</Box>
							</SimpleGrid>
						</SlideFade>
					}
				</>
			);

		case "picture-left":
			return (
				<>
					{renderPdf()}

					<SlideFade in={!loading} offsetY="20px">
						{
							isOpen ? (
								<Lightbox mainSrc={slide.image} onCloseRequest={() => setIsOpen(false)} />
							) : (
								<Fragment />
							)
						}

						<SimpleGrid gap={8} columns={2}>
							<Box>
								{
									hasTitle && <Text
										style={titleStyles}
										fontSize={{ base: "lg", md: "lg" }}
										textAlign="center"
										mt={3}
									>
										{slide.title}
									</Text>
								}
								{hasVideo && (videoYoutube || videoVimeo || videoBitMovin) && renderIframe(slide.video)}
								{
									hasVideo && videoEmbed &&
									<Box
										w="full"
										bg="#0f0f0f"
										mt={5}
										rounded="lg"
										alignContent="center"
									>
										<video src={slide.video} controls autoPlay={!slide.autostartVoiceover} style={{ margin: "0 auto 15px" }} />
									</Box>
								}

								{hasImage && (
									<Image className="hover-pop" src={slide.image} alt={slide.image} style={pictureStyles} cursor="pointer" mt={5} onClick={() => setIsOpen(true)} />
								)}
							</Box>

							<Box>
								{
									hasAudio && !isNull(audioData.error) &&
									<Box
										borderLeft="3px solid"
										borderColor="red.500"
										bg="danger.100"
										mt={3}
										px={{ base: "4", md: "3" }}
										py={{ base: "4", md: "2.5" }}
										borderRadius="md"
									>
										<HStack>
											<Icon as={BsExclamationCircleFill} color="danger.500" />
											<Text textAlign="left" fontWeight={500} color="gray.600">
												<strong>Cannot play audio:</strong> {audioData.error}
											</Text>
										</HStack>
									</Box>
								}
								{
									hasAudio && <audio src={audioData.file} autoPlay={slide.autostartVoiceover} controls style={{ width: "100%", marginTop: "10px" }} />
								}

								<Box style={contentStyles} mt={hasAudio ? 5 : 1}>
									<Text color="gray.600">
										<ReactMarkdown children={slide.content} className="slide-player" />
									</Text>
								</Box>

								{
									hasPDF && (
										<Button
											mt={5}
											size="md"
											bg="brand.500"
											color="white"
											float="left"
											_hover={{ bgColor: "" }}
											leftIcon={<Icon as={BsFilePdfFill} />}
											onClick={() => {
												setPdfIsOpen(true);
											}}
										>
											Open attached PDF
										</Button>
									)
								}
							</Box>
						</SimpleGrid>
					</SlideFade>
				</>
			);

		case "picture-right":
			return (
				<>
					{renderPdf()}
					<SlideFade in={!loading} offsetY="20px">
						{
							isOpen ? (
								<Lightbox mainSrc={slide.image} onCloseRequest={() => setIsOpen(false)} />
							) : (
								<Fragment />
							)
						}

						<SimpleGrid gap={8} columns={2}>
							<Box>
								{
									hasAudio && !isNull(audioData.error) &&
									<Box
										borderLeft="3px solid"
										borderColor="red.500"
										bg="danger.100"
										mt={3}
										px={{ base: "4", md: "3" }}
										py={{ base: "4", md: "2.5" }}
										borderRadius="md"
									>
										<HStack>
											<Icon as={BsExclamationCircleFill} color="danger.500" />
											<Text textAlign="left" fontWeight={500} color="gray.600">
												<strong>Cannot play audio:</strong> {audioData.error}
											</Text>
										</HStack>
									</Box>
								}
								{
									hasAudio && <audio src={audioData.file} autoPlay={slide.autostartVoiceover} controls style={{ width: "100%", marginTop: "10px" }} />
								}

								<Box style={contentStyles} mt={hasAudio ? 5 : 1}>
									<Text color="gray.600">
										<ReactMarkdown children={slide.content} className="slide-player" />
									</Text>
								</Box>

								{
									hasPDF && (
										<Button
											mt={5}
											size="md"
											bg="brand.500"
											color="white"
											float="left"
											_hover={{ bgColor: "" }}
											leftIcon={<Icon as={BsFilePdfFill} />}
											onClick={() => {
												setPdfIsOpen(true);
											}}
										>
											Open attached PDF
										</Button>
									)
								}
							</Box>

							<Box>
								{
									hasTitle && <Text
										style={titleStyles}
										fontSize={{ base: "lg", md: "lg" }}
										textAlign="center"
										mt={3}
									>
										{slide.title}
									</Text>
								}

								{hasVideo && (videoYoutube || videoVimeo || videoBitMovin) && renderIframe(slide.video)}
								{
									hasVideo && videoEmbed &&
									<Box
										w="full"
										bg="#0f0f0f"
										mt={5}
										rounded="lg"
										alignContent="center"
									>
										<video src={slide.video} controls autoPlay={!slide.autostartVoiceover} style={{ margin: "0 auto 15px" }} />
									</Box>
								}

								{hasImage && (
									<Image className="hover-pop" src={slide.image} alt={slide.image} style={pictureStyles} cursor="pointer" mt={5} onClick={() => setIsOpen(true)} />
								)}
							</Box>
						</SimpleGrid>
					</SlideFade>
				</>
			);

		case "two-column-left":
			return (
				<>
					{renderPdf()}
					<SlideFade in={!loading} offsetY="20px">
						{
							isOpen ? (
								<Lightbox mainSrc={slide.image} onCloseRequest={() => setIsOpen(false)} />
							) : (
								<Fragment />
							)
						}

						{
							hasTitle && <Text
								style={titleStyles}
								fontSize={{ base: "lg", md: "lg" }}
								textAlign="center"
								mt={3}
							>
								{slide.title}
							</Text>
						}

						<SimpleGrid gap={8} columns={2}>
							<Box>
								{hasVideo && (videoYoutube || videoVimeo || videoBitMovin) && renderIframe(slide.video)}
								{
									hasVideo && videoEmbed &&
									<Box
										w="full"
										bg="#0f0f0f"
										mt={5}
										rounded="lg"
										alignContent="center"
									>
										<video src={slide.video} autoPlay={!slide.autostartVoiceover} controls style={{ margin: "0 auto 15px" }} />
									</Box>
								}

								{hasImage && <Image className="hover-pop" src={slide.image} alt={slide.image} style={pictureStyles} cursor="pointer" onClick={() => setIsOpen(true)} />}
							</Box>
							<Box style={col6}>
								{
									hasAudio && !isNull(audioData.error) &&
									<Box
										borderLeft="3px solid"
										borderColor="red.500"
										bg="danger.100"
										mt={3}
										px={{ base: "4", md: "3" }}
										py={{ base: "4", md: "2.5" }}
										borderRadius="md"
									>
										<HStack>
											<Icon as={BsExclamationCircleFill} color="danger.500" />
											<Text textAlign="left" fontWeight={500} color="gray.600">
												<strong>Cannot play audio:</strong> {audioData.error}
											</Text>
										</HStack>
									</Box>
								}
								{
									hasAudio ? <audio src={audioData.file} autoPlay={slide.autostartVoiceover} controls style={{ width: "100%", marginTop: "15px" }} />
										: <Fragment />
								}

								<Box mt={5} style={contentStyles}>
									<Text color="gray.600">
										<ReactMarkdown children={slide.content} className="slide-player" />
									</Text>
								</Box>

								{
									hasPDF && (
										<Button
											mt={5}
											size="md"
											bg="brand.500"
											color="white"
											float="left"
											_hover={{ bgColor: "" }}
											leftIcon={<Icon as={BsFilePdfFill} />}
											onClick={() => {
												setPdfIsOpen(true);
											}}
										>
											Open attached PDF
										</Button>
									)
								}
							</Box>
						</SimpleGrid>
					</SlideFade>
				</>
			);

		default:
			// blank slide
			return (
				<>
					<SlideFade in={!loading} offsetY="20px">
						{renderPdf()}

						{
							isOpen && <Lightbox mainSrc={slide.image} onCloseRequest={() => setIsOpen(false)} />
						}

						{
							hasAudio && !isNull(audioData.error) && <Box
								borderLeft="3px solid"
								borderColor="red.500"
								bg="danger.100"
								mt={3}
								px={{ base: "4", md: "3" }}
								py={{ base: "4", md: "2.5" }}
								borderRadius="md"
							>
								<HStack>
									<Icon as={BsExclamationCircleFill} color="danger.500" />
									<Text textAlign="left" fontWeight={500} color="gray.600">
										<strong>Cannot play audio:</strong> {audioData.error}
									</Text>
								</HStack>
							</Box>
						}

						{
							hasAudio && <audio src={audioData.file} autoPlay={slide.autostartVoiceover} controls style={{ width: "100%", marginTop: "15px" }} />
						}

						{
							hasVideo && (videoYoutube || videoVimeo || videoBitMovin) && renderIframe(slide.video)
						}

						{
							hasVideo && videoEmbed && <Box
								w="full"
								bg="#0f0f0f"
								mt={5}
								rounded="lg"
								alignContent="center"
							>
								<video
									src={slide.video}
									autoPlay={!slide.autostartVoiceover}
									controls
									style={{ margin: "0 auto 15px" }}
								/>
							</Box>
						}

						<VStack alignItems="start" gap={5}>
							{
								hasImage && <Image
									className="hover-pop"
									src={slide.image}
									alt={slide.image}
									height={{ base: "auto" }}
									mt={5}
									cursor="pointer"
									onClick={() => setIsOpen(true)}
								/>
							}

							{
								hasPDF && <Button
									mt={5}
									size="md"
									bg="brand.500"
									color="white"
									float="left"
									_hover={{ bgColor: "" }}
									onClick={() => {
										setPdfIsOpen(true);
									}}
								>
									Open attached PDF
								</Button>
							}

							<Box style={contentStyles} mt={5}>
								<Text color="gray.600">
									<ReactMarkdown children={slide.content} className="slide-player" />
								</Text>
							</Box>
						</VStack>
					</SlideFade>
				</>
			);
	}
}

const renderIframe = (url: string) => {
	return (
		<Box
			w="full"
			bg="#0f0f0f"
			mt={5}
			rounded="lg"
			alignContent="center"
		>
			<Box
				m="0 auto"
				w="75%"
				as='iframe'
				src={`${url}`}
				objectFit="cover"
				allow="fullscreen"
				sx={{
					aspectRatio: '16/9'
				}}
			/>
		</Box>
	);
}
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Box,
	Divider,
	HStack,
	Icon,
	Text,
	Button,
	FormControl,
	Select,
	useBreakpointValue,
	SlideFade,
	Spacer,
	useToast,
	useDisclosure,
	Grid,
	VStack,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Stack,
} from "@chakra-ui/react";
import {
	BsArrowLeftCircle,
	BsArrowRightCircle,
	BsEye,
	BsJournalBookmarkFill,
	BsTrash,
} from "react-icons/bs";

import { deleteTaskAttempt, getTask, getTaskAttempt, passTaskAttempt, startTaskAttempt } from "../../features/tasks/task.actions";
import { TaskSummaryCard } from "./TaskSummaryCard";
import { useStep } from "../common/progressSteps/useStep";
import { Page } from "./Page";
import { ObbiAlertDialog } from "../common/AlertDialog";
import ReactMarkdown from "react-markdown";
import { isEmpty } from "lodash";
import rehypeRaw from "rehype-raw";
import { FaClipboardCheck } from "react-icons/fa";

export const Task = () => {
	const navigateTo = useNavigate();
	const toast = useToast();
	const { id } = useParams();
	const { state }: any = useLocation();
	const isMobile = useBreakpointValue({ base: true, md: false });

	const [loading, setLoading] = useState<boolean>(true);

	const [progressState, setProgressState] = useState({
		attempteditemcount: 0,
		itemcount: 0,
		percentagecomplete: 0,
	});

	const [attemptData, setAttemptData] = useState<any>({});
	const [trainingRequired, setTrainingRequired] = useState<any>({});
	const [pages, setPages] = useState([]);
	const [currentPage, setCurrentPage] = useState({
		tpname: "",
		tpdescriptionmd: "",
		items: [],
	});

	const [currentStep, { setStep }] = useStep({
		maxStep: pages.length,
		initialStep: state?.pageIndex || 0,
	});

	const [pinCodeRequired, setPinCodeRequired] = useState<boolean>(false);
	const [showFailureButton, setShowFailureButton] = useState<boolean>(false);

	const renderTaskSidebar = () => {
		return <VStack gap={5}>
			<Box
				w="full"
				h={{ base: "full", md: "unset" }}
				p={{ base: 3, md: 5 }}
				pt={{ base: 5, md: 2 }}
				bg="white"
				rounded={{ base: "none", md: "lg" }}
				boxShadow={{ base: "none", md: "lg" }}
			>
				<HStack>
					<Button
						display={{ base: "unset", md: "none" }}
						mt={2}
						mb={4}
						size="sm"
						color="gray.600"
						variant="link"
						fontWeight={600}
						onClick={() => {
							navigateTo(`/tasks/tasksummary/${state?.teamTaskId}`,
								{
									state: {
										navData: state?.navData,
										from: "tasks"
									}
								});
						}}
					>
						<HStack>
							<Icon as={BsArrowLeftCircle} />
							<Text>Back to Task Summary</Text>
						</HStack>
					</Button>

					<Spacer />

					{
						isMobile && <Button
							size="sm"
							variant="outline"
							colorScheme="danger"
							color="danger.500"
							bg="transparent"
							fontSize={isMobile ? "10pt" : "unset"}
							onClick={() => onOpen()}
						>
							<HStack>
								<Icon as={BsTrash} />
								<Text>Discard</Text>
							</HStack>
						</Button>
					}
				</HStack>

				<SlideFade in={!loading} offsetY="20px">
					<Stack
						pt={3}
						pb={5}
						direction={{ base: "column", md: "row" }}
					>
						<HStack gap={2}>
							<Icon
								as={state?.navData?.isGuided ? BsEye : FaClipboardCheck}
								verticalAlign="middle"
								fontSize="4xl"
								color="green.500"
							/>

							<VStack alignItems="start">
								<Text
									fontWeight={700}
									fontSize={{ base: "md", xl: "xl" }}
									textAlign="left"
									color="gray.700"
								>
									{attemptData.taskname}
								</Text>

								{
									state?.navData?.isMandatory && <Text
										fontWeight={500}
										textAlign="left"
										color="gray.500"
									>
										Mandatory Task
									</Text>
								}

								{
									state?.navData?.isGuided && <Text
										fontWeight={500}
										textAlign="left"
										color="gray.500"
									>
										Task Observation with {attemptData.targetuserforename} {attemptData.targetusersurname}
									</Text>
								}
							</VStack>
						</HStack>
					</Stack>
				</SlideFade>

				<Divider w="unset" mx={-5} />

				<Box mt={3}>
					<SlideFade in={!loading} offsetY="20px">
						<TaskSummaryCard
							summary={true}
							summaryData={attemptData}
							numberOfSteps={pages.length}
							progress={progressState}
							cardStatus={trainingRequired}
						/>
					</SlideFade>
				</Box>

				{
					!isEmpty(attemptData.tasknotes) && <Accordion
						defaultIndex={[0]}
						allowMultiple
						mt={5}
						w="100%"
					>
						<AccordionItem
							w="100%"
							border="1px solid #e2e8f0"
							rounded="lg"
						>
							<AccordionButton
								w="100%"
								py={2}
								px={2.5}
								_hover={{ bg: "" }}
							>
								<HStack>
									<Icon as={BsJournalBookmarkFill} color="gray.500" />
									<Text
										fontWeight={500}
										fontSize="sm"
										color="gray.600"
										display="inline"
									>
										{
											!isEmpty(attemptData.taskname) ? `${attemptData.taskname} Notes` : "Task Notes"
										}
									</Text>
								</HStack>

								<Spacer />

								<AccordionIcon color="gray.600" />
							</AccordionButton>

							<AccordionPanel
								w="full"
								h="auto"
								py={2}
								px={2.5}
							>
								<Text
									id="TaskNotesMD"
									textAlign="left"
									color="gray.600"
									fontSize="sm"
									whiteSpace="pre-wrap"
								>
									<ReactMarkdown
										children={attemptData.tasknotes}
										rehypePlugins={[rehypeRaw] as any}
									/>
								</Text>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				}

				{
					pages.length > 1 && <>
						<Divider w="unset" my={5} mx={-5} />

						<FormControl colorScheme="brand" mb={3}>
							<Select
								value={currentStep}
								onChange={(e) => setCurrentData(Number(e.target.value))}
								_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
								color="gray.600"
								size="sm"
								rounded="lg"
							>
								{
									pages.map((step: any, i: number) => (
										<option value={i}>
											Step {i + 1} - {step.tpname}
										</option>
									))
								}
							</Select>
						</FormControl>
					</>
				}

				{
					!isEmpty(currentPage.tpdescriptionmd) && <>
						{
							pages.length < 2 && <Divider w="unset" my={5} mx={-5} />
						}

						<Accordion
							defaultIndex={[0]}
							allowMultiple
							w="100%"
						>
							<AccordionItem
								w="100%"
								border="1px solid #e2e8f0"
								rounded="lg"
							>
								<AccordionButton
									w="100%"
									py={2}
									px={2.5}
									_hover={{ bg: "" }}
								>
									<HStack>
										<Icon as={BsJournalBookmarkFill} color="gray.500" />
										<Text
											fontWeight={500}
											fontSize="sm"
											color="gray.600"
											display="inline"
										>
											{
												!isEmpty(currentPage.tpname) ? `${currentPage.tpname} Notes` : "Page Notes"
											}
										</Text>
									</HStack>

									<Spacer />

									<AccordionIcon color="gray.600" />
								</AccordionButton>

								<AccordionPanel
									w="full"
									h="auto"
									py={2}
									px={2.5}
								>
									<Text
										id="PageNotesMD"
										textAlign="left"
										color="gray.600"
										fontSize="sm"
										whiteSpace="pre-wrap"
									>
										<ReactMarkdown
											children={currentPage.tpdescriptionmd}
											rehypePlugins={[rehypeRaw] as any}
										/>
									</Text>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</>
				}
			</Box>
		</VStack>
	};

	const renderNavigation = () => {
		return <Box
			hidden={loading}
			rounded="lg"
			boxShadow={!isMobile ? "lg" : "none"}
			bg={!isMobile ? "white" : ""}
			p={3}
		>
			<Stack direction={{ base: "column-reverse", md: "row" }}>
				<HStack>
					<Button
						disabled={currentStep === 0}
						size="sm"
						onClick={() => {
							setLoading(true);
							setCurrentData(currentStep - 1);

							window.scrollTo({ top: 0 });
						}}
					>
						<HStack>
							<Icon as={BsArrowLeftCircle} />
							<Text fontSize={{ base: "11pt", md: "unset" }}>
								Back
							</Text>
						</HStack>
					</Button>

					<Button
						_hover={{ bg: "#248451" }}
						size="sm"
						bg="brand.500"
						color="white"
						onClick={() => {
							setLoading(true);

							currentStep >= pages.length - 1
								? handleSubmitReport(attemptData.ttaid || attemptData.gtaid, pinCodeRequired)
								: setCurrentData(currentStep + 1);

							window.scrollTo({ top: 0 });
						}}
					>
						<HStack>
							<Text>
								{currentStep >= pages.length - 1 ? "Submit" : "Next"}
							</Text>
							<Icon as={BsArrowRightCircle} />
						</HStack>
					</Button>
				</HStack>

				<Spacer />

				<HStack>
					{
						(pages.length !== 1 && currentStep !== pages.length - 1) && <Box>
							<Button
								borderColor="brand.500"
								variant="outline"
								size="sm"
								color="brand.500"
								_hover={{ bg: "" }}
								onClick={() => {
									setLoading(true);
									handleSubmitReport(attemptData.ttaid || attemptData.gtaid, pinCodeRequired);
								}}
							>
								<HStack>
									<Text>
										Submit
									</Text>
									<Icon as={BsArrowRightCircle} />
								</HStack>
							</Button>

							<Spacer />
						</Box>
					}

					{
						!isMobile && <Button
							size="sm"
							variant="outline"
							colorScheme="danger"
							color="danger.500"
							bg="transparent"
							_hover={{ bg: "" }}
							onClick={() => onOpen()}
						>
							<HStack>
								<Icon as={BsTrash} />
								<Text>Discard</Text>
							</HStack>
						</Button>
					}
				</HStack>
			</Stack>
		</Box>
	};

	const setCurrentData = (step: number) => {
		setLoading(true);

		setStep(step);
		setCurrentPage(pages[step]);

		setLoading(false);
	};

	const handleSubmitReport = (attemptId: string, pinCodeRequired?: any) => {
		getTaskAttempt(Number(attemptId))
			.then((res) => {
				if (res.state?.state === 1 && !pinCodeRequired) {
					passTaskAttempt(Number(id))
						.then((res) => {
							if (res?.success) {
								toast({
									title: "Task Report Submitted",
									description: "",
									status: "success",
									duration: 5000,
									isClosable: true
								});

								navigateTo("/tasks", {
									state: {
										navData: state?.navData,
										from: "task"
									},
								});
							} else {
								toast({
									title: res?.message ?? "We couldn't submit this Task Report",
									description: "",
									status: "error",
									duration: 5000,
									isClosable: true
								});
							}
						})
						.catch((error) => {
							console.log(error);

							toast({
								title: "We couldn't submit this Task Report.",
								description: "Please try again. If this issue persists please contact your system administrator.",
								status: "error",
								duration: 5000,
								isClosable: true
							});
						});
				} else {
					navigateTo(`/tasks/task/${id}/complete`,
						{
							state: {
								failedItems: res.state,
								pageIndex: currentStep,
								cardStatus: state?.cardStatus,
								id: attemptId,
								teamId: state?.teamId,
								taskId: state?.taskId,
								teamTaskId: state?.teamTaskId,
								navData: state?.navData,
								taskname: attemptData.taskname,
								targetUser: attemptData.targetuserforename + ' ' + attemptData.targetusersurname,
								pinCodeRequired: pinCodeRequired,
								showFailButton: showFailureButton
							}
						});
				}
			})
			.catch((error) => {
				console.log(error);

				toast({
					title: "We couldn't fetch this Task attempt.",
					description: "Please try again. If this issue persists please contact your system administrator.",
					status: "error",
					duration: 5000,
					isClosable: true
				});

				navigateTo(`/tasks/tasksummary/${state?.teamTaskId}`,
					{
						state: {
							navData: state?.navData,
							from: "tasks"
						}
					}
				);
			})
	};

	const { isOpen, onOpen, onClose } = useDisclosure();
	const deleteAttempt = (attemptId: string): void => {
		deleteTaskAttempt(Number(attemptId))
			.then(() => {
				toast({
					title: "Task attempt deleted",
					description: "",
					status: "success",
					duration: 5000,
					isClosable: true
				})
			})
			.catch(() => {
				toast({
					title: "Could not delete task attempt",
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			})
			.finally(() => navigateTo(`/tasks/tasksummary/${state?.teamTaskId}`, {
				state: {
					teamId: state?.teamId,
					taskId: state?.taskId,
					teamTaskId: state?.teamTaskId,
					navData: state?.navData,
					from: "tasks"
				}
			}));
	};

	const startAttempt = (taskId: string) => {
		startTaskAttempt(
			Number(taskId),
			1,
			state?.navData?.selectedUser
		)
			.then((res) => {
				if (!res.success && res.message) {
					toast({
						title: res.message,
						description: "",
						status: "error",
						duration: 6000,
						isClosable: true
					});

					return
				}

				if (res.attempt?.ttaid) {
					navigateTo(`/tasks/task/${res.attempt.ttaid}`, {
						state: {
							id: res.attempt.ttaid,
							teamTaskId: id,
							navData: state?.navData
						}
					});
				}
			})
			.catch((error) => {
				console.error(error);
				toast({
					title: error.message,
					description: "Please try again. If this issue persists please contact your system administrator.",
					status: "error",
					duration: 6000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (id === "observation") {
			startAttempt(state?.navData?.taskId);
		} else {
			getTaskAttempt(Number(id))
				.then((res) => {
					setAttemptData(res.attempt);
					setTrainingRequired(res.trainingRequired);
					setProgressState(res.state);
					setPages(res.pages);
					setCurrentPage(res.pages[state?.pageIndex || 0]);

					getTask(Number(res.attempt.ttid))
						.then((res) => {
							if (res?.task?.ttguidedtaskrequirespin === '1' && state?.navData?.isGuided) {
								setPinCodeRequired(true);
							}

							if (res?.task?.taskfailurecondition === "3") {
								setShowFailureButton(true);
							}
						})
						.catch((error) => {
							console.log(`Cannot get Task with Team Task ID: ${res?.attempt?.ttid}`);

							throw error;
						})
				})
				.catch((error) => {
					console.log(error);

					toast({
						title: "Could not fetch this Task Attempt",
						description: "Please try again. If this issue persists please contact your system administrator.",
						status: "error",
						duration: 6000,
						isClosable: true,
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [id, state]);

	return (
		<Box m={{ base: 0, md: 5 }}>
			<Button
				display={{ base: "none", md: "unset" }}
				w="fit-content"
				border="none"
				boxShadow="sm"
				mb={2}
				size="sm"
				color="gray.600"
				variant="outline"
				fontWeight={600}
				onClick={() => {
					state?.from === "notification" ? navigateTo(-1) :
						navigateTo(`/tasks/tasksummary/${state?.teamTaskId || state?.navData?.taskId}`,
							{
								state: {
									navData: state?.navData,
									from: "tasks",
								}
							});
				}}
			>
				<HStack>
					<Icon as={BsArrowLeftCircle} />
					<Text>
						{
							state?.from === "notification" ? "Back to Notification" : "Back to Task Summary"
						}
					</Text>
				</HStack>
			</Button>

			<Grid
				w="100%"
				gap={5}
				templateColumns={{
					base: "repeat(1, 3fr)",
					lg: "repeat(2, 1fr)",
				}}
			>
				{
					!loading && <>
						<Box className="sidebar" display={{ base: "none", xl: "block" }}>
							{renderTaskSidebar()}
						</Box>

						<Box display={{ base: "block", xl: "none" }}>
							{renderTaskSidebar()}
						</Box>
					</>
				}

				<Box gridColumnStart={{ xl: 2 }}>
					{
						!loading && <>
							<Page
								scale={2}
								currentPage={currentPage}
								attemptData={attemptData}
								setProgressState={setProgressState}
							/>

							{renderNavigation()}
						</>
					}
				</Box>
			</Grid>

			<ObbiAlertDialog
				isOpen={isOpen}
				onConfirm={() => deleteAttempt(attemptData.ttaid)}
				onClose={onClose}
				title="Discard this report?"
				message="Are you sure you want to discard this task report?"
			/>
		</Box>
	);
};
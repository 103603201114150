import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Text, Badge, Button, FormControl, FormErrorMessage, FormLabel, HStack, Icon, Input, SlideFade, Spacer, Tag, TagCloseButton, TagLabel, Textarea, VStack, useBreakpointValue, Image, ButtonGroup, Divider, useToast, SimpleGrid, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { cloneRiskAssessmentIntoOrg, patchRiskAssessment, patchVersionRiskAssessment, updateRiskAssessmentTeams } from '../../features/riskAssessments/riskAssessments.actions';
import ObbiContainer from '../common/ObbiContainer';
import { BsExclamationCircle, BsArrowLeftCircle, BsArrowRightCircle, BsXCircle, BsExclamationTriangle, BsLifePreserver } from 'react-icons/bs';
import { TeamMessageCombobox } from './TeamMessageCombobox';
import whiteFlower from "../../assets/images/whiteFlower.svg";
import { TeamCombobox } from '../common/TeamCombobox';
import { getCurrentTeams } from '../../features/common/common.actions';
import _ from 'lodash';
import hazardImg from "../../assets/images/incident.svg";
import raDetails from "../../assets/images/warningToGo.svg";

const RiskAssessmentClone: React.FC = () => {
    const { id: riskAssessmentId } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const navigateTo = useNavigate();
    const { state }: any = useLocation();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const toast = useToast();

    const [editingTeams, setEditingTeams] = useState<any>([]);

    const [createTitleError, setCreateTitleError] = useState(false);
    const [createCategoryError, setCreateCategoryError] = useState(false);
    const [createDescriptionError, setCreateDescriptionError] = useState(false);
    const [createAckTeamsError, setCreateAckTeamsError] = useState(false);
    const [createEditTeamError, setCreateEditTeamError] = useState(false);

    const [acknowledgementTeams, setAcknowledgementTeams] = useState<any>([]);
    const [selectedAcknowledgementTeams, setSelectedAcknowledgementTeams] = useState<any>([]);

    const [editingTeamId, SetEditingTeamId] = useState<any>(null);
    const [riskAssessment, setRiskAssessment] = useState<any>(null);
    const [RAName, setRAName] = useState<string>(state?.riskAssessment?.raname || "");
    const [RACategory, setRACategory] = useState<string>(state?.riskAssessment?.racategory || "");
    const [RADescription, setRADescription] = useState<string>(state?.riskAssessment?.radescription || "");

    const defaultIndices = riskAssessment?.publishedversion?.hazards?.map((_: any, index: any) => index) || [];

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                getTeams();

            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        getData();
        setRiskAssessment(state.riskAssessment);
    }, [riskAssessmentId]);

    const getTeams = () => {
        getCurrentTeams()
            .then((res: any) => {
                let updatedTeams = res?.manages;

                for (const team of res?.manages) {
                    updatedTeams = updatedTeams.concat(team?.childteams);
                }

                setEditingTeams(updatedTeams);

                for (const team of selectedAcknowledgementTeams) {
                    updatedTeams = _.filter(
                        updatedTeams,
                        (t: any) => {
                            return t?.teamid !== team?.teamid
                        }
                    );
                }
                setAcknowledgementTeams(updatedTeams);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const selectTeam = (arg: any): void => {
        if (arg.teamid === "0") return;

        let currentSelection = [...selectedAcknowledgementTeams];
        currentSelection.push(arg);

        let updatedTeams = _.filter(
            acknowledgementTeams,
            (team: any) => {
                return arg.teamid !== team.teamid;
            }
        );

        setAcknowledgementTeams(updatedTeams);
        setSelectedAcknowledgementTeams(currentSelection);
        setCreateAckTeamsError(false);
    };


    const deselectTeam = (id: string): void => {
        let currentSelection = [...selectedAcknowledgementTeams];

        const deselectedTeam =
            _.filter(
                currentSelection,
                (team: any) => {
                    return id === team.teamid;
                }
            );

        currentSelection = _.filter(
            currentSelection,
            (team: any) => {
                return !deselectedTeam.includes(team)
            }
        );

        let updatedTeams = [...acknowledgementTeams];
        updatedTeams = updatedTeams.concat(deselectedTeam);
        updatedTeams = _.orderBy(updatedTeams, [team => team?.teamname?.toLowerCase()], ['asc']);

        setAcknowledgementTeams(updatedTeams);
        setSelectedAcknowledgementTeams(currentSelection);
    };

    const cloneRiskAssessment = async () => {
        try {
            if (!riskAssessmentId || !editingTeamId) {
                console.error("Risk Assessment ID or Editing Team ID is missing");
                return;
            }

            const postData: { raid: string, editingteamid: number, teams: [], name?: string, description?: string, category?: string } = {
                raid: riskAssessmentId,
                editingteamid: parseInt(editingTeamId),
                teams: selectedAcknowledgementTeams?.map((team: any) => team.teamid),
            }

            if (RAName !== riskAssessment?.raname) {
                postData.name = RAName;
            }

            if (RACategory !== riskAssessment?.racategory) {
                postData.category = RACategory;
            }

            if (RADescription !== riskAssessment?.radescription) {
                postData.description = RADescription;
            }

            const cloneResult: any = await cloneRiskAssessmentIntoOrg(postData);

            if (!cloneResult) {
                console.error("Error cloning risk assessment");
                return;
            }

            return cloneResult;
        } catch (error) {
            console.log(error);
        }
    }

    const patchRiskAssessmentFromData = async (raid: string, name: string, value: any) => {

        try {
            const patchBody = {
                id: raid,
                name: name,
                value: value
            };

            await patchRiskAssessment(patchBody);

            if (name === "name") {

                const patchBody = {
                    id: raid,
                    version: riskAssessment?.currentversion?.ravid,
                    name: name,
                    value: value
                };

                await patchVersionRiskAssessment(patchBody)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const confirmTemplate = async () => {

        try {
            //validate data

            if (RAName === "") {
                setCreateTitleError(true);
            } else {
                setCreateTitleError(false);
            }

            if (RACategory === "") {
                setCreateCategoryError(true);
            } else {
                setCreateCategoryError(false);
            }

            if (RADescription === "") {
                setCreateDescriptionError(true);
            } else {
                setCreateDescriptionError(false);
            }

            if (selectedAcknowledgementTeams.length === 0) {
                setCreateAckTeamsError(true);
            } else {
                setCreateAckTeamsError(false);
            }

            if (editingTeamId === null) {
                setCreateEditTeamError(true);
            } else {
                setCreateEditTeamError(false);
            }

            if (RAName === "" || RACategory === "" || RADescription === "" || selectedAcknowledgementTeams.length === 0 || editingTeamId === null) {
                toast({
                    title: "Please complete all required fields",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            //clone RA

            const cloneResult: any = await cloneRiskAssessment();

            if (!cloneResult) {
                toast({
                    title: "Error cloning risk assessment",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            //navigate to edit ra

            navigateTo(`/riskassessments/edit/${cloneResult?.newRaId}`, {
                state: {
                    isReview: true,
                    step: 1
                }
            });

        } catch (error) {
            toast({
                title: "Error cloning risk assessment",
                status: "error",
                duration: 5000,
                isClosable: true,
            });

            console.log(error);
        }
    }

    return (
        <ObbiContainer
            icon={BsExclamationCircle}
            title="Create From Template"
            backLink={
                <Button
                    display={{ base: "none", md: "unset" }}
                    w="fit-content"
                    size="sm"
                    variant="outline"
                    color="gray.600"
                    fontWeight={600}
                    boxShadow="xs"
                    onClick={() => {
                        if (state?.returnlocation) {
                            navigateTo(state?.returnlocation, { state: state?.returnlocationstate });
                        } else {
                            navigateTo(-1);
                        }
                    }}
                >
                    <HStack>
                        <Icon as={BsArrowLeftCircle} />
                        <Text>{state?.returntitle ?? 'Back'}</Text>
                    </HStack>
                </Button>
            }
        >
            <HStack
                w="full"
                pb={3}
                px={{ base: 3, md: 0 }}
                mb={10}
            >
                <VStack alignItems="baseline" >
                    <Text
                        fontWeight={{ base: 600, lg: 700 }}
                        fontSize="2xl"
                        textAlign="left"
                        color="gray.700"
                    >
                        New Risk Assessment Details
                    </Text>

                    <Text color="gray.600" fontSize={{ base: "xs", md: "sm" }}>
                        Before creating a new risk assessment from this template, please ensure the correct details are added.
                    </Text>
                </VStack>
                <Spacer />

                <Image
                    src={raDetails}
                    h={{ base: "75px", md: "100px" }}
                    pt={2}
                />
            </HStack>

            {/* Risk Assessment Details */}

            <VStack
                alignItems="start"
                gap={2}
                backgroundColor={"gray.300"}
                mb={8}
                mx={-10}
                mt={-5}
                p={5}
            >
                {
                    <SlideFade in={!loading} style={{ width: "100%" }}>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} width="full">
                            {/* Left Column */}
                            <VStack alignItems="start" gap={3}>
                                <FormControl
                                    isRequired
                                    isInvalid={createTitleError}
                                >
                                    <FormLabel
                                        fontWeight={500}
                                        fontSize="sm"
                                        color="gray.600"
                                    >
                                        Risk Assessment Name
                                    </FormLabel>
                                    <Input
                                        defaultValue={riskAssessment?.raname || ""}
                                        onChange={(e) => {
                                            setRAName(e.target.value);
                                        }}
                                    />
                                    {createTitleError && <FormErrorMessage>Please provide a name for this risk assessment</FormErrorMessage>}
                                </FormControl>

                                <FormControl
                                    isRequired
                                    isInvalid={createCategoryError}
                                >
                                    <FormLabel
                                        fontWeight={500}
                                        fontSize="sm"
                                        color="gray.600"
                                    >
                                        Category
                                    </FormLabel>
                                    <Input
                                        defaultValue={riskAssessment?.racategory || ""}
                                        onChange={(e) => {
                                            setRACategory(e.target.value);
                                        }}
                                    />
                                    {createCategoryError && <FormErrorMessage>Please provide a category for this risk assessment</FormErrorMessage>}
                                </FormControl>

                                <FormControl
                                    isRequired
                                    isInvalid={createDescriptionError}
                                >
                                    <FormLabel
                                        fontWeight={500}
                                        fontSize="sm"
                                        color="gray.600"
                                    >
                                        Description
                                    </FormLabel>
                                    <Textarea
                                        rows={4}
                                        defaultValue={riskAssessment?.radescription || ""}
                                        onChange={(e) => {
                                            setRADescription(e.target.value);
                                        }}
                                    />
                                    {createDescriptionError && <FormErrorMessage>Please provide a description for this risk assessment</FormErrorMessage>}
                                </FormControl>

                            </VStack>

                            {/* Right Column */}
                            <VStack alignItems="start" gap={3}>

                                <FormControl
                                    isRequired
                                    isInvalid={createEditTeamError}
                                    w="full"
                                >
                                    <Box
                                        border="1px solid"
                                        borderColor="gray.400"
                                        rounded="lg"
                                        p={3}
                                    >
                                        <HStack mb={2}>
                                            <VStack alignItems="start">
                                                <FormLabel
                                                    fontWeight={500}
                                                    fontSize="sm"
                                                    color="gray.600"
                                                >
                                                    Managing Team
                                                </FormLabel>
                                                <Text
                                                    fontWeight={500}
                                                    fontSize="xs"
                                                    color="gray.600"
                                                >
                                                    Members of this team are able to make changes to this Risk Assessment prior to publishing.
                                                </Text>
                                            </VStack>
                                        </HStack>
                                        <TeamCombobox
                                            teams={editingTeams}
                                            update={(e: any) => {
                                                SetEditingTeamId(e);
                                                setCreateEditTeamError(false);
                                            }}
                                            hideAllTeams={true}
                                            backgroundColor={"white"}
                                        />
                                    </Box>
                                    {createEditTeamError &&
                                        <Box
                                            backgroundColor={'white'}
                                            mt={-1}
                                            alignItems={'center'} justifyContent={'center'} borderBottomRadius={8}
                                            pt={1}
                                            pb={2}
                                            pl={3}
                                        >
                                            <FormErrorMessage>Please select a team to manage this risk assessment</FormErrorMessage>
                                        </Box>
                                    }
                                </FormControl>

                                <FormControl
                                    isRequired
                                    isInvalid={createAckTeamsError}
                                    p={3}
                                    border="1px solid"
                                    borderColor="gray.400"
                                    rounded="lg"
                                >
                                    <VStack alignItems="start">
                                        <FormLabel
                                            fontWeight={500}
                                            fontSize="sm"
                                            color="gray.600"
                                        >
                                            Teams to Acknowledge
                                            <Badge
                                                alignSelf="baseline"
                                                size="sm"
                                                ml={2}
                                            >
                                                {(selectedAcknowledgementTeams?.length).toString()}
                                            </Badge>
                                        </FormLabel>
                                        <Box>
                                            <Text
                                                fontWeight={500}
                                                fontSize="xs"
                                                color="gray.600"
                                            >
                                                The teams you select from the list are required to read and acknowledge this risk assessment.
                                            </Text>
                                            <Text
                                                fontWeight={500}
                                                fontSize="xs"
                                                color="gray.600"
                                            >
                                                Teams you have responsibilities for appear in this list.
                                            </Text>
                                        </Box>
                                        <Box w="full">
                                            <TeamMessageCombobox
                                                teams={acknowledgementTeams}
                                                update={selectTeam}
                                                selectedTeam={{}}
                                                placeholder="Select Teams"
                                            />
                                        </Box>
                                    </VStack>
                                    {
                                        selectedAcknowledgementTeams?.length > 0 && <Box textAlign="left">
                                            {
                                                selectedAcknowledgementTeams?.map((team: any, i: number) => (
                                                    <Tag
                                                        key={`team_${i}`}
                                                        size="md"
                                                        mr={2}
                                                        mt={3}
                                                        borderRadius='lg'
                                                        variant='solid'
                                                        colorScheme='white'
                                                    >
                                                        <TagLabel color={'gray.600'}>{team.teamname}</TagLabel>
                                                        <TagCloseButton
                                                            color={'gray.600'}
                                                            onClick={() => {
                                                                deselectTeam(team.teamid)
                                                            }}
                                                        />
                                                    </Tag>
                                                ))
                                            }
                                        </Box>
                                    }

                                    {createAckTeamsError &&
                                        <Box
                                            backgroundColor={'white'}
                                            mt={-1}
                                            alignItems={'center'} justifyContent={'center'} borderBottomRadius={8}
                                            pt={1}
                                            pb={2}
                                            pl={3}
                                        >
                                            <FormErrorMessage>Please select at least one team to acknowledge this risk assessment</FormErrorMessage>
                                        </Box>
                                    }
                                </FormControl>
                            </VStack>
                        </SimpleGrid>
                    </SlideFade>
                }
            </VStack>

            {/* Hazards */}

            <HStack
                w="full"
                pb={3}
                px={{ base: 3, md: 0 }}
                mb={5}
            >
                <VStack alignItems="baseline">
                    <Text
                        fontWeight={{ base: 600, lg: 700 }}
                        fontSize="2xl"
                        textAlign="left"
                        color="gray.700"
                    >
                        Hazards
                    </Text>

                    <Text color="gray.600" fontSize={{ base: "xs", md: "sm" }}>
                        The following hazards are provided as part of this template.  Once you have selected this template you should review and update each hazard to make it appropriate for your organisation. Understanding these risks will help keep you and your colleagues safe.
                    </Text>
                </VStack>
                <Spacer />
                <Image
                    src={hazardImg}
                    h={{ base: "75px", md: "100px" }}
                    pt={2}
                />
            </HStack>

            {/* Hazards list in accordion */}

            {defaultIndices?.length > 0 &&

                <VStack spacing={4}
                    align="stretch"
                    width="100%"
                    mb={10}
                >
                    <Accordion allowToggle allowMultiple defaultIndex={defaultIndices}>
                        {riskAssessment?.publishedversion?.hazards?.map((hazard: any, index: any) => (
                            <AccordionItem key={hazard.rahid}>
                                {({ isExpanded }) => (
                                    <>
                                        <Box>
                                            <AccordionButton
                                                _expanded={{ bg: 'gray.100' }}
                                                height="50px"
                                            >
                                                <Box flex="1" textAlign="left" fontWeight="medium">
                                                    <HStack>
                                                        <Text
                                                            color="gray.600"
                                                            fontWeight={500}
                                                            fontSize={{ base: "sm", md: "md" }}
                                                        >
                                                            {hazard.rahdescription}
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Box>
                                        <AccordionPanel
                                            pb={4}
                                        >
                                            <HStack alignItems={'center'} my={2}>
                                                <Icon as={BsLifePreserver} color="gray.500" />
                                                <Text
                                                    color="gray.600"
                                                    fontWeight={600}
                                                    fontSize={{ base: "xs", md: "sm" }}
                                                >
                                                    Suggested Controls
                                                </Text>
                                            </HStack>
                                            <VStack spacing={2} align="stretch" width="100%">
                                                {hazard?.controls?.length === 0 ?
                                                    <Text>No controls found</Text> :
                                                    hazard?.controls?.map((control: any) => (
                                                        <Box
                                                            border={'1px solid'}
                                                            borderColor={'gray.200'}
                                                            p={2}
                                                            rounded={'md'}
                                                        >
                                                            <Text
                                                                color="gray.600"
                                                                fontWeight={500}
                                                                fontSize={{ base: "xs", md: "sm" }}
                                                            >
                                                                {control?.racdescription}
                                                            </Text>
                                                        </Box>
                                                    ))}
                                            </VStack>

                                        </AccordionPanel>
                                    </>
                                )}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </VStack>
            }

            {/* Action buttons */}

            <Divider
                w="unset"
                mx={{ base: 0, md: -5 }}
            />

            <Box py={5} px={{ base: 5, md: 0 }}>
                <ButtonGroup gap={1}>
                    <Button
                        leftIcon={<BsXCircle />}
                        variant="outline"
                        size="sm"
                        color="gray.500"
                        fontWeight={600}
                        onClick={() => {
                            if (state?.returnlocation) {
                                navigateTo(state?.returnlocation, { state: state?.returnlocationstate });
                            } else {
                                navigateTo(-1);
                            }
                        }}
                    >
                        <Text>Cancel</Text>
                    </Button>
                    <Button
                        rightIcon={<BsArrowRightCircle />}
                        colorScheme="brand"
                        size="sm"
                        fontWeight={600}
                        _hover={{
                            bg: "#248451"
                        }}
                        onClick={confirmTemplate}
                    >
                        <Text>
                            Next
                        </Text>
                    </Button>
                </ButtonGroup>
            </Box>
        </ObbiContainer>
    );
};

export default RiskAssessmentClone;
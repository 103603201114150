import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _, { isEmpty, isNull, isUndefined } from "lodash";
import {
    Badge,
    Box,
    HStack,
    Icon,
    Text,
    Divider,
    Flex,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Image,
    Textarea,
    Button,
    ButtonGroup,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverHeader,
    PopoverBody,
    PopoverCloseButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tag,
    TagCloseButton,
    TagLabel,
    Center,
    SimpleGrid,
    Square,
    Spacer,
    useBreakpointValue,
    FormErrorMessage,
    useToast,
    SlideFade,
    Spinner,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import {
    BsArrowLeftCircle,
    BsArrowRightCircle,
    BsCheckCircle,
    BsExclamationCircle,
    BsXCircle,
    BsInfoCircle,
    BsPlusCircle,
    BsTrash,
    BsPaperclip,
    BsFilePdf,
    BsFileWord,
    BsExclamationTriangle
} from "react-icons/bs";
import { IoSkullOutline } from "react-icons/io5";

import { RootState } from "../../redux/rootReducer";
import { createRiskAssessment, createHazard, getRiskAssessment, patchRiskAssessment, postEditingTeam, updateRiskAssessment, patchVersionRiskAssessment, publishRiskAssessment, deleteDraft, addAttachment, getAttachment, deleteAttachment, addHazardAttachment, updateRiskAssessmentTeams } from "../../features/riskAssessments/riskAssessments.actions";
import { getCurrentTeams } from "../../features/common/common.actions";

import { useStep } from "../common/progressSteps/useStep";
import { Step } from "../common/progressSteps/Step";
import { DropZone } from "../common/DropZone";
import { TeamMessageCombobox } from "./TeamMessageCombobox"
import { EditingTeamCombobox } from "./EditingTeamCombobox";
import { Hazard } from "./Hazard";

import nothingToSee from "../../assets/images/nothingToSee.svg";
import questionGreen from "../../assets/images/questionGreen.svg";

import waiting from "../../assets/images/waiting.svg";
import hazardImg from "../../assets/images/incident.svg";
import obbiHelper from "../../assets/logos/obbi_helper.svg";

import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { fileTypeFromBlob } from "file-type";
import { Document, Page, pdfjs } from "react-pdf";
import { useDebounce } from "../common/useDebounce";

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const RiskAssessmentEditor: React.FC<any> = () => {
    const { state }: any = useLocation();
    const { id: riskAssessmentId } = useParams();
    const toast = useToast();

    const navigateTo = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const org = useSelector((state: RootState) => state.authReducer);

    const [loading, setLoading] = useState<boolean>(true);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [fileUploadLoadingHazardAttachment, setFileUploadLoadingHazardAttachment] = useState(false);

    const [riskAssessmentHasBeenCreated, setRiskAssessmentHasBeenCreated] = useState<boolean>(false);

    const [riskAssessment, setRiskAssessment] = useState<any>({
        raid: "",
        raname: "",
        raexpirydate: "",
        raeditingteam: "",
        editingteamname: "",
        currentversion: {
            ravid: ""
        }
    });

    const steps = [
        { title: "Step 1", description: "Details" },
        { title: "Step 2", description: "Hazards" },
        { title: "Step 3", description: "Review & Publish" }
    ];

    const [currentStep, { setStep }] = useStep({
        maxStep: steps.length,
        initialStep: state?.step || 0
    });

    const [attachments, setAttachments] = useState<any[]>([]);
    const [hazardAttachments, setHazardAttachments] = useState<any[]>([]);
    const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);

    const [pdfIsOpen, setPdfIsOpen] = useState(false);
    const [deleteIsOpen, setDeleteIsOpen] = useState({ id: "", open: false });
    const [deleteHazardIsOpen, setDeleteHazardIsOpen] = useState({ id: "", open: false });

    const [currentImage, setCurrentImage] = useState("")
    const [currentCert, setCurrentCert] = useState({
        id: 0,
        isPdf: false,
        uri: "",
        fileName: ""
    });

    // PDF pages
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const resetDocViewer = () => {
        setNumPages(0);
        setPageNumber(1);
    };

    const [acknowledgementTeams, setAcknowledgementTeams] = useState<any>([]);
    const [selectedAcknowledgementTeams, setSelectedAcknowledgementTeams] = useState<any>([]);
    const [actionTeams, setActionTeams] = useState<any>([]);

    const [editingTeams, setEditingTeams] = useState<any>([]);
    const [editingTeam, setEditingTeam] = useState<any>({
        teamid: "",
        teamname: ""
    });

    const [date, setDate] = useState<any>(null);

    const [createBody, setCreateBody] = useState<any>({
        title: "",
        description: "",
        category: "",
        teams: [],
        notes: "",
        nextReviewDate: "",
        approvedBy: "",
        externalReviewers: ""
    });

    const [createTitleError, setCreateTitleError] = useState(false);
    const [createCategoryError, setCreateCategoryError] = useState(false);
    const [createDescriptionError, setCreateDescriptionError] = useState(false);
    const [createAckTeamsError, setCreateAckTeamsError] = useState(false);
    const [createEditTeamError, setCreateEditTeamError] = useState(false);
    const [createDateError, setCreateDateError] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [isPublishOpen, setIsPublishOpen] = useState(false);
    const [isDeleteDraftOpen, setIsDeleteDraftOpen] = useState(false);

    const [nextReviewDateError, setNextReviewDateError] = useState(false);
    const [reviewNotesError, setReviewNotesError] = useState(false);

    const [reviewNotes, setReviewNotes] = useState<any>("");
    const [description, setDescription] = useState<any>("");

    const [hazards, setHazards] = useState<any>([]);

    const [newHazard, setNewHazard] = useState<any>({
        title: "",
        affects: "",
        consequences: "",
        risk: { riskText: "Select Risk Level", score: 0 },
        newrisk: { riskText: "Select Risk Level", score: 0 }
    });

    const [newHazardTitleError, setNewHazardTitleError] = useState(false);
    const [newHazardAffectsError, setNewHazardAffectsError] = useState(false);
    const [newHazardConsequencesError, setNewHazardConsequencesError] = useState(false);
    const [newHazardRiskError, setNewHazardRiskError] = useState(false);

    const [dropZoneActive, setDropZoneActive] = useState(false);
    const [addAttachmentModalIsOpen, setAddAttachmentModalIsOpen] = useState(false);
    const [newRiskLevelError, setNewRiskLevelError] = useState(false);

    //#region Hazard
    const renderAddHazardModal = () => {
        return <Modal
            size="3xl"
            isOpen={isOpen}
            onClose={() => {
                setNewHazard({
                    title: "",
                    affects: "",
                    consequences: "",
                    risk: { riskText: "Select Risk Level", score: 0 },
                    newrisk: { riskText: "Select Risk Level", score: 0 },
                    controls: []
                });

                setNewHazardTitleError(false);
                setNewHazardAffectsError(false);
                setNewHazardConsequencesError(false);
                setNewHazardRiskError(false);

                setHazardAttachments([]);
                setDropZoneActive(false);

                setIsOpen(false);
            }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    Add Hazard
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <VStack
                        alignItems="start"
                        gap={2}
                        w="full"
                    >
                        <Box
                            w="full"
                            p={0}
                            pr={3}
                            border="1px solid"
                            borderColor="green.600"
                            rounded="lg"
                            bg="brand.500"
                        >
                            <HStack>
                                <Image src={obbiHelper} h="100px" bg="white" roundedLeft="6.5px" />

                                <Spacer />

                                <Box alignItems="start" alignSelf="center">
                                    <Text
                                        fontWeight={700}
                                        color="white"
                                        mb={1}
                                    >
                                        Obbi Tips - Hazards
                                    </Text>

                                    <Text
                                        fontWeight={500}
                                        fontSize={{ base: "8pt", md: "sm" }}
                                        color="green.200"
                                    >
                                        Think about different types of hazards - physical, chemical, biological, ergonomic, and environmental.
                                    </Text>
                                </Box>
                            </HStack>
                        </Box>

                        <FormControl isRequired isInvalid={newHazardTitleError}>
                            <FormLabel
                                fontWeight={600}
                                color="gray.600"
                            >
                                Hazard Description
                            </FormLabel>
                            <InputGroup>
                                <Input
                                    onChange={(e) => {
                                        setNewHazard({
                                            ...newHazard,
                                            title: e.target.value
                                        });
                                    }}
                                    paddingRight={"50px"}
                                />
                                <InputRightElement
                                    color={"gray.500"}
                                    borderTopRightRadius={"md"}
                                    borderBottomRightRadius={"md"}
                                    borderLeft={"1px solid"}
                                    borderColor={"gray.200"}
                                >
                                    <Icon as={BsPaperclip} fontSize="xl"
                                        onClick={() => { setDropZoneActive(!dropZoneActive); }}
                                    />
                                </InputRightElement>
                            </InputGroup>

                            {newHazardTitleError && <FormErrorMessage>Please provide a hazard description</FormErrorMessage>}
                        </FormControl>

                        {
                            dropZoneActive &&
                            <FormControl>
                                <Box
                                    pt={2}
                                >
                                    <DropZone
                                        onDropAction={onDropActionHazardAttachment}
                                        fileUploadLoading={fileUploadLoadingHazardAttachment}
                                        additionalAcceptedFileTypes={['DOCX']}
                                    />
                                </Box>
                            </FormControl>
                        }
                        {
                            hazardAttachments.length > 0 &&
                            <VStack alignItems="start">
                                <FormControl>
                                    <SlideFade in={!loading}
                                        offsetY="20px"
                                    >
                                        <FormLabel
                                            fontWeight={600}
                                            color="gray.500"
                                        >
                                            <HStack>
                                                <Icon as={BsPaperclip} fontSize="md" />
                                                <Text>Attachments</Text>
                                            </HStack>
                                        </FormLabel>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            flexWrap={'wrap'}
                                            justifyContent={'flex-start'}
                                            gap={3}
                                        >
                                            {
                                                hazardAttachments.map((a: any, index: any) => {
                                                    return (
                                                        <>
                                                            {(a.isPdf || a.isDoc) ?
                                                                <Box minW="100px" key={index}>
                                                                    <Box minW="100px"
                                                                        boxSize='100px'
                                                                        boxShadow="xs"
                                                                        border="1px solid"
                                                                        borderColor="gray.200"
                                                                        rounded="lg"
                                                                        display={'flex'}
                                                                        flexDirection={'column'}
                                                                        justifyContent={"center"}
                                                                        alignItems={"center"}
                                                                        p={1}
                                                                    >
                                                                        <Icon as={a.isPdf ? BsFilePdf : BsFileWord} style={{ fontSize: "30px" }} />
                                                                        <Text
                                                                            fontSize={'xx-small'}
                                                                        >{a.fileName}</Text>
                                                                    </Box>
                                                                    <Button
                                                                        className="hover-pop"
                                                                        variant="link"
                                                                        size="xs"
                                                                        color="gray.500"
                                                                        _hover={{ color: "" }}
                                                                        onClick={() => {
                                                                            setDeleteHazardIsOpen({ id: a.fileName, open: true });
                                                                        }}
                                                                    >
                                                                        <Text textDecoration="underline">Delete</Text>
                                                                    </Button>
                                                                </Box>
                                                                :
                                                                <Box minW="100px" key={index}>
                                                                    <Image
                                                                        className="hover-pop"
                                                                        cursor="pointer"
                                                                        src={a.thumbnail}
                                                                        fallbackSrc='https://via.placeholder.com/160'
                                                                        alt={a.fileName}
                                                                        boxSize='100px'
                                                                        boxShadow="xs"
                                                                        objectFit="cover"
                                                                        border="1px solid"
                                                                        borderColor="gray.200"
                                                                        rounded="lg"
                                                                        onClick={() => {
                                                                            setCurrentImage(a.thumbnail);
                                                                            setIsAttachmentOpen(true);
                                                                        }}
                                                                    />

                                                                    <Button
                                                                        className="hover-pop"
                                                                        variant="link"
                                                                        size="xs"
                                                                        color="gray.500"
                                                                        _hover={{ color: "" }}
                                                                        onClick={() => {
                                                                            setDeleteHazardIsOpen({ id: a.fileName, open: true });
                                                                        }}
                                                                    >
                                                                        <Text textDecoration="underline">Delete</Text>
                                                                    </Button>
                                                                </Box>
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </SlideFade>
                                </FormControl>
                            </VStack>
                        }

                        <FormControl isRequired isInvalid={newHazardAffectsError}>
                            <FormLabel
                                fontWeight={600}
                                color="gray.600"
                            >
                                People Affected
                            </FormLabel>

                            <Textarea rows={3} onChange={(e) => {
                                setNewHazard({
                                    ...newHazard,
                                    affects: e.target.value
                                });
                            }} />

                            {newHazardAffectsError && <FormErrorMessage>Please provide parties or individuals at risk</FormErrorMessage>}
                        </FormControl>

                        <FormControl isRequired isInvalid={newHazardConsequencesError}>
                            <FormLabel
                                fontWeight={600}
                                color="gray.600"
                            >
                                Consequences of Hazard
                            </FormLabel>

                            <Textarea rows={3} onChange={(e) => {
                                setNewHazard({
                                    ...newHazard,
                                    consequences: e.target.value
                                });
                            }} />

                            {newHazardConsequencesError && <FormErrorMessage>Please provide hazard consequences</FormErrorMessage>}
                        </FormControl>

                        <HStack w="100%">
                            <FormControl isRequired isInvalid={newHazardRiskError}>
                                <FormLabel
                                    fontWeight={600}
                                    color="gray.600"
                                >
                                    Risk Before Controls
                                    <Popover>
                                        <PopoverTrigger>
                                            <Button variant="link" size="xs"><Icon as={BsInfoCircle} /></Button>
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <PopoverArrow />
                                            <PopoverCloseButton />
                                            <PopoverHeader
                                                fontWeight={600}
                                                fontSize="sm"
                                                color="gray.600"
                                            >
                                                Risk before carrying out safety controls
                                            </PopoverHeader>
                                            <PopoverBody>
                                                <VStack alignItems="start" gap={2} my={2}>
                                                    <Box>
                                                        <Badge colorScheme="gray" mb={1}>
                                                            1 Trivial
                                                        </Badge>
                                                        <Text whiteSpace="pre-wrap">Won’t cause serious injuries or illnesses.</Text>
                                                    </Box>

                                                    <Box>
                                                        <Badge colorScheme="gray" mb={1}>
                                                            2 Minor
                                                        </Badge>
                                                        <Text whiteSpace="pre-wrap">Can cause injuries or illnesses, only to a mild extent.</Text>
                                                    </Box>

                                                    <Box>
                                                        <Badge colorScheme="yellow" mb={1}>
                                                            3 Moderate
                                                        </Badge>
                                                        <Text whiteSpace="pre-wrap">Can cause injuries or illnesses that may require medical attention but limited treatment.</Text>
                                                    </Box>

                                                    <Box>
                                                        <Badge colorScheme="orange" mb={1}>
                                                            4 Serious
                                                        </Badge>
                                                        <Text whiteSpace="pre-wrap">Can cause irreversible injuries or illnesses that require constant medical attention.</Text>
                                                    </Box>

                                                    <Box>
                                                        <Badge colorScheme="red" mb={1}>
                                                            5 Fatal
                                                        </Badge>
                                                        <Text whiteSpace="pre-wrap">Can result in fatality.</Text>
                                                    </Box>
                                                </VStack>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </FormLabel>

                                <Popover>
                                    {({ isOpen, onClose }) => (
                                        <>
                                            <PopoverTrigger>
                                                {
                                                    newHazard.risk.score === "0" ? <Button
                                                        h="40px"
                                                        variant="outline"
                                                        color="brand.500"
                                                        borderColor="brand.500"
                                                        _hover={{
                                                            color: "#248451",
                                                            borderColor: "#248451"
                                                        }}
                                                        onClick={() => {

                                                        }}
                                                    >
                                                        Select Level For Risk Before Controls
                                                    </Button> :

                                                        <Button
                                                            border="1px solid"
                                                            borderColor={
                                                                Number(newHazard.risk.score) > 0 ?
                                                                    getColor(newHazard.risk.score) : "gray.200"
                                                            }
                                                            variant={
                                                                Number(newHazard.risk.score) > 0 ?
                                                                    "link" : "solid"
                                                            }
                                                            onClick={() => { }}
                                                        >
                                                            {
                                                                Number(newHazard.risk.score) > 0 && <Square
                                                                    m={0.5}
                                                                    float="right"
                                                                    cursor="pointer"
                                                                    rounded="md"
                                                                    boxShadow="sm"
                                                                    size="40px"
                                                                    bg={getColor(newHazard.risk.score)}
                                                                >
                                                                    {
                                                                        newHazard.risk.riskText.indexOf("Fatal") > -1 &&
                                                                        <Icon
                                                                            as={IoSkullOutline}
                                                                            color="white"
                                                                            mr="2px"
                                                                        />
                                                                    }
                                                                    <Text
                                                                        color="white"
                                                                        fontWeight={700}
                                                                        fontSize={
                                                                            newHazard.risk.riskText.indexOf("Fatal") > -1 ?
                                                                                "10pt" : "lg"
                                                                        }
                                                                    >
                                                                        {newHazard.risk.score}
                                                                    </Text>
                                                                </Square>
                                                            }

                                                            <Text
                                                                color={getColor(newHazard.risk.score)}
                                                                fontSize="10pt"
                                                                fontWeight={600}
                                                                mx={3}
                                                            >
                                                                {newHazard.risk.riskText}
                                                            </Text>
                                                        </Button>
                                                }
                                            </PopoverTrigger>

                                            <PopoverContent w={{ base: "100%", lg: "max-content" }}>
                                                <PopoverBody p={5}>
                                                    {renderRiskMatrix(false, onClose)}
                                                </PopoverBody>
                                            </PopoverContent>
                                        </>
                                    )}
                                </Popover>

                                {newHazardRiskError && <FormErrorMessage>Please select a risk level</FormErrorMessage>}
                            </FormControl>
                        </HStack>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <HStack>
                        <Button
                            size="sm"
                            cursor="pointer"
                            onClick={() => {
                                setNewHazard({
                                    title: "",
                                    affects: "",
                                    consequences: "",
                                    risk: { riskText: "Select Risk Level", score: 0 },
                                    newrisk: { riskText: "Select Risk Level", score: 0 },
                                    controls: []
                                });

                                setNewHazardTitleError(false);
                                setNewHazardAffectsError(false);
                                setNewHazardConsequencesError(false);
                                setNewHazardRiskError(false);

                                setIsOpen(false);

                                setHazardAttachments([]);
                                setDropZoneActive(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            size="sm"
                            colorScheme="brand"
                            _active={{ bg: "" }}
                            _hover={{ bg: "#248451" }}
                            onClick={() => { validateAddHazardForm() }}
                        >
                            Add
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    };

    const validateAddHazardForm = () => {
        if (isEmpty(newHazard?.title)) {
            setNewHazardTitleError(true);
        }

        if (isEmpty(newHazard?.affects)) {
            setNewHazardAffectsError(true);
        }

        if (isEmpty(newHazard?.consequences)) {
            setNewHazardConsequencesError(true);
        }

        if (newHazard.risk.score < 1) {
            setNewHazardRiskError(true);
        }

        if (newHazard?.title &&
            newHazard?.affects &&
            newHazard?.consequences &&
            newHazard.risk.score > 0
        ) {
            addHazard();
        }
    }

    const onDropActionHazardAttachment = async (file: any) => {
        setFileUploadLoadingHazardAttachment(true);

        const fileExtension = await fileTypeFromBlob(file);

        const supportedFileTypes = ["pdf", "docx", "png", "jpg", "jpeg"];

        if (!supportedFileTypes.includes(fileExtension?.ext ?? "")) {
            toast({
                title: "Unsupported attachment type",
                description: "",
                status: "error",
                duration: 6000,
                isClosable: true
            });

            return;
        }

        if (hazardAttachments.length > 5) {
            toast({
                title: "You can only upload up to 6 attachments",
                description: "",
                status: "error",
                duration: 6000,
                isClosable: true
            });

            return;
        }

        const previewUrl = URL.createObjectURL(file);

        setHazardAttachments(hazardAttachments => [...hazardAttachments, {
            thumbnail: previewUrl,
            isPdf: fileExtension?.ext === "pdf",
            isDoc: fileExtension?.ext === "docx",
            fileName: file.name,
            file: file
        }]);

        setFileUploadLoadingHazardAttachment(false);
        setDropZoneActive(false);
    };

    const addHazard = () => {
        const createHazardBody = {
            id: Number(riskAssessment?.raid),
            version: Number(riskAssessment?.currentversion.ravid),
            description: newHazard.title,
            consequences: newHazard.consequences,
            peopleaffected: newHazard.affects,
            risklevel: newHazard.risk.score.toString(),
            riskcategory: newHazard.risk.riskText
        };

        createHazard(createHazardBody)
            .then(async (res) => {

                if (hazardAttachments.length === 0) {
                    setHazards([
                        ...hazards,
                        res?.hazard
                    ]);
                } else if (hazardAttachments.length > 0) {

                    try {
                        setFileUploadLoadingHazardAttachment(true);

                        let addHazardResponse: any = [];

                        for (const attachment of hazardAttachments) {

                            const formData = new FormData();
                            formData.append("id", riskAssessment?.raid);
                            formData.append("version", riskAssessment?.currentversion.ravid);
                            formData.append("hazard", res?.hazard?.rahid);
                            formData.append("file", attachment?.file);

                            addHazardResponse = await addHazardAttachment(formData);
                        }

                        if (addHazardResponse?.version?.hazards) {
                            setHazards(addHazardResponse?.version?.hazards);
                        }

                    } catch (error: any) {
                        console.log(error);
                    } finally {
                        setFileUploadLoading(false);
                        setFileUploadLoadingHazardAttachment(false);
                    }
                }
            })
            .catch((error: any) => {
                console.log(error);

                toast({
                    description: error.message,
                    status: "error",
                    duration: 6000,
                    isClosable: true
                });
            })
            .finally(() => {
                setNewHazard({
                    title: "",
                    affects: "",
                    risk: { riskText: "Select Risk Level", score: 0 },
                    controls: []
                });

                setIsOpen(false);

                setHazardAttachments([]);
                setDropZoneActive(false);
            });
    };
    //#endregion


    //#region Risk Assessment
    const resetCreateErrorState = () => {
        setCreateTitleError(false);
        setCreateCategoryError(false);
        setCreateDescriptionError(false);
        setCreateAckTeamsError(false);
        setCreateEditTeamError(false);
        setCreateDateError(false);
    };

    const createRiskAssessmentFromData = () => {
        resetCreateErrorState();

        let isValid = true;

        if (isEmpty(createBody.title)) {
            setCreateTitleError(true);
            isValid = false;
        }

        if (isEmpty(createBody.category)) {
            setCreateCategoryError(true);
            isValid = false;
        }

        if (isEmpty(createBody.description)) {
            setCreateDescriptionError(true);
            isValid = false;
        }

        if (isEmpty(selectedAcknowledgementTeams)) {
            setCreateAckTeamsError(true);
            isValid = false;
        }

        if (isEmpty(editingTeam.teamid)) {
            setCreateEditTeamError(true);
            isValid = false;
        }

        if (isValid) {
            setLoading(true);

            const requestBody = {
                organisation: org.user.orgid,
                name: createBody.title,
                category: createBody.category,
                description: createBody.description,
                teams: selectedAcknowledgementTeams?.map((t: any) => Number(t.teamid)),
                editingTeam: editingTeam.teamid
            };

            createRiskAssessment(requestBody)
                .then((res: any) => {
                    setRiskAssessment(res?.riskAssessment);
                    setRiskAssessmentHasBeenCreated(true);
                    setStep(currentStep + 1);

                    navigateTo(`/riskassessments/edit/${res?.riskAssessment?.raid}`,
                        {
                            state: {
                                raid: res?.riskAssessment?.raid,
                                ravid: res?.riskAssessment?.currentversion?.ravid
                            }
                        }
                    );
                })
                .catch((error: any) => {
                    console.log(error);

                    toast({
                        title: `A Risk Assessment named "${createBody.title}" already exists.`,
                        description: "",
                        status: "error",
                        duration: 6000,
                        isClosable: true
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const patchRiskAssessmentFromData = (name: string, value: any) => {
        const patchBody = {
            id: riskAssessment?.raid,
            name: name,
            value: value
        };

        patchRiskAssessment(patchBody)
            .then((res: any) => {
                setRiskAssessment(res?.riskAssessment);
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => { });

        if (name === "name" || name === "expirydate") {

            if (name === "expirydate") {
                name = "nextreviewdate";
            }

            const patchBody = {
                id: riskAssessment?.raid,
                version: riskAssessment?.currentversion?.ravid,
                name: name,
                value: value
            };

            patchVersionRiskAssessment(patchBody)
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => { });
        }
    };

    const putRiskAssessmentFromData = (updatedTeams: any) => {
        const putBody = {
            id: riskAssessment?.raid,
            name: riskAssessment?.raname,
            category: riskAssessment?.racategory,
            description: riskAssessment?.radescription,
            expirydate: riskAssessment?.raexpirydate || date,
            expirynumber: riskAssessment?.raalertbeforeexpirynumber,
            expiryunit: riskAssessment?.raalertbeforeexpiryunit,
            expiryalert: riskAssessment?.alertwhenexpired,
            teams: updatedTeams.map((team: any) => team.teamid)
        };

        updateRiskAssessment(putBody)
            .then((res: any) => {
                setRiskAssessment(res?.riskAssessment);
            })
            .catch((error: any) => {
                console.log(error);

                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 6000,
                    isClosable: true
                });
            })
            .finally(() => { });
    };

    const confirmPublishModal = () => {
        return <Modal
            size="lg"
            isOpen={isPublishOpen}
            onClose={() => { setIsPublishOpen(false); }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    Publish Risk Assessment
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <VStack
                        fontSize="sm"
                        alignItems="start"
                        gap={2}
                        w="full"
                    >
                        <Text color="gray.600" fontWeight={500}>
                            Are you sure you want to publish this risk assessment?
                        </Text>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <HStack>
                        <Button
                            onClick={() => { setIsPublishOpen(false); }}
                            cursor="pointer"
                            size="sm"
                        >
                            Cancel
                        </Button>

                        <Button
                            className="hover-pop"
                            colorScheme="brand"
                            size="sm"
                            _active={{ bg: "" }}
                            _hover={{ bg: "#248451" }}
                            onClick={() => {
                                publishRiskAssessmentPressed()
                            }}
                        >
                            Publish
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    };

    function publishRiskAssessmentPressed() {
        try {
            if (isNull(riskAssessment?.currentversion?.ravnextreviewdate)) {
                const patchBody = {
                    id: riskAssessment?.raid,
                    version: riskAssessment?.currentversion?.ravid,
                    name: "nextreviewdate",
                    value: date
                };

                postRiskAssessmentTeams();

                patchVersionRiskAssessment(patchBody)
                    .catch((error: any) => {
                        console.log(error);

                        toast({
                            title: "Error",
                            description: error.message,
                            status: "error",
                            duration: 6000,
                            isClosable: true
                        });
                    })
                    .finally(() => {
                        publishRiskAssessment(riskAssessment?.raid)
                            .then((res: any) => {
                                navigateTo(`/riskassessments/version/${res?.riskAssessment?.publishedversion?.ravid}`,
                                    {
                                        state: {
                                            raid: res?.riskAssessment?.raid,
                                            ravid: res?.riskAssessment?.publishedversion?.ravid
                                        }
                                    }
                                );
                            });
                    });
            } else {
                publishRiskAssessment(riskAssessment?.raid)
                    .then((res: any) => {
                        navigateTo(`/riskassessments/version/${res?.riskAssessment?.publishedversion?.ravid}`,
                            {
                                state: {
                                    raid: res?.riskAssessment?.raid,
                                    ravid: res?.riskAssessment?.publishedversion?.ravid
                                }
                            }
                        );
                    })
                    .catch((error: any) => {
                        console.log(error);

                        toast({
                            title: "Error",
                            description: error.message,
                            status: "error",
                            duration: 6000,
                            isClosable: true
                        });
                    });
            }
        } catch (error: any) {
            console.log(error);

            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 6000,
                isClosable: true
            });
        }
    }

    const deleteDraftModal = () => {
        return <Modal
            size="lg"
            isOpen={isDeleteDraftOpen}
            onClose={() => { setIsDeleteDraftOpen(false); }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    Delete this Draft?
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <VStack
                        fontSize="sm"
                        alignItems="start"
                        gap={2}
                        w="full"
                    >
                        <Text color="gray.600" fontWeight={500}>
                            Are you sure you want to delete this draft of <strong>{riskAssessment?.raname}</strong>?
                        </Text>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <HStack>
                        <Button
                            size="sm"
                            cursor="pointer"
                            onClick={() => { setIsDeleteDraftOpen(false); }}
                        >
                            Cancel
                        </Button>

                        <Button
                            colorScheme="red"
                            size="sm"
                            _active={{ bg: "" }}
                            onClick={() => {
                                deleteThisDraft();
                            }}
                        >
                            Delete
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    };

    const deleteThisDraft = () => {
        deleteDraft(riskAssessment?.raid)
            .then(() => {
                if (!isNull(riskAssessment?.publishedversion)) {
                    navigateTo(-1);
                } else
                    navigateTo("/riskassessments");
            })
            .catch((error: any) => {
                console.log(error.message);

                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 6000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsDeleteDraftOpen(false);
            });
    };

    const renderDeleteAttachmentModal = () => {
        return <Modal
            size="lg"
            isOpen={deleteIsOpen.open}
            onClose={() => { setDeleteIsOpen({ id: "", open: false }) }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <HStack>
                        <Text fontWeight={700}>Delete Attachment</Text>
                    </HStack>
                    <ModalCloseButton />
                </ModalHeader>

                <ModalBody>
                    <Text fontWeight={500} color="gray.600" fontSize="sm">
                        Are you sure you want to delete this attachment?
                    </Text>
                </ModalBody>

                <ModalFooter p={5}>
                    <HStack>
                        <Button
                            size="sm"
                            _hover={{ bg: "" }}
                            onClick={() => {
                                setDeleteIsOpen({ id: deleteIsOpen.id, open: false });
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            bg="danger.500"
                            color="white"
                            size="sm"
                            _hover={{ bg: "" }}
                            onClick={() => {
                                deleteRiskAssessmentAttachment(deleteIsOpen.id);
                            }}
                        >
                            <Icon as={BsTrash} mr={2} />
                            Delete
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    };

    const renderDeleteHazardAttachmentModal = () => {
        return <Modal
            size="lg"
            isOpen={deleteHazardIsOpen?.open}
            onClose={() => { setDeleteHazardIsOpen({ id: "", open: false }) }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <HStack>
                        <Text fontWeight={700}>Delete Attachment</Text>
                    </HStack>
                    <ModalCloseButton />
                </ModalHeader>

                <ModalBody>
                    <Text fontWeight={500} color="gray.600" fontSize="sm">
                        Are you sure you want to delete this attachment?
                    </Text>
                </ModalBody>

                <ModalFooter p={5}>
                    <HStack>
                        <Button
                            size="sm"
                            _hover={{ bg: "" }}
                            onClick={() => {
                                setDeleteHazardIsOpen({ id: "", open: false });
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            bg="danger.500"
                            color="white"
                            size="sm"
                            _hover={{ bg: "" }}
                            onClick={() => {
                                deleteAttachmentForAddHazardModal(deleteHazardIsOpen?.id);
                                setDeleteHazardIsOpen({ id: "", open: false });
                            }}
                        >
                            <Icon as={BsTrash} mr={2} />
                            Delete
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    };

    const renderPdf = () => {
        return <Modal
            size="4xl"
            isOpen={pdfIsOpen}
            onClose={() => {
                setPdfIsOpen(false);
                resetDocViewer();
            }}
        >
            <ModalOverlay />

            <ModalContent mt={0}>
                <ModalHeader
                    style={{
                        height: "50px",
                        borderRadius: "5px 5px 0 0",
                        background: "#38a169",
                        color: "white",
                    }}
                >
                    <HStack gap={1} paddingRight={10}>
                        <Text wordBreak="break-word" fontSize="lg" maxW="50%">{currentCert.fileName}</Text>
                        <Spacer />

                        <Button
                            bg="white"
                            color="brand.500"
                            float="left"
                            size="sm"
                            disabled={pageNumber === 1}
                            onClick={() => setPageNumber(pageNumber - 1)}
                        >
                            Back
                        </Button>
                        <Text fontSize="md">
                            {pageNumber} of {numPages}
                        </Text>
                        <Button
                            bg="white"
                            color="brand.500"
                            float="right"
                            size="sm"
                            disabled={pageNumber === numPages}
                            onClick={() => setPageNumber(pageNumber + 1)}
                        >
                            Next
                        </Button>

                        <ModalCloseButton
                            color="white"
                            _hover={{ bg: "" }}
                            _active={{ bg: "" }}
                        />
                    </HStack>
                </ModalHeader>

                <ModalBody>
                    <Document
                        file={currentCert.uri}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                            <Center my={48}>
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    color="brand.500"
                                    size="xl"
                                />
                            </Center>
                        }
                    >
                        <Page
                            scale={2}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            pageNumber={pageNumber}
                        />
                    </Document>
                </ModalBody>
            </ModalContent>
        </Modal>
    };

    const renderImage = () => {
        return <Modal
            size="4xl"
            isOpen={isAttachmentOpen}
            onClose={() => {
                setCurrentImage("");
                setIsAttachmentOpen(false)
            }}
        >
            <ModalOverlay />

            <ModalContent mt={0}>
                <ModalHeader />

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <Image
                        src={currentImage}
                        m="5px auto 15px auto"
                        p={0}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    };

    const deleteRiskAssessmentAttachment = (attachmentGuid: string) => {
        deleteAttachment(riskAssessment?.raid, riskAssessment?.currentversion?.ravid, attachmentGuid)
            .then(() => {
                let updatedAttachments = attachments;
                updatedAttachments = updatedAttachments.filter(attachment => attachment.path !== attachmentGuid);

                setAttachments(updatedAttachments);
            })
            .catch((error: any) => {
                toast({
                    title: error.message,
                    description: "",
                    status: "error",
                    duration: 6000,
                    isClosable: true
                });
            })
            .finally(() => setDeleteIsOpen({ id: "", open: false }));
    };

    const deleteAttachmentForAddHazardModal = (filename: string) => {
        let updatedAttachments = hazardAttachments;
        updatedAttachments = updatedAttachments.filter(attachment => attachment.fileName !== filename);

        setHazardAttachments(updatedAttachments);
    };

    const fetchAttachment = async (
        id: string,
        version: string,
        attachment: string,
        isPdf: boolean,
        isDoc: boolean,
        pdfExists: boolean
    ) => {
        const pdf = isPdf || isDoc;

        if (!pdfExists && pdf) {
            alert("This attachment is currently being converted. Please try again later.");
            return;
        }

        await getAttachment(id, version, attachment, pdf)
            .then((res: any) => {
                if (pdf) {
                    setCurrentCert({
                        id: Number(id),
                        isPdf: isPdf,
                        fileName: res?.attachment.aafilename,
                        uri: res?.datauri
                    });
                    setPdfIsOpen(true);
                } else {
                    setCurrentImage(res?.datauri);
                    setIsAttachmentOpen(true);
                }
            })
            .catch((error: any) => {
                console.log(error);

                toast({
                    title: "Could not fetch attachment",
                    description: "",
                    status: "error",
                    duration: 6000,
                    isClosable: true
                });
            });
    };

    const onDropAction = async (file: any) => {
        const fileExtension = await fileTypeFromBlob(file);

        const supportedFileTypes = ["pdf", "docx", "png", "jpg", "jpeg"];

        if (!supportedFileTypes.includes(fileExtension?.ext ?? "")) {
            toast({
                title: "Unsupported attachment type",
                description: "",
                status: "error",
                duration: 6000,
                isClosable: true
            });

            return;
        }

        setFileUploadLoading(true);

        let formData = new FormData();
        formData.append("id", riskAssessment?.raid);
        formData.append("version", riskAssessment?.currentversion?.ravid);
        formData.append("file", file);

        addAttachment(formData)
            .then((res: any) => {
                const thumbnail = `${process.env.REACT_APP_OBBI_API_URL}/riskAssessments/attachment?id=${riskAssessment?.raid}&version=${riskAssessment?.currentversion?.ravid}&attachment=${res?.attachment.raapath}&datauri=false&thumbnail=true`;
                const attachment = {
                    "thumbnail": thumbnail,
                    "fileName": res?.attachment.raafilename,
                    "path": res?.attachment.raapath,
                    "isPdf": res?.attachment.ispdf || false,
                    "isDoc": res?.attachment.isdoc || false,
                    "riskAssessmentId": riskAssessment?.raid,
                    "pdfExists": res?.attachment.pdfExists || false
                };
                setAttachments(attachments => [...attachments, attachment]);
            })
            .catch((error) => {
                toast({
                    title: error.message,
                    description: "",
                    status: "error",
                    duration: 6000,
                    isClosable: true
                });
            })
            .finally(() => {
                setFileUploadLoading(false)
                setAddAttachmentModalIsOpen(false);
            }
            );
    };

    const renderAddAttachmentModal = () => {
        return <Modal
            size="lg"
            isOpen={addAttachmentModalIsOpen}
            onClose={() => {

                //remove unsaved attachments from the list
                if (attachments?.length > 0 && attachments?.some((a: any) => a.path === undefined)) {
                    const updatedAttachments = attachments?.filter((a: any) => a.path !== undefined);
                    setHazardAttachments(updatedAttachments);
                }

                setAddAttachmentModalIsOpen(false);
            }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    {attachments && attachments?.length > 0 && attachments?.some((a) => a.path != undefined) ? "Update Attachments" : attachments?.filter((a) => a.path === undefined)?.length > 1 ? "Add Attachments" : "Add Attachment"}
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody mb={5}>
                    <DropZone
                        onDropAction={onDropAction}
                        fileUploadLoading={fileUploadLoading}
                        additionalAcceptedFileTypes={['DOCX']}
                    />
                    {
                        attachments?.length > 0 &&
                        <VStack alignItems="start" mt={5}>
                            <FormControl>
                                <SlideFade in={!loading}
                                    offsetY="20px"
                                >
                                    <FormLabel
                                        fontWeight={500}
                                        fontSize="sm"
                                        color="gray.500"
                                    >
                                        <HStack>
                                            <Icon as={BsPaperclip} fontSize="md" />
                                            <Text>Attachments</Text>
                                        </HStack>
                                    </FormLabel>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        flexWrap={'wrap'}
                                        justifyContent={'flex-start'}
                                        alignItems={'center'}
                                        gap={3}
                                    >
                                        {
                                            attachments?.map((a: any, index: any) => {
                                                return (
                                                    <>
                                                        {
                                                            (a.isPdf || a.isDoc) ?
                                                                <>
                                                                    {
                                                                        a.path ?
                                                                            <Box minW="100px" key={index}>
                                                                                <Image
                                                                                    className="hover-pop"
                                                                                    cursor="pointer"
                                                                                    src={a.thumbnail}
                                                                                    fallbackSrc='https://via.placeholder.com/160'
                                                                                    alt={a.fileName}
                                                                                    boxSize='100px'
                                                                                    boxShadow="xs"
                                                                                    objectFit="cover"
                                                                                    border="1px solid"
                                                                                    borderColor="gray.200"
                                                                                    rounded="lg"
                                                                                    onClick={() => {

                                                                                        if (!a.pdfExists) {
                                                                                            alert("This attachment is currently being converted. Please try again later.");
                                                                                            return;
                                                                                        }

                                                                                        getAttachment(riskAssessment?.raid, riskAssessment?.currentversion.ravid, a.path, (a.isPdf || a.isDoc))
                                                                                            .then((res: any) => {
                                                                                                setCurrentCert({
                                                                                                    id: Number(a.hazardId),
                                                                                                    isPdf: a.isPdf,
                                                                                                    fileName: a.fileName,
                                                                                                    uri: res?.datauri
                                                                                                });
                                                                                                setPdfIsOpen(true);
                                                                                            }
                                                                                            );
                                                                                    }}
                                                                                />

                                                                                <Button
                                                                                    className="hover-pop"
                                                                                    variant="link"
                                                                                    size="xs"
                                                                                    color="gray.500"
                                                                                    _hover={{ color: "" }}
                                                                                    onClick={() => {
                                                                                        setDeleteIsOpen({ id: a.path, open: true });
                                                                                    }}
                                                                                >
                                                                                    <Text textDecoration="underline">Delete</Text>
                                                                                </Button>
                                                                            </Box>
                                                                            :
                                                                            <Box minW="100px" key={index}>
                                                                                <Box minW="100px"
                                                                                    boxSize='100px'
                                                                                    boxShadow="xs"
                                                                                    border="1px solid"
                                                                                    borderColor="gray.200"
                                                                                    rounded="lg"
                                                                                    display={'flex'}
                                                                                    flexDirection={'column'}
                                                                                    justifyContent={"center"}
                                                                                    alignItems={"center"}
                                                                                    p={1}
                                                                                >
                                                                                    <Icon as={a.isPdf ? BsFilePdf : BsFileWord} style={{ fontSize: "30px" }} />
                                                                                    <Text
                                                                                        fontSize={'xx-small'}
                                                                                    >{a.fileName}</Text>
                                                                                </Box>
                                                                                <Button
                                                                                    className="hover-pop"
                                                                                    variant="link"
                                                                                    size="xs"
                                                                                    color="gray.500"
                                                                                    _hover={{ color: "" }}
                                                                                    onClick={() => {
                                                                                        setDeleteIsOpen({ id: a.path, open: true });
                                                                                    }}
                                                                                >
                                                                                    <Text textDecoration="underline">Delete</Text>
                                                                                </Button>
                                                                            </Box>
                                                                    }</>
                                                                :
                                                                <Box minW="100px">
                                                                    <Image
                                                                        className="hover-pop"
                                                                        cursor="pointer"
                                                                        src={a.thumbnail}
                                                                        fallbackSrc='https://via.placeholder.com/160'
                                                                        alt={a.fileName}
                                                                        boxSize='100px'
                                                                        boxShadow="xs"
                                                                        objectFit="cover"
                                                                        border="1px solid"
                                                                        borderColor="gray.200"
                                                                        rounded="lg"
                                                                        onClick={() => {
                                                                            if (a.path) {
                                                                                getAttachment(riskAssessment?.raid, riskAssessment?.currentversion.ravid, a.path, (a.isPdf || a.isDoc))
                                                                                    .then((res: any) => {
                                                                                        setCurrentImage(res?.datauri);
                                                                                        setIsAttachmentOpen(true);
                                                                                    }
                                                                                    );
                                                                            } else {
                                                                                setCurrentImage(a.thumbnail);
                                                                                setIsAttachmentOpen(true);
                                                                            }
                                                                        }}
                                                                    />

                                                                    <Button
                                                                        className="hover-pop"
                                                                        variant="link"
                                                                        size="xs"
                                                                        color="gray.500"
                                                                        _hover={{ color: "" }}
                                                                        onClick={() => {
                                                                            setDeleteIsOpen({ id: a.path, open: true });
                                                                        }}
                                                                    >
                                                                        <Text textDecoration="underline">Delete</Text>
                                                                    </Button>
                                                                </Box>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Box>
                                </SlideFade>
                            </FormControl>
                        </VStack>
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    };
    //#endregion


    //#region Helper Functions
    const renderRiskMatrix = (isNewRisk: boolean = false, onClose: any) => {
        const matrixData = [
            // ROW 1
            { type: "sidebar", text: "Trivial", number: 1 },
            { number: 1, riskText: "Trivial / Rare" },
            { number: 2, riskText: "Trivial / Unlikely" },
            { number: 3, riskText: "Trivial / Possible" },
            { number: 4, riskText: "Trivial / Likely" },
            { number: 5, riskText: "Trivial / Frequent" },

            // ROW 2
            { type: "sidebar", text: "Minor", number: 2 },
            { number: 2, riskText: "Minor / Rare" },
            { number: 4, riskText: "Minor / Unlikely" },
            { number: 6, riskText: "Minor / Possible" },
            { number: 8, riskText: "Minor / Likely" },
            { number: 10, riskText: "Minor / Frequent" },

            // ROW 3
            { type: "sidebar", text: "Moderate", number: 3 },
            { number: 3, riskText: "Moderate / Rare" },
            { number: 6, riskText: "Moderate / Unlikely" },
            { number: 9, riskText: "Moderate / Possible" },
            { number: 12, riskText: "Moderate / Likely" },
            { number: 15, riskText: "Moderate / Frequent" },

            // ROW 4
            { type: "sidebar", text: "Serious", number: 4 },
            { number: 4, riskText: "Serious / Rare" },
            { number: 8, riskText: "Serious / Unlikely" },
            { number: 12, riskText: "Serious / Possible" },
            { number: 16, riskText: "Serious / Likely" },
            { number: 20, riskText: "Serious / Frequent" },

            // ROW 5
            { type: "sidebar", text: "Fatal", number: 5, icon: <Icon as={IoSkullOutline} color="gray.600" /> },
            { number: 5, riskText: "Fatal / Rare" },
            { number: 10, riskText: "Fatal / Unlikely" },
            { number: 15, riskText: "Fatal / Possible" },
            { number: 20, riskText: "Fatal / Likely" },
            { number: 25, riskText: "Fatal / Frequent" },

            // FOOTER ROW 
            { type: "sidebar" },
            { type: "footer", text: "Rare", number: 1 },
            { type: "footer", text: "Unlikely", number: 2 },
            { type: "footer", text: "Possible", number: 3 },
            { type: "footer", text: "Likely", number: 4 },
            { type: "footer", text: "Frequent", number: 5 },
        ];

        return <SimpleGrid columns={6}>
            {
                matrixData.map((data: any, index: any) => {
                    if (data.type === "footer") {
                        return <Box
                            key={index}
                            pt={2}
                            textAlign="center"
                            alignSelf="center"
                        >
                            <Text
                                color="gray.600"
                                fontSize={{ base: "8pt", md: "xs" }}
                                fontWeight={500}
                                alignSelf="center"
                                textAlign="center"
                            >
                                {data.text}
                            </Text>

                            <Text
                                color="gray.600"
                                fontSize="xs"
                                fontWeight={500}
                            >
                                {data.number && data.number}
                            </Text>
                        </Box>
                    } else if (data.type === "sidebar") {
                        return <Box
                            key={index}
                            textAlign="center"
                            alignSelf="center"
                        >
                            <Text
                                color="gray.600"
                                fontSize={{ base: "8pt", md: "xs" }}
                                fontWeight={500}
                            >
                                {data.text} {data.icon}
                            </Text>

                            <Text
                                color="gray.600"
                                fontSize="xs"
                                fontWeight={500}
                            >
                                {data.number}
                            </Text>
                        </Box>
                    } else
                        return <Center
                            key={index}
                            cursor="pointer"
                            rounded="md"
                            boxShadow="sm"
                            boxSize={{ base: "50px", md: "65px" }}
                            m="1.5px"
                            px={{ base: 5, md: 7 }}
                            bg={data.bgColor || getBgColor(Number(data.number))}
                            _hover={{
                                bg: getHoverColor(Number(data.number))
                            }}
                            onClick={() => {
                                if (isNewRisk) {
                                    setNewHazard({
                                        ...newHazard,
                                        newrisk: { riskText: data.riskText, score: data.number }
                                    });
                                } else
                                    setNewHazard({
                                        ...newHazard,
                                        risk: { riskText: data.riskText, score: data.number }
                                    });

                                onClose();
                            }}
                        >
                            <Text
                                color="white"
                                fontWeight={700}
                            >
                                {data.number}
                            </Text>
                        </Center>
                })
            }
        </SimpleGrid>
    };

    const getEditingTeams = () => {
        getCurrentTeams()
            .then((res: any) => {

                var teams = res?.manages;

                for (const team of res?.manages) {
                    teams = teams.concat(team?.childteams);
                }

                setEditingTeams(teams);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const selectEditingTeam = (e: any) => {
        setEditingTeam({
            teamid: e.teamid,
            teamname: e.teamname
        });

        if (riskAssessmentId) {
            postEditingTeam({
                id: riskAssessment?.raid,
                editingTeam: Number(e.teamid)
            })
                .then((res: any) => {
                    setRiskAssessment(res?.riskAssessment);
                })
                .catch((error: any) => {
                    console.log(error);

                    toast({
                        title: "Error",
                        description: error.message,
                        status: "error",
                        duration: 6000,
                        isClosable: true
                    });
                });
        } else {
            setCreateBody({
                ...createBody,
                editingTeam: {
                    teamid: e.teamid,
                    teamname: e.teamname
                }
            });
        }
    };

    const getTeamsToAcknowledge = () => {
        getCurrentTeams()
            .then((res: any) => {
                let updatedTeams = res?.manages;

                for (const team of res?.manages) {
                    updatedTeams = updatedTeams.concat(team?.childteams);
                }

                setActionTeams(updatedTeams);

                for (const team of selectedAcknowledgementTeams) {
                    updatedTeams = _.filter(
                        updatedTeams,
                        (t: any) => {
                            return t?.teamid !== team?.teamid
                        }
                    );
                }

                setAcknowledgementTeams(updatedTeams);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const selectTeam = (arg: any): void => {
        if (arg.teamid === "0") return;

        let currentSelection = [...selectedAcknowledgementTeams];
        currentSelection.push(arg);

        let updatedTeams = _.filter(
            acknowledgementTeams,
            (team: any) => {
                return arg.teamid !== team.teamid;
            }
        );

        if (riskAssessmentId) {
            putRiskAssessmentFromData(currentSelection);
        }

        setAcknowledgementTeams(updatedTeams);
        setSelectedAcknowledgementTeams(currentSelection);
    };

    const selectTeamNoUpdate = (arg: any): void => {
        if (arg.teamid === "0") return;

        let currentSelection = [...selectedAcknowledgementTeams];
        currentSelection.push(arg);

        let updatedTeams = _.filter(
            acknowledgementTeams,
            (team: any) => {
                return arg.teamid !== team.teamid;
            }
        );

        setAcknowledgementTeams(updatedTeams);
        setSelectedAcknowledgementTeams(currentSelection);
    };

    const postRiskAssessmentTeams = () => {
        const teams = [...selectedAcknowledgementTeams]?.map((t: any) => { return t.teamid });
        const postBody = {
            id: riskAssessment?.raid,
            teams: teams || riskAssessment?.teams
        };

        updateRiskAssessmentTeams(postBody)
            .catch((error: any) => {
                console.log(error);

                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 6000,
                    isClosable: true
                });
            });
    };

    const deselectTeam = (id: string): void => {
        let currentSelection = [...selectedAcknowledgementTeams];

        const deselectedTeam =
            _.filter(
                currentSelection,
                (team: any) => {
                    return id === team.teamid;
                }
            );

        currentSelection = _.filter(
            currentSelection,
            (team: any) => {
                return !deselectedTeam.includes(team)
            }
        );

        let updatedTeams = [...acknowledgementTeams];
        updatedTeams = updatedTeams.concat(deselectedTeam);
        updatedTeams = _.orderBy(updatedTeams, [team => team?.teamname?.toLowerCase()], ['asc']);

        putRiskAssessmentFromData(currentSelection);

        setAcknowledgementTeams(updatedTeams);
        setSelectedAcknowledgementTeams(currentSelection);
    };

    const getBgColor = (risk: any) => (
        risk > 0 && risk < 5 ? "green.400" :
            risk > 4 && risk < 11 ? "yellow.300" :
                risk > 10 && risk < 16 ? "orange.300" :
                    risk > 15 && risk < 21 ? "red.400" :
                        "gray.600"
    );

    const getColor = (risk: any) => (
        risk > 0 && risk < 5 ? "#37a169" :
            risk > 4 && risk < 11 ? "#D69E2E" :
                risk > 10 && risk < 16 ? "#ED8936" :
                    risk > 15 && risk < 21 ? "#E53E3E" :
                        "#1A202C"
    );

    const getHoverColor = (risk: any) => (
        risk > 0 && risk < 5 ? "green.500" :
            risk > 4 && risk < 11 ? "yellow.400" :
                risk > 10 && risk < 16 ? "orange.400" :
                    risk > 15 && risk < 21 ? "red.500" :
                        "gray.700"
    );
    //#endregion

    const updateDescription = (e: any) => {
        if (riskAssessmentId) {
            setDescription(e.target.value);

            descriptionDebounce();
        } else {
            setCreateBody({
                ...createBody,
                description: e.target.value
            });
        }
    };

    const descriptionDebounce = useDebounce(() => {
        patchRiskAssessmentFromData("description", description);
    }, 300);

    const updateReviewNotes = (e: any) => {
        if (riskAssessmentId) {
            setReviewNotes(e.target.value);

            reviewNotesDebounce();
        } else {
            setCreateBody({
                ...createBody,
                notes: e.target.value
            });
        }
    };

    const reviewNotesDebounce = useDebounce(() => {
        patchVersionRiskAssessment({
            id: riskAssessment?.raid,
            version: riskAssessment?.currentversion?.ravid,
            name: "reviewnotes",
            value: reviewNotes
        })
            .then((res: any) => {
                setRiskAssessment(
                    {
                        ...riskAssessment,
                        currentversion: res?.version
                    }
                );
            })
            .catch((error: any) => {
                console.log(error.message);
            });
    }, 300);

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    };

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc =
            `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    useEffect(() => {
        // Is editing
        if (riskAssessmentId) {
            setRiskAssessmentHasBeenCreated(true);

            getRiskAssessment(Number(riskAssessmentId))
                .then((res: any) => {
                    // User has navigated back to a published Risk Assessment
                    if (isNull(res?.riskAssessment?.racurrentversion)) {
                        navigateTo(`/riskassessments/version/${res?.riskAssessment?.rapublishedversion}`,
                            {
                                state: {
                                    raid: res?.riskAssessment?.raid,
                                    ravid: res?.riskAssessment?.rapublishedversion
                                }
                            }
                        );
                    } else {
                        setRiskAssessment(res?.riskAssessment);
                        setHazards(res?.riskAssessment?.currentversion?.hazards);
                        setSelectedAcknowledgementTeams(res?.riskAssessment?.teams);
                        setEditingTeam({
                            teamid: res?.riskAssessment?.raeditingteam,
                            teamname: res?.riskAssessment?.editingteamname
                        });

                        const reviewDate = dayjs(res?.riskAssessment?.raexpirydate).format("YYYY-MM-DD");
                        if (reviewDate !== "Invalid Date") {
                            setDate(reviewDate);
                        }

                        if (!isUndefined(res?.riskAssessment?.currentversion?.attachments) &&
                            res?.riskAssessment?.currentversion?.attachments.length > 0) {

                            const attachmentThumbnails: any[] = [];

                            for (let attachment of res?.riskAssessment?.currentversion?.attachments) {
                                const thumbnail = `${process.env.REACT_APP_OBBI_API_URL}/riskAssessments/attachment?id=${riskAssessmentId}&version=${res?.riskAssessment?.currentversion?.ravid}&attachment=${attachment?.raapath}&datauri=false&thumbnail=true`;
                                attachmentThumbnails.push({
                                    "thumbnail": thumbnail,
                                    "fileName": attachment?.raafilename,
                                    "path": attachment?.raapath,
                                    "isPdf": attachment?.ispdf,
                                    "isDoc": attachment?.isdoc,
                                    "pdfExists": attachment?.pdfExists
                                });
                            }
                            setAttachments(attachmentThumbnails);
                        }

                        setLoading(false);
                    }
                })
                .catch((error: any) => {
                    console.log(error);

                    toast({
                        title: "Error",
                        description: error.message,
                        status: "error",
                        duration: 6000,
                        isClosable: true
                    });

                    setLoading(false);
                });
        } else {
            const reviewOneYearFromNow = dayjs(dayjs().add(dayjs.duration({ 'years': 1 }))).format("YYYY-MM-DD");
            setDate(reviewOneYearFromNow);

            setLoading(false);
        }

        getEditingTeams();
        getTeamsToAcknowledge();

    }, [currentStep, state]);

    const validateHazards = () => {
        if (!Array.isArray(hazards)) {
            return true;
        }

        return hazards?.every(hazard => !isEmpty(hazard?.rahnewrisklevel));
    };

    return (
        <Box m={{ base: 0, md: 5 }}>
            <Box
                h={{ base: "full", md: "unset" }}
                pt={2}
                px={{ base: 0, md: 5 }}
                bg="white"
                rounded={{ base: "none", md: "lg" }}
                boxShadow={{ base: "none", md: "lg" }}
            >
                <HStack p={5} pt={3}>
                    <HStack>
                        <Icon
                            mr={3}
                            as={BsExclamationCircle}
                            verticalAlign="middle"
                            fontSize="4xl"
                            color="green.500"
                        />

                        <Text
                            fontWeight={{ base: 600, lg: 700 }}
                            fontSize={{ base: "xl", lg: "2xl" }}
                            textAlign="left"
                            color="gray.700"
                        >
                            {
                                (isEmpty(createBody.title) && isEmpty(riskAssessment?.raname)) ?
                                    "Create a Risk Assessment" : createBody.title || riskAssessment?.raname
                            }
                        </Text>
                    </HStack>

                    <Spacer />

                    <HStack>
                        {currentStep === 1 &&
                            <Button
                                onClick={() => setAddAttachmentModalIsOpen(true)}
                                variant="outline"
                                size="sm"
                                fontWeight={500}
                            >
                                <HStack>
                                    <Icon as={BsPaperclip} />
                                    {!isMobile && <Text>Upload Attachment</Text>}
                                </HStack>
                            </Button>
                        }
                        {(currentStep > 0 && riskAssessment?.publishedversion != null)
                            &&
                            <Button
                                variant="outline"
                                size="sm"
                                color="red.500"
                                borderColor="red.100"
                                fontWeight={500}
                                onClick={() => { setIsDeleteDraftOpen(true); }}
                            >
                                <HStack>
                                    <Icon as={BsTrash} />
                                    {!isMobile && <Text>Delete this Draft</Text>}
                                </HStack>
                            </Button>
                        }
                    </HStack>
                </HStack>

                <Divider w="unset" mx={{ base: 0, md: -5 }} />

                {
                    isNull(riskAssessment?.publishedversion) && !state?.isReview && <>
                        <Flex
                            my={{ base: 2, md: 4 }}
                            pt={{ base: 5, md: 0 }}
                            px={{ base: 5, md: 0 }}
                        >
                            {
                                steps.map((step, id) => (
                                    <Step
                                        key={id}
                                        cursor="pointer"
                                        title={step.title}
                                        description={step.description}
                                        isActive={currentStep === id}
                                        isCompleted={currentStep > id}
                                        isFirstStep={id === 0}
                                        isLastStep={steps.length === id + 1}
                                        onClick={() => {
                                            if (id === 2) {
                                                //validate all hazards have a residual risk
                                                let newRiskLevelSet = true;

                                                for (let hazard of hazards) {
                                                    if (isEmpty(hazard?.rahnewrisklevel)) {
                                                        newRiskLevelSet = false;
                                                    }
                                                }

                                                if (!newRiskLevelSet) {

                                                    setNewRiskLevelError(true);

                                                    toast({
                                                        title: "Error",
                                                        description: "Please ensure all hazards have a risk level after controls",
                                                        status: "error",
                                                        duration: 5000,
                                                        isClosable: true
                                                    });

                                                    return;
                                                }
                                            }

                                            if (riskAssessmentHasBeenCreated || riskAssessmentId)
                                                setStep(id);
                                        }}
                                    />
                                ))
                            }
                        </Flex>

                        <Divider w="unset" mx={{ base: 0, md: -5 }} />
                    </>
                }

                <Box
                    m={isMobile ? 3 : 5}
                    mb={currentStep === 3 ? 0 : 8}
                >
                    {
                        {
                            0:
                                <VStack alignItems="start" gap={2}>
                                    <Text
                                        fontWeight={{ base: 600, lg: 700 }}
                                        fontSize={isMobile ? "lg" : "xl"}
                                        textAlign="left"
                                        color="gray.700"
                                    >
                                        Risk Assessment Details
                                    </Text>

                                    <FormControl
                                        isRequired
                                        isInvalid={createEditTeamError}
                                        w={{ base: "full", md: "50%" }}
                                    >
                                        <Box
                                            border="1px solid"
                                            borderColor="gray.200"
                                            rounded="lg"
                                            p={3}
                                        >
                                            <HStack mb={2}>
                                                <VStack alignItems="start">
                                                    <FormLabel
                                                        fontWeight={500}
                                                        fontSize="sm"
                                                        color="gray.600"
                                                    >
                                                        Managing Team
                                                    </FormLabel>

                                                    <Text
                                                        fontWeight={500}
                                                        fontSize="xs"
                                                        color="gray.500"
                                                    >
                                                        Members of this team are able to make changes to this Risk Assessment prior to publishing.
                                                    </Text>
                                                </VStack>

                                                <Spacer />
                                                <Image src={questionGreen} h="60px" />
                                            </HStack>

                                            <EditingTeamCombobox
                                                teams={editingTeams}
                                                riskAssessmentData={riskAssessment}
                                                editingTeam={editingTeam}
                                                update={selectEditingTeam}
                                            />
                                        </Box>

                                        {createEditTeamError && <FormErrorMessage>Please select a team to manage this risk assessment</FormErrorMessage>}
                                    </FormControl>

                                    {
                                        !state?.isReview && <SlideFade in={!loading} style={{ width: "100%" }}>
                                            <VStack alignItems="start" gap={3}>
                                                <FormControl
                                                    isRequired
                                                    isInvalid={createTitleError}
                                                    w={{ base: "full", md: "50%" }}
                                                >
                                                    <FormLabel
                                                        fontWeight={500}
                                                        fontSize="sm"
                                                        color="gray.600"
                                                    >
                                                        Risk Assessment Name
                                                    </FormLabel>

                                                    <Input
                                                        defaultValue={riskAssessment?.raname || ""}
                                                        onChange={(e) => {
                                                            if (riskAssessmentId) {
                                                                patchRiskAssessmentFromData("name", e.target.value);
                                                            } else {
                                                                setCreateBody({
                                                                    ...createBody,
                                                                    title: e.target.value
                                                                });
                                                            }
                                                        }}
                                                    />

                                                    {createTitleError && <FormErrorMessage>Please provide a name for this risk assessment</FormErrorMessage>}
                                                </FormControl>

                                                <FormControl
                                                    isRequired
                                                    isInvalid={createCategoryError}
                                                    w={{ base: "full", md: "50%" }}
                                                >
                                                    <FormLabel
                                                        fontWeight={500}
                                                        fontSize="sm"
                                                        color="gray.600"
                                                    >
                                                        Category
                                                    </FormLabel>

                                                    <Input
                                                        defaultValue={riskAssessment?.racategory || ""}
                                                        onChange={(e) => {
                                                            if (riskAssessmentId) {
                                                                patchRiskAssessmentFromData("category", e.target.value);
                                                            } else {
                                                                setCreateBody({
                                                                    ...createBody,
                                                                    category: e.target.value
                                                                });
                                                            }
                                                        }}
                                                    />

                                                    {createCategoryError && <FormErrorMessage>Please provide a category for this risk assessment</FormErrorMessage>}
                                                </FormControl>

                                                <FormControl
                                                    isRequired
                                                    isInvalid={createDescriptionError}
                                                    w={{ base: "full", md: "50%" }}
                                                >
                                                    <FormLabel
                                                        fontWeight={500}
                                                        fontSize="sm"
                                                        color="gray.600"
                                                    >
                                                        Description
                                                    </FormLabel>

                                                    <Textarea
                                                        rows={5}
                                                        defaultValue={riskAssessment?.radescription || ""}
                                                        onChange={(e) => {
                                                            updateDescription(e);
                                                        }}
                                                    />

                                                    {createDescriptionError && <FormErrorMessage>Please provide a description for this risk assessment</FormErrorMessage>}
                                                </FormControl>

                                                <FormControl
                                                    isRequired
                                                    isInvalid={createAckTeamsError}
                                                    w={{ base: "full", md: "50%" }}
                                                    p={3}
                                                    border="1px solid"
                                                    borderColor="gray.200"
                                                    rounded="lg"
                                                >
                                                    <VStack alignItems="start">
                                                        <FormLabel
                                                            fontWeight={500}
                                                            fontSize="sm"
                                                            color="gray.600"
                                                        >
                                                            Teams to Acknowledge

                                                            <Badge
                                                                alignSelf="baseline"
                                                                size="sm"
                                                                ml={2}
                                                            >
                                                                {(selectedAcknowledgementTeams?.length).toString()}
                                                            </Badge>
                                                        </FormLabel>

                                                        <Box>
                                                            <Text
                                                                fontWeight={500}
                                                                fontSize="xs"
                                                                color="gray.500"
                                                            >
                                                                The teams you select from the list are required to read and acknowledge this risk assessment.
                                                            </Text>

                                                            <Text
                                                                fontWeight={500}
                                                                fontSize="xs"
                                                                color="gray.500"
                                                            >
                                                                Teams you have responsibilities for appear in this list.
                                                            </Text>
                                                        </Box>

                                                        <Box w="full">
                                                            <TeamMessageCombobox
                                                                teams={acknowledgementTeams}
                                                                update={selectTeam}
                                                                selectedTeam={{}}
                                                            />
                                                        </Box>
                                                    </VStack>

                                                    {
                                                        selectedAcknowledgementTeams?.length > 0 && <Box textAlign="left">
                                                            {
                                                                selectedAcknowledgementTeams?.map((team: any, i: number) => (
                                                                    <Tag
                                                                        key={`team_${i}`}
                                                                        size="md"
                                                                        mr={2}
                                                                        mt={3}
                                                                        borderRadius='lg'
                                                                        variant='solid'
                                                                        colorScheme='green'
                                                                    >
                                                                        <TagLabel>{team.teamname}</TagLabel>
                                                                        <TagCloseButton
                                                                            onClick={() => {
                                                                                deselectTeam(team.teamid)
                                                                            }}
                                                                        />
                                                                    </Tag>
                                                                ))
                                                            }
                                                        </Box>
                                                    }

                                                    {createAckTeamsError && <FormErrorMessage>Please select at least one team to acknowledge this risk assessment</FormErrorMessage>}
                                                </FormControl>
                                            </VStack>
                                        </SlideFade>
                                    }
                                </VStack>,
                            1:
                                <VStack w="100%" alignItems="start" gap={2}>
                                    {
                                        state?.type === "upload" && <>
                                            <Text
                                                fontWeight={{ base: 600, lg: 700 }}
                                                fontSize={isMobile ? "lg" : "xl"}
                                                textAlign="left"
                                                color="gray.700"
                                            >
                                                Upload Risk Assessment
                                            </Text>

                                            <Box w="full" cursor="pointer">
                                                <DropZone
                                                    onDropAction={onDropAction}
                                                    w="full"
                                                    fileUploadLoading={false}
                                                    additionalAcceptedFileTypes={['DOCX']}
                                                />
                                            </Box>
                                        </>
                                    }

                                    <VStack
                                        w="100%"
                                        p={3}
                                        pt={2}
                                        gap={{ base: 2, md: 0 }}
                                        alignItems="baseline"
                                        border="1px solid"
                                        borderColor="gray.200"
                                        rounded="lg"
                                    >
                                        <HStack w="100%" alignItems="flex-start">
                                            <HStack w="100%" alignItems="flex-start">
                                                <VStack w="100%" alignItems="baseline">
                                                    <Text
                                                        fontWeight={{ base: 600, lg: 700 }}
                                                        fontSize={{ base: "lg", md: "xl" }}
                                                        textAlign="left"
                                                        color="gray.700"
                                                    >
                                                        {state?.isReview ? "Review " : ""}  Hazards
                                                    </Text>

                                                    <Text
                                                        fontWeight={500}
                                                        fontSize={{ base: "xs", md: "sm" }}
                                                        textAlign="left"
                                                        color="gray.600"
                                                    >
                                                        {
                                                            state?.isReview ? "Take a moment to carefully review your risk assessment hazards. Ensuring accuracy now can prevent serious incidents later." :
                                                                "This is where you can define hazards, set safety controls and assign follow up actions to manage your risks effectively."
                                                        }
                                                    </Text>
                                                </VStack>

                                                {
                                                    isMobile && <Image
                                                        src={hazardImg}
                                                        p={{ base: 3, md: 3 }}
                                                        h="90px"
                                                    />
                                                }
                                            </HStack>

                                            {
                                                !isMobile && <Image
                                                    src={hazardImg}
                                                    p={{ base: 3, md: 3 }}
                                                    h="95px"
                                                />
                                            }
                                        </HStack>

                                        <Button
                                            w={{ base: "full", md: "unset" }}
                                            style={{ marginTop: isMobile ? 5 : -1 }}
                                            size="sm"
                                            float="right"
                                            bg="brand.500"
                                            color="white"
                                            _hover={{
                                                bg: "#248451"
                                            }}
                                            _active={{
                                                bg: "#248451"
                                            }}
                                            onClick={() => {
                                                //validate all hazards have a residual risk
                                                let newRiskLevelSet = true;

                                                for (let hazard of hazards) {
                                                    if (isEmpty(hazard?.rahnewrisklevel)) {
                                                        newRiskLevelSet = false;
                                                    }
                                                }

                                                if (!newRiskLevelSet) {
                                                    setNewRiskLevelError(true);

                                                    toast({
                                                        title: "Error",
                                                        description: "Please ensure all hazards have a risk level after controls",
                                                        status: "error",
                                                        duration: 5000,
                                                        isClosable: true
                                                    });

                                                } else {
                                                    setIsOpen(true);
                                                }
                                            }}
                                        >
                                            <HStack>
                                                <Icon as={BsPlusCircle} />
                                                <Text>{isEmpty(hazards) ? "Add Hazard" : "Add Another Hazard"}</Text>
                                            </HStack>
                                        </Button>
                                    </VStack>

                                    <Box w="100%">
                                        {
                                            attachments?.length > 0 && <VStack alignItems="start" my={4}>
                                                <FormControl>
                                                    <FormLabel
                                                        fontWeight={{ base: 500, lg: 600 }}
                                                        fontSize={{ base: "md", md: "lg" }}
                                                        textAlign="left"
                                                        color="gray.700"
                                                    >
                                                        <HStack>
                                                            <Icon as={BsPaperclip} fontSize="md" />
                                                            <Text>Attachments</Text>
                                                        </HStack>
                                                    </FormLabel>

                                                    <SlideFade in={!loading} offsetY="20px">
                                                        <Box
                                                            display={'flex'}
                                                            flexDirection={'row'}
                                                            flexWrap={'wrap'}
                                                            justifyContent={'flex-start'}
                                                            alignItems={'center'}
                                                            gap={3}
                                                        >
                                                            {
                                                                attachments?.map((a: any, index: any) => {
                                                                    return (
                                                                        <Box key={index}>
                                                                            <Image
                                                                                className="hover-pop"
                                                                                cursor="pointer"
                                                                                src={a.thumbnail}
                                                                                fallbackSrc='https://via.placeholder.com/160'
                                                                                alt={a.fileName}
                                                                                boxSize='160px'
                                                                                boxShadow="xs"
                                                                                objectFit="cover"
                                                                                border="1px solid"
                                                                                borderColor="gray.200"
                                                                                rounded="lg"
                                                                                onClick={() => {
                                                                                    fetchAttachment(riskAssessment?.raid, riskAssessment?.currentversion?.ravid, a.path, a.isPdf, a.isDoc, a.pdfExists)
                                                                                }}
                                                                            />

                                                                            <Button
                                                                                className="hover-pop"
                                                                                variant="link"
                                                                                size="xs"
                                                                                color="gray.500"
                                                                                _hover={{ color: "" }}
                                                                                onClick={() => {
                                                                                    setDeleteIsOpen({ id: a.path, open: true });
                                                                                }}
                                                                            >
                                                                                <Text textDecoration="underline">Delete</Text>
                                                                            </Button>
                                                                        </Box>
                                                                    )
                                                                })
                                                            }
                                                        </Box>
                                                    </SlideFade>
                                                </FormControl>
                                            </VStack>
                                        }
                                    </Box>

                                    {
                                        !loading && (isEmpty(hazards) ?
                                            <Center my={5} w="full">
                                                <VStack gap={2}>
                                                    <Image src={nothingToSee} h="150px" />

                                                    <Box textAlign="center">
                                                        <Text
                                                            fontSize="lg"
                                                            fontWeight={700}
                                                            mb={2}
                                                        >
                                                            No Hazards
                                                        </Text>

                                                        <Text
                                                            fontSize="sm"
                                                            fontWeight={400}
                                                            color="gray.500"
                                                        >
                                                            No Hazards have been added to this Risk Assessment
                                                        </Text>
                                                    </Box>
                                                </VStack>
                                            </Center> :
                                            <>
                                                <Text
                                                    fontWeight={{ base: 500, lg: 600 }}
                                                    fontSize={{ base: "md", md: "lg" }}
                                                    textAlign="left"
                                                    color="gray.700"
                                                >
                                                    <HStack>
                                                        <Icon as={BsExclamationTriangle} fontSize="md" />
                                                        <Text>Hazards</Text>
                                                    </HStack>
                                                </Text >

                                                <Box w="100%">
                                                    <VStack gap={2}>
                                                        {
                                                            hazards.map((h: any) => {
                                                                return <Hazard
                                                                    key={h.rahid}
                                                                    riskAssessmentId={riskAssessment?.raid}
                                                                    riskAssessmentVersionId={riskAssessment?.currentversion.ravid}
                                                                    teams={actionTeams}
                                                                    renderRiskMatrix={renderRiskMatrix}
                                                                    hazard={h}
                                                                    hazards={hazards}
                                                                    setHazards={setHazards}
                                                                    getBgColor={getBgColor}
                                                                    getHoverColor={getHoverColor}
                                                                    newRiskLevelError={newRiskLevelError}
                                                                    setNewRiskLevelError={setNewRiskLevelError}
                                                                />
                                                            })
                                                        }
                                                    </VStack>

                                                    <Button
                                                        mt={3}
                                                        size="sm"
                                                        colorScheme="brand"
                                                        alignSelf="start"
                                                        leftIcon={<Icon as={BsPlusCircle} />}
                                                        _hover={{ bg: "#248451" }}
                                                        onClick={() => {
                                                            //validate all hazards have a residual risk
                                                            let newRiskLevelSet = true;

                                                            for (let hazard of hazards) {
                                                                if (isEmpty(hazard?.rahnewrisklevel)) {
                                                                    newRiskLevelSet = false;
                                                                }
                                                            }

                                                            if (!newRiskLevelSet) {

                                                                setNewRiskLevelError(true);

                                                                toast({
                                                                    title: "Error",
                                                                    description: "Please ensure all hazards have a risk level after controls",
                                                                    status: "error",
                                                                    duration: 5000,
                                                                    isClosable: true
                                                                });

                                                            } else {
                                                                setIsOpen(true);
                                                            }
                                                        }}
                                                    >
                                                        Add Another Hazard
                                                    </Button>
                                                </Box>
                                            </>
                                        )
                                    }
                                </VStack>,
                            2:
                                <VStack alignItems="start" gap={3}>
                                    <Text
                                        fontWeight={{ base: 600, lg: 700 }}
                                        fontSize={isMobile ? "lg" : "xl"}
                                        textAlign="left"
                                        color="gray.700"
                                    >
                                        Review and Publish
                                    </Text>

                                    <Box
                                        w={{ base: "full", md: "50%" }}
                                        border="1px solid"
                                        borderColor="gray.200"
                                        rounded="lg"
                                        p={3}
                                    >
                                        <FormControl isRequired isInvalid={nextReviewDateError}>
                                            <VStack alignItems="start">
                                                <HStack w="full" alignItems="end">
                                                    <VStack alignItems="start">
                                                        <FormLabel
                                                            fontWeight={500}
                                                            fontSize="md"
                                                            color="gray.600"
                                                        >
                                                            Next Review Date
                                                        </FormLabel>

                                                        <Text
                                                            fontWeight={500}
                                                            fontSize="sm"
                                                            color="gray.500"
                                                        >
                                                            It is recommended that Risk Assessments are reviewed on an annual basis.
                                                        </Text>
                                                        <HStack>
                                                            {
                                                                !isMobile && <Button
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        const sixMonthsFromNow = dayjs(
                                                                            dayjs().add(dayjs.duration({ 'months': 6 }))
                                                                        )
                                                                            .format("YYYY-MM-DD");

                                                                        setDate(sixMonthsFromNow);
                                                                        patchRiskAssessmentFromData("expirydate", sixMonthsFromNow);
                                                                    }}
                                                                >
                                                                    6 Months
                                                                </Button>
                                                            }

                                                            <Button
                                                                size="sm"
                                                                onClick={() => {
                                                                    const oneYearFromNow = dayjs(
                                                                        dayjs().add(dayjs.duration({ 'years': 1 }))
                                                                    )
                                                                        .format("YYYY-MM-DD");

                                                                    setDate(oneYearFromNow);
                                                                    patchRiskAssessmentFromData("expirydate", oneYearFromNow);
                                                                }}
                                                            >
                                                                1 Year
                                                            </Button>

                                                            <Button
                                                                size="sm"
                                                                onClick={() => {
                                                                    const twoYearsFromNow = dayjs(
                                                                        dayjs().add(dayjs.duration({ 'years': 2 }))
                                                                    )
                                                                        .format("YYYY-MM-DD");

                                                                    setDate(twoYearsFromNow);
                                                                    patchRiskAssessmentFromData("expirydate", twoYearsFromNow);
                                                                }}
                                                            >
                                                                2 Years
                                                            </Button>

                                                            <Button
                                                                size="sm"
                                                                onClick={() => {
                                                                    const threeYearsFromNow = dayjs(
                                                                        dayjs().add(dayjs.duration({ 'years': 3 }))
                                                                    )
                                                                        .format("YYYY-MM-DD");

                                                                    setDate(threeYearsFromNow);
                                                                    patchRiskAssessmentFromData("expirydate", threeYearsFromNow);
                                                                }}
                                                            >
                                                                3 Years
                                                            </Button>
                                                        </HStack>
                                                    </VStack>

                                                    {
                                                        !isMobile && <>
                                                            <Spacer />
                                                            <Image src={waiting} h="90px" />
                                                        </>
                                                    }
                                                </HStack>

                                                <Input
                                                    type="date"
                                                    value={date}
                                                    onKeyDown={(e: any) => { e.preventDefault() }}
                                                    onWheel={(e: any) => e.target.blur()}
                                                    onChange={(e: any) => {
                                                        setDate(e.target.value);
                                                        patchRiskAssessmentFromData("expirydate", e.target.value);
                                                    }}
                                                />
                                            </VStack>

                                            {nextReviewDateError && <FormErrorMessage>Please provide the next review date</FormErrorMessage>}
                                        </FormControl>
                                    </Box>

                                    {
                                        !isNull(riskAssessment?.publishedversion) && state?.isReview && <VStack
                                            gap={2}
                                            alignItems="start"
                                            w={{ base: "full", md: "50%" }}
                                            p={3}
                                            border="1px solid"
                                            borderColor="gray.200"
                                            rounded="lg"
                                        >
                                            <FormControl
                                                isRequired
                                                isInvalid={reviewNotesError}
                                            >
                                                <FormLabel
                                                    fontWeight={500}
                                                    fontSize="md"
                                                    color="gray.600"
                                                >
                                                    Publishing Details
                                                </FormLabel>

                                                <Text
                                                    fontWeight={500}
                                                    fontSize="sm"
                                                    color="gray.500"
                                                    mb={2}
                                                >
                                                    Why are you making these changes?
                                                </Text>

                                                <Textarea
                                                    defaultValue={riskAssessment?.currentversion?.ravreviewnotes || ""}
                                                    maxLength={300}
                                                    onChange={(e: any) => {
                                                        updateReviewNotes(e);
                                                    }}
                                                />

                                                {reviewNotesError && <FormErrorMessage>Please provide some review notes</FormErrorMessage>}
                                            </FormControl>

                                            <FormControl
                                                isRequired
                                                isInvalid={createAckTeamsError}
                                            >
                                                <VStack alignItems="start">
                                                    <FormLabel
                                                        fontWeight={500}
                                                        fontSize="md"
                                                        color="gray.600"
                                                    >
                                                        Teams to Acknowledge

                                                        <Badge
                                                            alignSelf="baseline"
                                                            size="sm"
                                                            ml={2}
                                                        >
                                                            {(selectedAcknowledgementTeams?.length).toString()}
                                                        </Badge>
                                                    </FormLabel>

                                                    <Box>
                                                        <Text
                                                            fontWeight={500}
                                                            fontSize="sm"
                                                            color="gray.500"
                                                        >
                                                            The teams selected from the list will be notified to read and acknowledge this Risk Assessment.
                                                        </Text>

                                                        <Text
                                                            fontWeight={500}
                                                            fontSize="sm"
                                                            color="gray.500"
                                                            mt={2}
                                                        >
                                                            Teams you have responsibilities for appear in this list.
                                                        </Text>
                                                    </Box>

                                                    <Box w="full">
                                                        <TeamMessageCombobox
                                                            teams={acknowledgementTeams}
                                                            update={selectTeamNoUpdate}
                                                            selectedTeam={{}}
                                                        />
                                                    </Box>
                                                </VStack>

                                                {
                                                    selectedAcknowledgementTeams?.length > 0 && <Box textAlign="left">
                                                        {
                                                            selectedAcknowledgementTeams?.map((team: any, i: number) => (
                                                                <Tag
                                                                    key={`team_${i}`}
                                                                    size="md"
                                                                    mr={2}
                                                                    mt={3}
                                                                    borderRadius='lg'
                                                                    variant='solid'
                                                                    colorScheme='green'
                                                                >
                                                                    <TagLabel>{team.teamname}</TagLabel>
                                                                    <TagCloseButton
                                                                        onClick={() => {
                                                                            deselectTeam(team.teamid)
                                                                        }}
                                                                    />
                                                                </Tag>
                                                            ))
                                                        }
                                                    </Box>
                                                }

                                                {createAckTeamsError && <FormErrorMessage>Please select at least one team to acknowledge this risk assessment</FormErrorMessage>}
                                            </FormControl>
                                        </VStack>
                                    }
                                </VStack>
                        }[currentStep]
                    }
                </Box>

                <Divider w="unset" mx={{ base: 0, md: -5 }} />

                <Box py={5} px={{ base: 5, md: 0 }}>
                    <ButtonGroup gap={1}>
                        {
                            (currentStep > 0 && !state?.isReview) ||
                                (currentStep === 2 && state?.isReview) ? <Button
                                    leftIcon={<BsArrowLeftCircle />}
                                    variant="outline"
                                    size="sm"
                                    color="gray.500"
                                    fontWeight={600}
                                    onClick={() => {
                                        setStep(currentStep - 1);
                                    }}
                                >
                                <Text>Back</Text>
                            </Button> :

                                <Button
                                    leftIcon={<BsXCircle />}
                                    variant="outline"
                                    size="sm"
                                    color="gray.500"
                                    fontWeight={600}
                                    onClick={() => {
                                        navigateTo("/riskassessments");
                                    }}
                                >
                                    <Text>Cancel</Text>
                                </Button>
                        }

                        {
                            currentStep < 2 ? <Button
                                rightIcon={<BsArrowRightCircle />}
                                colorScheme="brand"
                                size="sm"
                                fontWeight={600}
                                _hover={{
                                    bg: "#248451"
                                }}
                                onClick={() => {
                                    if (!riskAssessmentHasBeenCreated && currentStep === 0) {
                                        createRiskAssessmentFromData();
                                    } else if (currentStep === 1) {
                                        if (!validateHazards()) {
                                            setNewRiskLevelError(true);
                                            toast({
                                                title: "Error",
                                                description: "Please ensure all hazards have a risk level after controls",
                                                status: "error",
                                                duration: 5000,
                                                isClosable: true
                                            });
                                        } else {
                                            setStep(currentStep + 1);
                                        }
                                    } else {
                                        setStep(currentStep + 1);
                                    }
                                }}
                            >
                                <Text>
                                    Next:
                                    {
                                        currentStep === 0 ? " Hazards" :
                                            currentStep === 1 ? " Review and Publish" :
                                                currentStep === 2 && "Publish"
                                    }
                                </Text>
                            </Button> :

                                <Button
                                    leftIcon={<BsCheckCircle />}
                                    colorScheme="brand"
                                    size="sm"
                                    fontWeight={600}
                                    _hover={{
                                        bg: "#248451"
                                    }}
                                    onClick={() => {
                                        let error = false;
                                        setNextReviewDateError(false);

                                        if (isNull(riskAssessment?.raexpirydate) && isNull(date)) {
                                            setNextReviewDateError(true);
                                            error = true;
                                        }

                                        if (state?.isReview &&
                                            !isNull(riskAssessment?.publishedversion) &&
                                            isNull(riskAssessment?.currentversion?.ravreviewnotes)) {
                                            setReviewNotesError(true);
                                            error = true;
                                        }

                                        if (!error) {
                                            setIsPublishOpen(true);
                                        }
                                    }}
                                >
                                    <Text>Publish</Text>
                                </Button>
                        }
                    </ButtonGroup>
                </Box>
            </Box>

            {renderAddHazardModal()}
            {confirmPublishModal()}
            {deleteDraftModal()}
            {renderImage()}
            {renderPdf()}
            {renderDeleteAttachmentModal()}
            {renderDeleteHazardAttachmentModal()}
            {renderAddAttachmentModal()}
        </Box>
    );
};
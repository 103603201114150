import { useEffect, useState } from "react";
import {
	useLocation,
	useNavigate,
	useParams
} from "react-router-dom";
import rehypeRaw from "rehype-raw";
import {
	Box,
	VStack,
	SimpleGrid,
	Text,
	Image,
	Button,
	useToast,
	Icon,
	HStack,
	Avatar,
	SlideFade,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Center,
	Spacer,
	Spinner,
	Divider
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import ReactMarkdown from "react-markdown";
import { BsArrowLeftCircle, BsExclamationCircleFill, BsHandThumbsUp, BsImages } from "react-icons/bs";

import {
	acknowledgeMessage,
	getMessage,
	getMessageAttachment,
	markAsRead
} from "../../features/messages/message.actions";
import { localDateTimeIgnoreToday } from "../../helpers/DayJsHelper";

export const Message: React.FC<any> = () => {
	const { id } = useParams();
	const toast = useToast();
	const { state }: any = useLocation();
	const navigateTo = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [message, setMessage] = useState<any>({});
	const [from, setFrom] = useState("");

	const [attachments, setAttachments] = useState<any[]>([]);
	const [currentImage, setCurrentImage] = useState("");
	const [isOpen, setIsOpen] = useState(false);

	// PDF pages
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const resetDocViewer = () => {
		setNumPages(0);
		setPageNumber(1);
	};

	const [currentPdf, setCurrentPdf] = useState({
		id: 0,
		uri: "",
		fileName: ""
	});
	const [pdfIsOpen, setPdfIsOpen] = useState(false);

	const acknowledge = () => {
		if (id) {
			acknowledgeMessage(parseInt(id))
				.then((message) => {
					setMessage(message);

					toast({
						title: "Message acknowledged.",
						description: "",
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				})
				.catch((error) => {
					console.log(error);

					toast({
						title: "Could not acknowledge this message. Please try again.",
						description: "",
						status: "error",
						duration: 6000,
						isClosable: true,
					});
				});
		}
	};

	const renderImage = () => {
		return <Modal
			size="2xl"
			isOpen={isOpen}
			onClose={() => { setIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader />

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>
				<ModalBody>
					<Image
						src={currentImage}
						m="5px auto 15px auto"
						p={0}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => {
				setPdfIsOpen(false);
				resetDocViewer();
			}}
		>
			<ModalOverlay />

			<ModalContent mt={0}>
				<ModalHeader
					style={{
						height: "50px",
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={1} paddingRight={10}>
						<Text wordBreak="break-word" fontSize="lg" maxW="50%">{currentPdf.fileName}</Text>

						<Spacer />

						<Button
							bg="white"
							color="brand.500"
							float="left"
							size="sm"
							disabled={pageNumber === 1}
							onClick={() => setPageNumber(pageNumber - 1)}
						>
							Back
						</Button>

						<Text fontSize="md">
							{pageNumber} of {numPages}
						</Text>

						<Button
							bg="white"
							color="brand.500"
							float="right"
							size="sm"
							disabled={pageNumber === numPages}
							onClick={() => setPageNumber(pageNumber + 1)}
						>
							Next
						</Button>

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>
					<Document
						file={currentPdf.uri}
						loading={
							<Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							scale={2}
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={pageNumber}
						/>
					</Document>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc =
			`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	});

	useEffect(() => {
		async function initialise() {
			setLoading(true);

			if (id) {
				getMessage(Number(id))
					.then(async (message: any) => {
						setMessage(message);
						setFrom(`${message.creator.udforename} ${message.creator.udsurname}`);

						const attachmentThumbnails: any[] = [];

						for (let attachment of message.attachments) {
							attachmentThumbnails.push({
								id: id,
								thumbnail: `${process.env.REACT_APP_OBBI_API_URL}/messages/attachment?id=${id}&attachment=${attachment.matpath}&thumbnail=true&datauri=false`,
								fileName: attachment.matfilename,
								path: attachment.matpath,
								isPdf: attachment.ispdf || attachment.isdoc
							});
						}
						setAttachments(attachmentThumbnails);
					})
					.catch((error) => {
						console.log(error);
					});

				// if unread only
				markAsRead(parseInt(id))
					.then((message: any) => { })
					.catch((error) => {
						console.log(error);
					});
			}
			setLoading(false);
		}

		initialise();
	}, [id]);

	return (
		<Box m={{ base: 0, md: 5 }}>
			<Button
				hidden={state === null}
				display={{ base: "none", md: "unset" }}
				w="fit-content"
				border="none"
				boxShadow="sm"
				mb={2}
				size="sm"
				color="gray.600"
				variant="outline"
				fontWeight={600}
				onClick={() => {
					navigateTo("/messages",
						{
							state: {
								tabIndex: state?.tabIndex,
								pageIndex: state?.pageIndex,
								pageCount: state?.pageCount,
								filter: state?.filter
							}
						});
				}}
			>
				<HStack alignItems="center">
					<Icon as={BsArrowLeftCircle} />
					<Text>Back to Messages</Text>
				</HStack>
			</Button>

			<Box
				h={{ base: "full", md: "unset" }}
				p={{ base: 3, md: 8 }}
				bg="white"
				rounded={{ base: "none", md: "lg" }}
				boxShadow={{ base: "none", md: "lg" }}
			>
				<Button
					display={{ base: "unset", md: "none" }}
					mt={2}
					mb={6}
					size="sm"
					color="gray.600"
					variant="link"
					fontWeight={600}
					onClick={() => {
						navigateTo("/messages",
							{
								state: {
									tabIndex: state?.tabIndex,
									pageIndex: state?.pageIndex,
									pageCount: state?.pageCount
								}
							});
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircle} />
						<Text>Back to Messages</Text>
					</HStack>
				</Button>

				<SlideFade in={!loading}>
					<SimpleGrid columns={{ base: 1, lg: 2 }}>
						<HStack>
							{
								message.msgkind === "1" && <Icon
									color="red.500"
									fontSize="lg"
									mr={2}
									as={BsExclamationCircleFill}
								/>
							}

							<Text
								fontWeight={600}
								fontSize='xl'
								color="gray.700"
								wordBreak="break-word"
							>
								{message.msgsubject}
							</Text>
						</HStack>

						<Box
							justifyContent="end"
							display={{ base: "none", lg: 'flex' }}
						>
							{
								state !== null && <Text
									float="right"
									color="gray.500"
									textAlign="left"
									fontSize="sm"
								>
									From <Avatar
										name={from}
										size="xs"
										color="white"
									/> {from}, {localDateTimeIgnoreToday(message.mudatesent)}
								</Text>
							}
						</Box>
					</SimpleGrid>
				</SlideFade>

				<VStack
					display='block'
					alignItems='baseline'
				>
					<Box display={{ lg: "none" }}>
						{
							state !== null && <SlideFade in={!loading} offsetY="20px">
								<VStack alignItems="start" mt={2}>
									<HStack gap={2}>
										<HStack>
											<Avatar
												size="xs"
												color="white"
												name={from}
											/>

											<Text
												fontSize="xs"
												color="gray.700"
												fontWeight={500}
											>
												{from}
											</Text>
										</HStack>

										<Text fontSize="xs" color="gray.500">
											{localDateTimeIgnoreToday(message.mudatesent)}
										</Text>
									</HStack>
								</VStack>
							</SlideFade>
						}
					</Box>

					<Box py={3}>
						<Divider />
					</Box>

					{
						<SlideFade in={!loading} offsetY="20px">
							<VStack
								textAlign="left"
								display='flex'
								alignItems='baseline'
								spacing={8}
							>
								<Text
									fontWeight={500}
									fontSize="md"
									color="gray.600"
									wordBreak="break-word"
									whiteSpace="pre-line"
								>
									<ReactMarkdown children={message.msgbody} rehypePlugins={[rehypeRaw] as any} />
								</Text>

								{
									attachments && attachments.length > 0 && <VStack
										textAlign="left"
										display="flex"
										alignItems="baseline"
									>
										<HStack>
											<Icon
												as={BsImages}
												fontSize="xs"
												color="gray.500"
											/>
											<Text
												fontWeight={500}
												fontSize="sm"
												color="gray.500"
											>
												Attachments
											</Text>
										</HStack>

										<Grid
											templateColumns={{ base: 'repeat(5, 1fr)', md: 'repeat(5, 5fr)' }}
											alignItems="start"
											gap={2}
											mt={3}
										>
											{
												attachments.map((a: any) => {
													return (
														<Box minW="170px">
															<Image
																className="hover-pop"
																cursor="pointer"
																src={a.thumbnail}
																fallbackSrc='https://via.placeholder.com/160'
																alt={a.fileName}
																boxSize='160px'
																boxShadow="xs"
																objectFit="cover"
																border="1px solid"
																borderColor="gray.200"
																rounded="lg"
																onClick={async () => {
																	await getMessageAttachment(a.id, a.path, 0, 1, a.isPdf)
																		.then((res: any) => {
																			if (a.isPdf) {
																				setCurrentPdf({
																					id: Number(id),
																					fileName: a.fileName,
																					uri: res?.datauri
																				});
																				setPdfIsOpen(true);
																			} else {
																				setCurrentImage(res.datauri);
																				setIsOpen(true);
																			}
																		})
																		.catch((error: any) => {
																			console.log(error);

																			toast({
																				title: "Error",
																				description: "Cannot download this attachment",
																				status: 'error',
																				duration: 6000,
																				isClosable: true,
																			});
																		});
																}}
															/>
														</Box>
													)
												})
											}
										</Grid>
									</VStack>
								}
							</VStack>
						</SlideFade>
					}

					{
						message.msgacknowledgementrequired === '1' &&
						message.muacknowledged === '0' &&

						<SlideFade in={!loading} offsetY="20px">
							<Box
								w={{ base: "full", md: "fit-content" }}
								p={3}
								rounded="lg"
								border="1px solid"
								borderColor="green.100"
								mt={6}
								bg="green.50"
							>
								<VStack alignItems="start">
									<Text
										color="gray.600"
										fontWeight={500}
										fontSize="sm"
									>
										Acknowledge this message to let {message.creator.udforename} know you've seen it.
									</Text>

									<Button
										size="sm"
										colorScheme="brand"
										_hover={{ bg: "" }}
										variant="solid"
										leftIcon={<Icon as={BsHandThumbsUp} />}
										onClick={() => acknowledge()}
									>
										Acknowledge
									</Button>
								</VStack>
							</Box>
						</SlideFade>
					}
				</VStack>
			</Box>

			{renderImage()}
			{renderPdf()}
		</Box>
	)
};
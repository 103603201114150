import {
	Box,
	Button,
	HStack,
	Icon,
	Select,
	Text,
	useBreakpointValue
} from "@chakra-ui/react";
import {
	BsChevronDoubleLeft,
	BsChevronDoubleRight,
	BsChevronLeft,
	BsChevronRight,
} from "react-icons/bs";
import { IconType } from "react-icons/lib";
import { useEffect } from "react";

export const ReportPager: React.FC<any> = ({
	pageCount,
	pageIndex,
	setPageIndex,
	loading,
	pageItemSizes,
	onChangePageItemSize,
	defaultValue
}): JSX.Element => {
	const isLg = useBreakpointValue({ base: true, lg: false });

	// Handle keyboard navigation
	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			// Only handle keys if not typing in an input/textarea
			if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
				return;
			}

			switch (event.key) {
				case "ArrowLeft":
				case "PageDown":
					if (!loading && pageIndex > 0) {
						event.preventDefault(); // Prevent page scrolling
						setPageIndex(pageIndex - 1);
					}
					break;
				case "ArrowRight":
				case "PageUp":
					if (!loading && pageIndex < pageCount - 1) {
						event.preventDefault(); // Prevent page scrolling
						setPageIndex(pageIndex + 1);
					}
					break;
			}
		};

		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [pageIndex, pageCount, loading, setPageIndex]);

	type PagerButtonProps = {
		index: Number
		hidden: boolean,
		disabled: boolean,
		icon: IconType,
		'aria-label'?: string
	};

	const PagerButton = ({ index, hidden, disabled, icon, 'aria-label': ariaLabel }: PagerButtonProps): JSX.Element =>
		<Button
			p={0}
			bg="white"
			boxShadow="xs"
			_hover={{ bg: "gray.50" }}
			_active={{
				bgColor: "gray.100",
				borderColor: "gray.100",
				boxShadow: "0px 2px 3px 0px #00000014 inset"
			}}
			border="1px solid"
			borderColor="gray.200"
			size={isLg ? "sm" : "md"}
			onClick={async (): Promise<void> => {
				setPageIndex(index);
			}}
			disabled={disabled}
			hidden={hidden}
			aria-label={ariaLabel}
		>
			<Icon as={icon} />
		</Button>;

	const pagerButtons: (JSX.Element | null)[] = [
		<>
			{!isLg &&
				<Select
					minW="120px"
					w="auto"
					defaultValue={defaultValue}
					size="md"
					borderColor="gray.200"
					_focus={{ outline: "" }}
					_hover={{ borderColor: "gray.300" }}
					onChange={(e) => onChangePageItemSize(e.target.value)}
					aria-label="Items per page"
				>
					{
						pageItemSizes?.map((item: number) => (
							<option key={item} value={item}>{item} items</option>
						))
					}
				</Select>
			}
		</>,
		<PagerButton
			key={0}
			index={0}
			hidden={pageIndex < 2}
			disabled={loading}
			icon={BsChevronDoubleLeft}
			aria-label="First page"
		/>,
		<PagerButton
			key={1}
			index={pageIndex - 1}
			hidden={false}
			disabled={loading || pageIndex === 0}
			icon={BsChevronLeft}
			aria-label="Previous page"
		/>,
		<Text
			width='-webkit-fill-available'
			fontSize={isLg ? 'xs' : 'sm'}
			fontWeight={500}
			aria-live="polite"
			aria-atomic="true"
		>
			{pageIndex + 1} of {pageCount}
		</Text>,
		<PagerButton
			key={pageCount + 2}
			index={pageIndex + 1}
			hidden={false}
			disabled={loading || (pageIndex - 1 >= pageCount - 1 - 1)}
			icon={BsChevronRight}
			aria-label="Next page"
		/>,
		<PagerButton
			key={pageCount + 3}
			index={pageCount - 1}
			hidden={pageIndex - 1 >= pageCount - 1 - 2}
			disabled={loading}
			icon={BsChevronDoubleRight}
			aria-label="Last page"
		/>
	];

	return (
		<Box textAlign="center" display="inline-grid" role="navigation" aria-label="Pagination">
			<HStack gap={0} color="gray.600" width="100%">
				{pagerButtons}
			</HStack>
		</Box>
	);
};

export default ReportPager;
import axios from "axios";

const config = () => {
    return {
        headers: {
            "Content-Type": "application/json"
        }
    };
};

const configWithParams = (obj: Object) => {
    return {
        params: obj,
        headers: {
            "Content-Type": "application/json"
        }
    };
};

const PdfConfigWithParams = (obj: Object) => {
    return {
        params: obj,
        headers: {
            "Accept": "application/pdf",
            "Content-Type": "application/pdf",
            "responseType": "arraybuffer"
        }
    };
};

const multipartConfig = () => {
    return {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
};

const AxiosClient = axios.create({
    baseURL: process.env.REACT_APP_OBBI_API_URL,
    responseType: "json",
    timeout: 30 * 1000,      // 30s
    withCredentials: true,   // send cookies
});

AxiosClient.interceptors.response.use(
    response => response,
    error => {
        // 503 for system downtime
        if (error.response.status === 503) {
            window.location.href = `${process.env.REACT_APP_ENV_URL}/login`;
        }

        if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = `${process.env.REACT_APP_ENV_URL}/login`;
        }

        if (error.response.status === 400) {
            throw error;
        }

        if (error.response.status === 500) {
            throw error;
        }
    });

export const get = async<T>(path: string): Promise<T> =>
    await AxiosClient.get(path, config());

export const getWithParams = async<T>(path: string, obj: object): Promise<T> =>
    await AxiosClient.get(path, configWithParams(obj));

export const getPDFWithParams = async<T>(path: string, obj: object): Promise<T> =>
    await AxiosClient.get(path, PdfConfigWithParams(obj));

export const put = async<T>(path: string, payload?: T): Promise<T> =>
    await AxiosClient.put(path, payload, config());

export const patch = async<T>(path: string, payload?: T): Promise<T> =>
    await AxiosClient.patch(path, payload, config());

export const post = async<T>(path: string, payload?: T): Promise<T> =>
    await AxiosClient.post(path, payload, config());

export const _delete = async<T>(path: string, data: any): Promise<T> =>
    await AxiosClient.delete(path, { ...config(), data });

export const getAttachment = async<T>(path: string, data: FormData): Promise<T> =>
    await AxiosClient.post(path, data, { ...multipartConfig(), data });

export const postAttachment = async<T>(path: string, data: FormData): Promise<T> =>
    await AxiosClient.post(path, data, { ...multipartConfig(), data });